<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="173.549"
    height="67.993"
    viewBox="0 0 173.549 67.993"
  >
    <g id="Group_2607" data-name="Group 2607" transform="translate(0 0)">
      <rect
        id="Rectangle_2431"
        data-name="Rectangle 2431"
        width="5.77"
        height="15.853"
        transform="translate(59.237 52.14)"
        fill="#231f20"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M419.54,161.64l.328,8.239a3.335,3.335,0,0,0,.291,1.379,11.843,11.843,0,0,0,1.441,1.71,1.484,1.484,0,0,1,.35,1.051q0,1.544-1.847,2.153a24.2,24.2,0,0,1-6.6.609,13.009,13.009,0,0,1-4.561-.561,1.969,1.969,0,0,1-1.382-1.9,1.339,1.339,0,0,1,.141-.691,12.519,12.519,0,0,1,.936-1.088q.937-.981.937-6.551a11.494,11.494,0,0,0-.749-4.774,2.726,2.726,0,0,0-4.4-.235,7.965,7.965,0,0,0-.583,3.652v4.634a6.894,6.894,0,0,0,.173,1.9,11.525,11.525,0,0,0,1.161,1.988,1.822,1.822,0,0,1,.255.961q0,1.38-1.917,2.035a20.037,20.037,0,0,1-5.991.656q-6.506,0-6.505-2.691a1.517,1.517,0,0,1,.2-.855,4.266,4.266,0,0,1,.947-.83,1.948,1.948,0,0,0,.774-1.019,18.194,18.194,0,0,0,.3-2.82q.163-2.666.166-5.593a5.792,5.792,0,0,0-.246-2.116,3.853,3.853,0,0,0-1.416-1.228,1.759,1.759,0,0,1-.187-3.077,15.419,15.419,0,0,1,3.37-1.51q2.246-.794,4.33-1.346a13.64,13.64,0,0,1,2.855-.549,1.554,1.554,0,0,1,1.182.515,1.731,1.731,0,0,1,.48,1.217c0,.047-.009.242-.025.586a2.591,2.591,0,0,0-.023.328q0,1.123.936,1.121c.376,0,.771-.3,1.195-.888a6.673,6.673,0,0,1,5.85-2.9,8.318,8.318,0,0,1,5.054,1.544,5.938,5.938,0,0,1,2.058,2.585A14.379,14.379,0,0,1,419.54,161.64Z"
        transform="translate(-323.598 -143.832)"
        fill="#444c4c"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M468.322,158.747v7.908a3.648,3.648,0,0,0,.689,2.3,2.143,2.143,0,0,0,1.767.889,5.326,5.326,0,0,0,2.411-.844,1.363,1.363,0,0,1,.7-.21,1.292,1.292,0,0,1,1.03.678,2.548,2.548,0,0,1,.467,1.475,3.722,3.722,0,0,1-1.592,2.786,12,12,0,0,1-8.048,2.831,8.456,8.456,0,0,1-6.015-2.119,7.278,7.278,0,0,1-2.27-5.581V158.044a1.088,1.088,0,0,0-.126-.664,1.074,1.074,0,0,0-.667-.129h-2.411c-.452,0-.741-.075-.866-.224a1.836,1.836,0,0,1-.187-1.04v-1.03a1.185,1.185,0,0,1,.538-1.031l10.716-6.74a2.1,2.1,0,0,1,.984-.21h1.9a.932.932,0,0,1,.785.269,1.962,1.962,0,0,1,.2,1.065v3.159a1.308,1.308,0,0,0,.222.912,1.462,1.462,0,0,0,.947.21h4.331a1.45,1.45,0,0,1,1.121.317,2.348,2.348,0,0,1,.258,1.346v1.381a2.691,2.691,0,0,1-.328,1.556,1.406,1.406,0,0,1-1.192.432h-4.236a.913.913,0,0,0-1.124,1.124Z"
        transform="translate(-327.544 -143.443)"
        fill="#444c4c"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M353.681,150.553a13.182,13.182,0,0,0-6.975-6.131,23.84,23.84,0,0,0-8.469-1.219c-.891,0-1.63.016-2.225.048q-4.209.209-7.557.21-.888,0-2.854-.07c-.595-.016-1.108-.022-1.545-.022a6.223,6.223,0,0,0-3.1.643,1.985,1.985,0,0,0-1.113,1.79,2.143,2.143,0,0,0,1.615,2.084,2.309,2.309,0,0,1,1.427,1.053,7.279,7.279,0,0,1,.305,2.621q0,5.007-.152,10.086t-.339,6.831a5.717,5.717,0,0,1-.516,2.049,1.973,1.973,0,0,1-1.264.76,3.1,3.1,0,0,0-1.416.737,1.77,1.77,0,0,0-.432,1.275,2.251,2.251,0,0,0,.982,1.9,4.184,4.184,0,0,0,2.55.726q.445,0,1.593-.095,1.824-.139,3.3-.141h1.684q2.154,0,4.984.095l2.095.061V164.654a4.434,4.434,0,1,1,2.316,0v11.26l.853.028a18.818,18.818,0,0,0,5.991-.936,14.312,14.312,0,0,0,7.487-5.523,16.2,16.2,0,0,0,2.083-4.6,18.853,18.853,0,0,0,.774-5.3A18.483,18.483,0,0,0,353.681,150.553Z"
        transform="translate(-319.072 -143.204)"
        fill="#ea6e30"
      />
      <path
        id="Path_123"
        data-name="Path 123"
        d="M386.1,159.336a11.942,11.942,0,0,0-3.723-4.143,12.924,12.924,0,0,0-7.51-2.223,15.506,15.506,0,0,0-10.461,3.7,11.329,11.329,0,0,0-4.118,9.034,10.411,10.411,0,0,0,3.37,8.048,12.849,12.849,0,0,0,9.056,3.184l.046,0V169.7a4.435,4.435,0,1,1,2.316,0v7.067a16.133,16.133,0,0,0,7.817-3.231q4.657-3.555,4.656-8.634A11.531,11.531,0,0,0,386.1,159.336Z"
        transform="translate(-321.675 -143.821)"
        fill="#444c4c"
      />
      <path
        id="Path_124"
        data-name="Path 124"
        d="M450.384,170.546a3.253,3.253,0,0,1-.982-.258,1.62,1.62,0,0,1-.493-.656,13.361,13.361,0,0,1-.513-4.7l.045-3.487a10.289,10.289,0,0,0-.595-3.9,6.08,6.08,0,0,0-2.1-2.515,11.416,11.416,0,0,0-6.9-2.06A18.646,18.646,0,0,0,430,155.218a7.938,7.938,0,0,0-2.445,1.965,3.582,3.582,0,0,0-.9,2.246,2.322,2.322,0,0,0,.723,1.78,2.633,2.633,0,0,0,1.873.678q2.27,0,4.353-2.972a7.823,7.823,0,0,1,1.369-1.544,2,2,0,0,1,1.253-.421,1.743,1.743,0,0,1,1.684,1.11,9.14,9.14,0,0,1,.516,3.591c0,.656-.118,1.075-.353,1.253a3.328,3.328,0,0,1-1.707.27q-6.11.024-9.173,2.971a5.791,5.791,0,0,0-1.967,4.306,6.406,6.406,0,0,0,2.692,5.172,7.951,7.951,0,0,0,3.933,1.254v-2.661a4.415,4.415,0,1,1,2.306,0v2.624a8.677,8.677,0,0,0,4.035-1.615,2.322,2.322,0,0,1,1.241-.584,1.3,1.3,0,0,1,.911.609,4.832,4.832,0,0,0,3.885,1.684A7.143,7.143,0,0,0,447.9,175.9a7.477,7.477,0,0,0,2.329-2.035,3.971,3.971,0,0,0,.9-2.341A.855.855,0,0,0,450.384,170.546Z"
        transform="translate(-325.777 -143.821)"
        fill="#444c4c"
      />
      <g
        id="Group_2605"
        data-name="Group 2605"
        transform="translate(78.377 36.367)"
      >
        <path
          id="Path_125"
          data-name="Path 125"
          d="M412.844,195.149H406.2a4.537,4.537,0,0,0,.467,2.039,3.122,3.122,0,0,0,2.867,1.777,4.141,4.141,0,0,0,1.114-.141,3.644,3.644,0,0,0,.976-.449,7.346,7.346,0,0,0,.87-.653c.265-.232.61-.546,1.032-.945a1.112,1.112,0,0,1,.747-.224,1.228,1.228,0,0,1,.846.287,1.021,1.021,0,0,1,.323.808,2.212,2.212,0,0,1-.36,1.076,4.294,4.294,0,0,1-1.09,1.181,6.188,6.188,0,0,1-1.829.94,7.879,7.879,0,0,1-2.53.373,6.769,6.769,0,0,1-5.086-1.865,6.948,6.948,0,0,1-1.817-5.064,8.48,8.48,0,0,1,.447-2.791,6.178,6.178,0,0,1,1.306-2.209,5.682,5.682,0,0,1,2.115-1.411,7.578,7.578,0,0,1,2.786-.491,6.609,6.609,0,0,1,3.414.839,5.525,5.525,0,0,1,2.133,2.17,5.7,5.7,0,0,1,.708,2.711q0,1.283-.733,1.661A4.591,4.591,0,0,1,412.844,195.149ZM406.2,193.22h6.157a4.134,4.134,0,0,0-.938-2.606,2.815,2.815,0,0,0-2.146-.864,2.722,2.722,0,0,0-2.083.876A4.379,4.379,0,0,0,406.2,193.22Z"
          transform="translate(-402.733 -182.362)"
          fill="#231f20"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M433.633,195.095a5.367,5.367,0,0,1-.852,2.973,5.662,5.662,0,0,1-2.494,2.065,9.345,9.345,0,0,1-3.893.747,8.755,8.755,0,0,1-4.453-1.02,5.927,5.927,0,0,1-2.021-1.959,4.442,4.442,0,0,1-.779-2.381,1.616,1.616,0,0,1,1.654-1.629,1.411,1.411,0,0,1,.989.372,2.946,2.946,0,0,1,.69,1.108,7.378,7.378,0,0,0,.753,1.455,3.039,3.039,0,0,0,1.136.963,4.2,4.2,0,0,0,1.929.38,4.348,4.348,0,0,0,2.667-.765,2.3,2.3,0,0,0,1.026-1.908,2.023,2.023,0,0,0-.553-1.475,3.568,3.568,0,0,0-1.431-.864,23.942,23.942,0,0,0-2.345-.634,17.235,17.235,0,0,1-3.29-1.077,5.3,5.3,0,0,1-2.1-1.679,4.351,4.351,0,0,1-.778-2.643,4.548,4.548,0,0,1,.821-2.675,5.182,5.182,0,0,1,2.376-1.8,9.724,9.724,0,0,1,3.656-.629,9.069,9.069,0,0,1,2.905.416,6.16,6.16,0,0,1,2.033,1.107,4.638,4.638,0,0,1,1.182,1.45,3.351,3.351,0,0,1,.372,1.48,1.749,1.749,0,0,1-.467,1.187,1.481,1.481,0,0,1-1.163.528,1.338,1.338,0,0,1-.963-.317,4.268,4.268,0,0,1-.716-1.039,4.684,4.684,0,0,0-1.194-1.61,3.485,3.485,0,0,0-2.238-.578,3.892,3.892,0,0,0-2.307.627,1.826,1.826,0,0,0-.876,1.511,1.533,1.533,0,0,0,.3.946,2.547,2.547,0,0,0,.822.684,5.48,5.48,0,0,0,1.058.448q.534.162,1.765.473a27.64,27.64,0,0,1,2.793.794,8.557,8.557,0,0,1,2.128,1.059,4.348,4.348,0,0,1,1.368,1.573A5.034,5.034,0,0,1,433.633,195.095Z"
          transform="translate(-403.769 -182.023)"
          fill="#231f20"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M440.832,189.317v.41a6.707,6.707,0,0,1,1.991-1.735,4.865,4.865,0,0,1,2.337-.555,5.415,5.415,0,0,1,2.885.808,5.572,5.572,0,0,1,2.085,2.364,8.259,8.259,0,0,1,.765,3.681,8.987,8.987,0,0,1-.442,2.881,6.482,6.482,0,0,1-1.212,2.2,5.3,5.3,0,0,1-1.822,1.368,5.4,5.4,0,0,1-2.259.479,4.724,4.724,0,0,1-2.442-.586,7,7,0,0,1-1.886-1.715v5.1q0,2.24-1.628,2.239a1.308,1.308,0,0,1-1.269-.578,3.65,3.65,0,0,1-.311-1.686V189.341a2.143,2.143,0,0,1,.423-1.449,1.466,1.466,0,0,1,1.157-.479,1.535,1.535,0,0,1,1.176.491A2.014,2.014,0,0,1,440.832,189.317Zm6.654,4.937a5.9,5.9,0,0,0-.409-2.307,3.392,3.392,0,0,0-1.138-1.48,2.726,2.726,0,0,0-1.611-.516,3.025,3.025,0,0,0-2.369,1.107,4.838,4.838,0,0,0-.964,3.259,4.708,4.708,0,0,0,.958,3.152,2.99,2.99,0,0,0,2.375,1.126,2.732,2.732,0,0,0,1.568-.491,3.378,3.378,0,0,0,1.157-1.475A5.854,5.854,0,0,0,447.486,194.254Z"
          transform="translate(-404.937 -182.363)"
          fill="#231f20"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M467.353,194.317a8.049,8.049,0,0,1-.473,2.8,6.193,6.193,0,0,1-1.368,2.2,6.043,6.043,0,0,1-2.14,1.412,7.541,7.541,0,0,1-2.8.492,7.378,7.378,0,0,1-2.775-.5,6.138,6.138,0,0,1-3.5-3.613,8.657,8.657,0,0,1,.007-5.615,6.156,6.156,0,0,1,1.357-2.19,6.025,6.025,0,0,1,2.138-1.4,8.1,8.1,0,0,1,5.573.007,6.021,6.021,0,0,1,3.514,3.608A8.045,8.045,0,0,1,467.353,194.317Zm-3.409,0a5.1,5.1,0,0,0-.913-3.235,2.973,2.973,0,0,0-2.456-1.156,3.049,3.049,0,0,0-1.754.515,3.271,3.271,0,0,0-1.168,1.524,6.918,6.918,0,0,0-.008,4.676,3.346,3.346,0,0,0,1.157,1.525,3.018,3.018,0,0,0,1.773.528,2.966,2.966,0,0,0,2.456-1.163A5.082,5.082,0,0,0,463.944,194.317Z"
          transform="translate(-405.961 -182.363)"
          fill="#231f20"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M474.488,196.32v2.834A2.192,2.192,0,0,1,474,200.7a1.622,1.622,0,0,1-1.233.516,1.557,1.557,0,0,1-1.206-.522,2.234,2.234,0,0,1-.473-1.544V189.7q0-2.289,1.654-2.289a1.386,1.386,0,0,1,1.22.534,2.966,2.966,0,0,1,.41,1.58,5.963,5.963,0,0,1,1.251-1.58,2.586,2.586,0,0,1,1.71-.534,4.39,4.39,0,0,1,2.076.534,1.6,1.6,0,0,1,1.007,1.419,1.35,1.35,0,0,1-.428,1.026,1.33,1.33,0,0,1-.927.4,4.205,4.205,0,0,1-.9-.23,4.223,4.223,0,0,0-1.264-.23,1.84,1.84,0,0,0-1.218.391,2.5,2.5,0,0,0-.734,1.164,8.129,8.129,0,0,0-.361,1.834Q474.487,194.788,474.488,196.32Z"
          transform="translate(-407.051 -182.363)"
          fill="#231f20"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M483.12,187.4h.373v-2.04c0-.548.014-.976.043-1.288a1.886,1.886,0,0,1,.244-.8,1.535,1.535,0,0,1,.571-.566,1.626,1.626,0,0,1,.833-.216,1.673,1.673,0,0,1,1.169.483,1.439,1.439,0,0,1,.442.79,7.221,7.221,0,0,1,.093,1.325V187.4h1.244a1.59,1.59,0,0,1,1.1.342,1.128,1.128,0,0,1,.38.876.984.984,0,0,1-.542.958,3.52,3.52,0,0,1-1.549.275h-.634v6.243a9.659,9.659,0,0,0,.057,1.224,1.243,1.243,0,0,0,.3.7,1.026,1.026,0,0,0,.79.266,4.33,4.33,0,0,0,.809-.1,4.216,4.216,0,0,1,.8-.106,1.022,1.022,0,0,1,.739.33,1.106,1.106,0,0,1,.33.815,1.363,1.363,0,0,1-.9,1.255,6.051,6.051,0,0,1-2.575.436,4.444,4.444,0,0,1-2.411-.535,2.491,2.491,0,0,1-1.077-1.48,10.069,10.069,0,0,1-.254-2.525v-6.517h-.449a1.622,1.622,0,0,1-1.12-.349,1.143,1.143,0,0,1-.385-.884,1.1,1.1,0,0,1,.4-.876A1.776,1.776,0,0,1,483.12,187.4Z"
          transform="translate(-407.711 -182.052)"
          fill="#231f20"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M503.537,196.779a3.937,3.937,0,0,1-2.7,3.924,9.072,9.072,0,0,1-3.254.516,7.931,7.931,0,0,1-3.133-.559,4.569,4.569,0,0,1-1.928-1.4,2.818,2.818,0,0,1-.623-1.685,1.308,1.308,0,0,1,.4-.958,1.364,1.364,0,0,1,1.007-.4,1.179,1.179,0,0,1,.822.26,2.887,2.887,0,0,1,.546.734,3.724,3.724,0,0,0,1.25,1.356,3.766,3.766,0,0,0,1.984.448,2.854,2.854,0,0,0,1.672-.454,1.272,1.272,0,0,0,.653-1.038,1.414,1.414,0,0,0-.677-1.306,8.358,8.358,0,0,0-2.232-.784,18.467,18.467,0,0,1-2.855-.913,4.7,4.7,0,0,1-1.761-1.263,2.9,2.9,0,0,1-.659-1.927,3.4,3.4,0,0,1,.61-1.929,4.172,4.172,0,0,1,1.8-1.449,6.878,6.878,0,0,1,2.867-.541,9.36,9.36,0,0,1,2.368.274,5.883,5.883,0,0,1,1.753.733,3.6,3.6,0,0,1,1.071,1.02,1.988,1.988,0,0,1,.366,1.1,1.266,1.266,0,0,1-.391.957,1.557,1.557,0,0,1-1.114.373,1.363,1.363,0,0,1-.889-.3,5.861,5.861,0,0,1-.839-.895,3.048,3.048,0,0,0-.909-.8,2.861,2.861,0,0,0-1.416-.3,2.775,2.775,0,0,0-1.53.392,1.143,1.143,0,0,0-.61.976,1.056,1.056,0,0,0,.448.878,3.7,3.7,0,0,0,1.207.566q.757.223,2.088.546a11.143,11.143,0,0,1,2.582.922,4.277,4.277,0,0,1,1.517,1.261A2.8,2.8,0,0,1,503.537,196.779Z"
          transform="translate(-408.365 -182.363)"
          fill="#231f20"
        />
      </g>
      <ellipse
        id="Ellipse_19"
        data-name="Ellipse 19"
        cx="11.049"
        cy="11.049"
        rx="11.049"
        ry="11.049"
        transform="translate(51.208 38.421)"
        fill="#ea6e30"
      />
      <g
        id="Group_2606"
        data-name="Group 2606"
        transform="translate(56.043 43.256)"
      >
        <path
          id="Path_132"
          data-name="Path 132"
          d="M385.48,201.805a.7.7,0,0,1-.7-.7V190.08a.7.7,0,0,1,1.405,0V201.1A.7.7,0,0,1,385.48,201.805Z"
          transform="translate(-379.266 -189.377)"
          fill="#fff"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M390.619,196.665H379.6a.7.7,0,0,1,0-1.405h11.023a.7.7,0,0,1,0,1.405Z"
          transform="translate(-378.894 -189.749)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_134"
        data-name="Path 134"
        d="M500.969,158.52a10.1,10.1,0,0,0-2.528-3A11.6,11.6,0,0,0,490.884,153,13.164,13.164,0,0,0,485.712,154a12.553,12.553,0,0,0-5.242,4.048,11.377,11.377,0,0,0-2.386,7.067,11.867,11.867,0,0,0,1.38,5.57,11.71,11.71,0,0,0,2.668,3.392,12.189,12.189,0,0,0,8.236,2.856,13.948,13.948,0,0,0,8.589-2.786q2.106-1.567,2.105-3.252a2.486,2.486,0,0,0-.584-1.545,1.592,1.592,0,0,0-1.194-.749,2.49,2.49,0,0,0-1.264.561,8.177,8.177,0,0,1-2.257,1.065,8.315,8.315,0,0,1-2.492.432,5.5,5.5,0,0,1-3.546-1.345,3.759,3.759,0,0,1-1.651-2.866c0-.421.242-.633.725-.633h1.468v-2.3a4.415,4.415,0,1,1,2.306,0v2.3H499.8a1.892,1.892,0,0,0,1.638-.97,4.5,4.5,0,0,0,.631-2.492A7.833,7.833,0,0,0,500.969,158.52Z"
        transform="translate(-329.116 -143.823)"
        fill="#444c4c"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "LogoHeader"
};
</script>