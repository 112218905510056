<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="27.608"
    height="28.084"
    viewBox="0 0 27.608 28.084"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2515"
          data-name="Rectangle 2515"
          width="27.608"
          height="28.084"
          :fill="color"
        />
      </clipPath>
    </defs>
    <g id="Group_2644" data-name="Group 2644">
      <path
        id="Path_186"
        data-name="Path 186"
        d="M41.666,30.389c3.686,0,4.122.014,5.578.082a7.527,7.527,0,0,1,2.563.484A4.27,4.27,0,0,1,51.394,32a4.357,4.357,0,0,1,1.033,1.615,7.889,7.889,0,0,1,.475,2.607c.066,1.481.08,1.925.08,5.674s-.014,4.194-.08,5.674a7.889,7.889,0,0,1-.475,2.608A4.357,4.357,0,0,1,51.394,51.8a4.27,4.27,0,0,1-1.587,1.05,7.523,7.523,0,0,1-2.563.483c-1.455.068-1.892.082-5.578.082s-4.123-.014-5.578-.082a7.523,7.523,0,0,1-2.563-.483,4.271,4.271,0,0,1-1.587-1.05,4.36,4.36,0,0,1-1.033-1.615,7.887,7.887,0,0,1-.475-2.608c-.066-1.481-.08-1.925-.08-5.674s.014-4.193.08-5.674a7.887,7.887,0,0,1,.475-2.607A4.359,4.359,0,0,1,31.938,32a4.271,4.271,0,0,1,1.587-1.05,7.527,7.527,0,0,1,2.563-.484c1.456-.068,1.892-.082,5.578-.082m0-2.53c-3.749,0-4.219.016-5.691.085a9.984,9.984,0,0,0-3.351.653,6.755,6.755,0,0,0-2.445,1.62A6.9,6.9,0,0,0,28.587,32.7a10.459,10.459,0,0,0-.642,3.409c-.067,1.5-.083,1.976-.083,5.789s.016,4.292.083,5.79a10.459,10.459,0,0,0,.642,3.409,6.9,6.9,0,0,0,1.592,2.487,6.755,6.755,0,0,0,2.445,1.62,9.983,9.983,0,0,0,3.351.653c1.472.068,1.942.085,5.691.085s4.219-.016,5.691-.085a9.984,9.984,0,0,0,3.351-.653,6.755,6.755,0,0,0,2.445-1.62A6.9,6.9,0,0,0,54.745,51.1a10.461,10.461,0,0,0,.642-3.409c.067-1.5.083-1.976.083-5.79s-.016-4.292-.083-5.789a10.461,10.461,0,0,0-.642-3.409,6.9,6.9,0,0,0-1.592-2.487,6.755,6.755,0,0,0-2.445-1.62,9.984,9.984,0,0,0-3.351-.653c-1.472-.068-1.942-.085-5.691-.085"
        transform="translate(-27.862 -27.859)"
        :fill="color"
      />
      <path
        id="Path_187"
        data-name="Path 187"
        d="M145.938,138.846a7.212,7.212,0,1,0,7.089,7.211,7.15,7.15,0,0,0-7.089-7.211m0,11.891a4.681,4.681,0,1,1,4.6-4.681,4.642,4.642,0,0,1-4.6,4.681"
        transform="translate(-132.133 -132.015)"
        :fill="color"
      />
      <path
        id="Path_188"
        data-name="Path 188"
        d="M353.719,108.525a1.657,1.657,0,1,1-1.657-1.685,1.671,1.671,0,0,1,1.657,1.685"
        transform="translate(-330.89 -101.979)"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconInstagram",
  props: {
    color: {
      type: String,
      default: "#fff"
    }
  }
};
</script>