<template>
  <b-row
    class="content-loading-pickup"
    :cols="m_col"
    :cols-md="md_cols"
    align-v="stretch"
  >
    <b-col v-for="(col, index) in events" :key="index">
      <b-card class="mb-3 mb-md-2 mb-lg-4 border-0 rounded-0" no-body>
        <b-skeleton-img no-aspect></b-skeleton-img>
        <div class="article-content">
          <b-skeleton animation height="20px" width="7rem"></b-skeleton>
          <h4>
            <b-skeleton animation height="60px" width="100%"></b-skeleton>
          </h4>
          <b-card-header class="mt-4 pl-0">
            <span class="date"
              ><b-skeleton animation height="10px" width="4rem"></b-skeleton
            ></span>
          </b-card-header>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SectionContentPickup",
  props: {
    events: {
      type: [Number],
      default: 6,
    },
    m_col: {
      type: [Number],
      default: 2,
    },
    md_cols: {
     type: [Number],
      default: 3, 
    }
  },
};
</script>