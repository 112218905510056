<template>
  <div class="overlay">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="
        margin: auto;
        background: none;
        display: block;
        shape-rendering: auto;
      "
      :width="width"
      :height="height"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="0"
        fill="none"
        :stroke="outerBorder"
        stroke-width="2"
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="0s"
        ></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="0s"
        ></animate>
      </circle>
      <circle
        cx="50"
        cy="50"
        r="0"
        fill="none"
        :stroke="innerBorder"
        stroke-width="2"
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.5s"
        ></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.5s"
        ></animate>
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "LoadingOnButton",
  props: {
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "200px",
    },
    outerBorder: {
      type: String,
      default: "#f54b0f",
    },
    innerBorder: {
      type: String,
      default: "#f26837",
    },
  },
};
</script>

<style lang="sass" scoped>
.overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgb(210 202 202 / 40%)
    display: flex
    align-items: center
    z-index: 9999
.overlay-content-page 
  position: fixed
  z-index: 99999
.overlay-content-tab-page
  position: absolute
  z-index: 99999
</style>