<template>
  <footer class="footer footer-authen pt-4 pb-3 mt-3">
    <b-container>
      <p class="logo-footer pt-1 pt-md-3 mb-1 mb-md-2 text-center">
        <b-link class="pt-md-2" href="https://itunes.apple.com/jp/app/id1611529736?mt=8" target="_blank"
          ><esports-app-store-footer class="app-store" /></b-link
        ><b-link class="pt-md-2" href="https://play.google.com/store/apps/details?id=jp.co.digilo.donate_esport&hl=ja&gl=jp" target="_blank"
          ><esports-google-play-footer class="google-play"
        /></b-link>
      </p>
      <p class="bottom-footer text-center pt-1 mb-0">
        Copyrights 2021 ©︎ Donate eSpots all rights reserved.
      </p>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "FooterAuthen",
};
</script>