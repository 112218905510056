<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="185.25"
    height="72.577"
    viewBox="0 0 185.25 72.577"
  >
    <g
      id="Group_3"
      data-name="Group 3"
      transform="translate(-319.072 -143.204)"
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="6.159"
        height="16.922"
        transform="translate(382.303 198.859)"
      />
      <path
        id="Path_1"
        data-name="Path 1"
        d="M421.482,162.213l.35,8.794a3.557,3.557,0,0,0,.311,1.472,12.638,12.638,0,0,0,1.538,1.825,1.584,1.584,0,0,1,.374,1.122q0,1.648-1.972,2.3a25.826,25.826,0,0,1-7.046.65,13.887,13.887,0,0,1-4.869-.6,2.1,2.1,0,0,1-1.475-2.023,1.429,1.429,0,0,1,.15-.738,13.368,13.368,0,0,1,1-1.161q1-1.047,1-6.993a12.269,12.269,0,0,0-.8-5.1,2.909,2.909,0,0,0-4.7-.251,8.5,8.5,0,0,0-.622,3.9v4.946a7.359,7.359,0,0,0,.185,2.023,12.3,12.3,0,0,0,1.239,2.122,1.945,1.945,0,0,1,.272,1.026q0,1.473-2.046,2.172a21.389,21.389,0,0,1-6.395.7q-6.945,0-6.944-2.872a1.619,1.619,0,0,1,.212-.913,4.554,4.554,0,0,1,1.011-.886,2.079,2.079,0,0,0,.826-1.088,19.412,19.412,0,0,0,.323-3.01q.174-2.846.177-5.97a6.182,6.182,0,0,0-.263-2.259,4.113,4.113,0,0,0-1.512-1.311,1.878,1.878,0,0,1-.2-3.285,16.457,16.457,0,0,1,3.6-1.612q2.4-.848,4.622-1.437a14.56,14.56,0,0,1,3.047-.586,1.659,1.659,0,0,1,1.262.55,1.848,1.848,0,0,1,.512,1.3c0,.05-.01.258-.027.625a2.767,2.767,0,0,0-.024.35q0,1.2,1,1.2c.4,0,.823-.316,1.275-.948a7.123,7.123,0,0,1,6.244-3.1,8.879,8.879,0,0,1,5.4,1.648,6.337,6.337,0,0,1,2.2,2.759A15.348,15.348,0,0,1,421.482,162.213Z"
        fill="#333"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M469.341,159.541v8.441a3.9,3.9,0,0,0,.736,2.45,2.288,2.288,0,0,0,1.886.949,5.685,5.685,0,0,0,2.573-.9,1.455,1.455,0,0,1,.75-.224,1.379,1.379,0,0,1,1.1.724,2.72,2.72,0,0,1,.5,1.574,3.972,3.972,0,0,1-1.7,2.974,12.805,12.805,0,0,1-8.591,3.022,9.026,9.026,0,0,1-6.421-2.262,7.768,7.768,0,0,1-2.423-5.957V158.79a1.161,1.161,0,0,0-.135-.709,1.147,1.147,0,0,0-.712-.138h-2.574c-.482,0-.791-.08-.924-.239a1.961,1.961,0,0,1-.2-1.11v-1.1a1.265,1.265,0,0,1,.574-1.1l11.439-7.194a2.246,2.246,0,0,1,1.05-.224h2.023a1,1,0,0,1,.838.287,2.094,2.094,0,0,1,.212,1.137v3.372a1.4,1.4,0,0,0,.237.973,1.561,1.561,0,0,0,1.011.224h4.623a1.548,1.548,0,0,1,1.2.338,2.505,2.505,0,0,1,.275,1.437v1.474a2.872,2.872,0,0,1-.35,1.661,1.5,1.5,0,0,1-1.272.461h-4.521a.975.975,0,0,0-1.2,1.2Z"
        fill="#333"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M356.014,151.049a14.071,14.071,0,0,0-7.445-6.544,25.446,25.446,0,0,0-9.04-1.3c-.951,0-1.74.017-2.375.051q-4.492.224-8.067.224-.948,0-3.046-.075c-.635-.017-1.183-.024-1.649-.024a6.642,6.642,0,0,0-3.309.686,2.119,2.119,0,0,0-1.188,1.911,2.287,2.287,0,0,0,1.724,2.224,2.464,2.464,0,0,1,1.523,1.124,7.769,7.769,0,0,1,.326,2.8q0,5.345-.162,10.766t-.362,7.292a6.1,6.1,0,0,1-.551,2.187,2.105,2.105,0,0,1-1.349.811,3.312,3.312,0,0,0-1.511.787,1.889,1.889,0,0,0-.461,1.361,2.4,2.4,0,0,0,1.048,2.023,4.466,4.466,0,0,0,2.722.775q.475,0,1.7-.1,1.947-.148,3.522-.15h1.8q2.3,0,5.32.1l2.236.065V166.1a4.733,4.733,0,1,1,2.472,0v12.019l.911.03a20.087,20.087,0,0,0,6.4-1,15.277,15.277,0,0,0,7.992-5.895,17.285,17.285,0,0,0,2.223-4.907,20.126,20.126,0,0,0,.826-5.658A19.729,19.729,0,0,0,356.014,151.049Z"
        :fill="logo"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M387.838,159.765a12.746,12.746,0,0,0-3.974-4.422,13.8,13.8,0,0,0-8.016-2.373,16.551,16.551,0,0,0-11.166,3.947,12.093,12.093,0,0,0-4.4,9.643,11.113,11.113,0,0,0,3.6,8.591,13.715,13.715,0,0,0,9.667,3.4l.049,0v-7.715a4.734,4.734,0,1,1,2.472,0v7.543a17.22,17.22,0,0,0,8.344-3.449q4.971-3.795,4.97-9.216A12.308,12.308,0,0,0,387.838,159.765Z"
        fill="#333"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M452.08,171.731a3.473,3.473,0,0,1-1.048-.275,1.728,1.728,0,0,1-.526-.7,14.26,14.26,0,0,1-.548-5.021l.048-3.722a10.984,10.984,0,0,0-.635-4.159,6.491,6.491,0,0,0-2.237-2.685,12.186,12.186,0,0,0-7.367-2.2,19.9,19.9,0,0,0-9.443,2.4,8.474,8.474,0,0,0-2.61,2.1,3.824,3.824,0,0,0-.96,2.4,2.478,2.478,0,0,0,.772,1.9,2.81,2.81,0,0,0,2,.724q2.423,0,4.646-3.172a8.354,8.354,0,0,1,1.461-1.648,2.138,2.138,0,0,1,1.337-.449,1.86,1.86,0,0,1,1.8,1.185,9.756,9.756,0,0,1,.551,3.833c0,.7-.126,1.147-.377,1.337a3.552,3.552,0,0,1-1.822.288q-6.522.025-9.791,3.171a6.181,6.181,0,0,0-2.1,4.6,6.838,6.838,0,0,0,2.873,5.521,8.487,8.487,0,0,0,4.2,1.338v-2.84a4.713,4.713,0,1,1,2.461,0v2.8a9.261,9.261,0,0,0,4.307-1.724,2.479,2.479,0,0,1,1.325-.623,1.39,1.39,0,0,1,.973.65,5.157,5.157,0,0,0,4.147,1.8,7.624,7.624,0,0,0,3.92-1.1,7.981,7.981,0,0,0,2.486-2.172,4.238,4.238,0,0,0,.961-2.5A.912.912,0,0,0,452.08,171.731Z"
        fill="#333"
      />
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_6"
          data-name="Path 6"
          d="M413.526,195.672h-7.09a4.843,4.843,0,0,0,.5,2.177,3.333,3.333,0,0,0,3.06,1.9,4.421,4.421,0,0,0,1.189-.151,3.891,3.891,0,0,0,1.042-.479,7.841,7.841,0,0,0,.929-.7c.283-.248.651-.583,1.1-1.009a1.187,1.187,0,0,1,.8-.239,1.31,1.31,0,0,1,.9.306,1.09,1.09,0,0,1,.345.862,2.362,2.362,0,0,1-.384,1.149,4.584,4.584,0,0,1-1.163,1.261,6.606,6.606,0,0,1-1.952,1,8.41,8.41,0,0,1-2.7.4,7.226,7.226,0,0,1-5.429-1.991,7.417,7.417,0,0,1-1.939-5.4,9.051,9.051,0,0,1,.477-2.979,6.594,6.594,0,0,1,1.394-2.358,6.065,6.065,0,0,1,2.258-1.506,8.09,8.09,0,0,1,2.974-.524,7.054,7.054,0,0,1,3.644.9,5.9,5.9,0,0,1,2.277,2.316,6.088,6.088,0,0,1,.756,2.894q0,1.37-.782,1.773A4.9,4.9,0,0,1,413.526,195.672Zm-7.09-2.059h6.572a4.412,4.412,0,0,0-1-2.782,3,3,0,0,0-2.291-.922,2.905,2.905,0,0,0-2.224.935A4.675,4.675,0,0,0,406.436,193.613Z"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M434.61,195.976a5.728,5.728,0,0,1-.91,3.173,6.044,6.044,0,0,1-2.662,2.2,9.974,9.974,0,0,1-4.155.8,9.345,9.345,0,0,1-4.753-1.089,6.327,6.327,0,0,1-2.157-2.091,4.742,4.742,0,0,1-.831-2.542,1.725,1.725,0,0,1,1.766-1.739,1.506,1.506,0,0,1,1.056.4,3.145,3.145,0,0,1,.737,1.183,7.87,7.87,0,0,0,.8,1.553,3.245,3.245,0,0,0,1.213,1.028,4.48,4.48,0,0,0,2.059.406,4.641,4.641,0,0,0,2.847-.817,2.455,2.455,0,0,0,1.1-2.037,2.16,2.16,0,0,0-.59-1.574,3.809,3.809,0,0,0-1.527-.922,25.555,25.555,0,0,0-2.5-.677,18.4,18.4,0,0,1-3.512-1.15,5.655,5.655,0,0,1-2.243-1.792,4.645,4.645,0,0,1-.83-2.821,4.855,4.855,0,0,1,.876-2.855,5.532,5.532,0,0,1,2.536-1.917,10.38,10.38,0,0,1,3.9-.671,9.681,9.681,0,0,1,3.1.444,6.575,6.575,0,0,1,2.17,1.182,4.95,4.95,0,0,1,1.262,1.548,3.577,3.577,0,0,1,.4,1.58,1.867,1.867,0,0,1-.5,1.267,1.581,1.581,0,0,1-1.241.564,1.428,1.428,0,0,1-1.028-.338,4.555,4.555,0,0,1-.764-1.109,5,5,0,0,0-1.275-1.719,3.72,3.72,0,0,0-2.389-.617,4.155,4.155,0,0,0-2.463.669,1.949,1.949,0,0,0-.935,1.613,1.637,1.637,0,0,0,.317,1.01,2.72,2.72,0,0,0,.877.73,5.85,5.85,0,0,0,1.129.478q.57.173,1.884.505a29.49,29.49,0,0,1,2.981.848,9.133,9.133,0,0,1,2.271,1.13,4.641,4.641,0,0,1,1.46,1.679A5.373,5.373,0,0,1,434.61,195.976Z"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M441.048,189.445v.438a7.159,7.159,0,0,1,2.125-1.852,5.193,5.193,0,0,1,2.495-.592,5.781,5.781,0,0,1,3.08.863,5.948,5.948,0,0,1,2.225,2.523,8.816,8.816,0,0,1,.817,3.929,9.6,9.6,0,0,1-.472,3.075,6.921,6.921,0,0,1-1.294,2.35,5.651,5.651,0,0,1-1.945,1.46,5.759,5.759,0,0,1-2.411.511,5.042,5.042,0,0,1-2.607-.625,7.468,7.468,0,0,1-2.013-1.831v5.443q0,2.391-1.738,2.39a1.4,1.4,0,0,1-1.355-.617,3.9,3.9,0,0,1-.332-1.8V189.471a2.288,2.288,0,0,1,.452-1.547,1.565,1.565,0,0,1,1.235-.511,1.638,1.638,0,0,1,1.255.524A2.15,2.15,0,0,1,441.048,189.445Zm7.1,5.27a6.294,6.294,0,0,0-.437-2.463,3.621,3.621,0,0,0-1.215-1.58,2.91,2.91,0,0,0-1.72-.551,3.229,3.229,0,0,0-2.529,1.182,5.163,5.163,0,0,0-1.029,3.479,5.026,5.026,0,0,0,1.023,3.364,3.191,3.191,0,0,0,2.535,1.2,2.916,2.916,0,0,0,1.674-.524,3.606,3.606,0,0,0,1.235-1.574A6.249,6.249,0,0,0,448.151,194.715Z"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M468.265,194.782a8.593,8.593,0,0,1-.505,2.986,6.611,6.611,0,0,1-1.46,2.35,6.449,6.449,0,0,1-2.284,1.507,8.049,8.049,0,0,1-2.987.525,7.875,7.875,0,0,1-2.962-.531,6.552,6.552,0,0,1-3.737-3.857,9.241,9.241,0,0,1,.007-5.994,6.572,6.572,0,0,1,1.448-2.338,6.432,6.432,0,0,1,2.282-1.493,8.65,8.65,0,0,1,5.949.007,6.427,6.427,0,0,1,3.751,3.851A8.587,8.587,0,0,1,468.265,194.782Zm-3.639,0a5.448,5.448,0,0,0-.975-3.453,3.174,3.174,0,0,0-2.622-1.234,3.255,3.255,0,0,0-1.872.55,3.492,3.492,0,0,0-1.247,1.627,7.384,7.384,0,0,0-.009,4.991,3.572,3.572,0,0,0,1.235,1.628,3.221,3.221,0,0,0,1.893.564,3.166,3.166,0,0,0,2.622-1.241A5.424,5.424,0,0,0,464.626,194.782Z"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M474.717,196.92v3.025a2.339,2.339,0,0,1-.518,1.654,1.731,1.731,0,0,1-1.316.551,1.662,1.662,0,0,1-1.287-.557,2.385,2.385,0,0,1-.505-1.648V189.856q0-2.443,1.766-2.443a1.479,1.479,0,0,1,1.3.57,3.166,3.166,0,0,1,.438,1.687,6.364,6.364,0,0,1,1.335-1.687,2.76,2.76,0,0,1,1.825-.57,4.686,4.686,0,0,1,2.216.57,1.708,1.708,0,0,1,1.075,1.515,1.441,1.441,0,0,1-.457,1.1,1.42,1.42,0,0,1-.989.431,4.489,4.489,0,0,1-.962-.245,4.507,4.507,0,0,0-1.349-.245,1.964,1.964,0,0,0-1.3.417,2.674,2.674,0,0,0-.784,1.242,8.678,8.678,0,0,0-.385,1.958Q474.716,195.285,474.717,196.92Z"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M483.227,187.732h.4v-2.177c0-.585.015-1.042.046-1.375a2.013,2.013,0,0,1,.26-.856,1.637,1.637,0,0,1,.61-.6,1.736,1.736,0,0,1,.889-.231,1.786,1.786,0,0,1,1.248.516,1.537,1.537,0,0,1,.472.843,7.708,7.708,0,0,1,.1,1.414v2.47h1.328a1.7,1.7,0,0,1,1.174.365,1.2,1.2,0,0,1,.406.935,1.05,1.05,0,0,1-.578,1.023,3.757,3.757,0,0,1-1.653.293h-.677v6.664a10.3,10.3,0,0,0,.061,1.307,1.327,1.327,0,0,0,.317.745,1.1,1.1,0,0,0,.843.284,4.62,4.62,0,0,0,.864-.112,4.5,4.5,0,0,1,.85-.113,1.091,1.091,0,0,1,.789.352,1.18,1.18,0,0,1,.352.87,1.455,1.455,0,0,1-.956,1.34,6.459,6.459,0,0,1-2.749.465,4.744,4.744,0,0,1-2.574-.571A2.659,2.659,0,0,1,483.9,200a10.747,10.747,0,0,1-.271-2.695v-6.956h-.479a1.731,1.731,0,0,1-1.2-.372,1.22,1.22,0,0,1-.411-.944,1.173,1.173,0,0,1,.432-.935A1.9,1.9,0,0,1,483.227,187.732Z"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M504.322,197.41a4.2,4.2,0,0,1-2.887,4.189,9.683,9.683,0,0,1-3.473.551,8.466,8.466,0,0,1-3.344-.6,4.878,4.878,0,0,1-2.058-1.494,3.008,3.008,0,0,1-.665-1.8,1.4,1.4,0,0,1,.426-1.023,1.456,1.456,0,0,1,1.075-.424,1.258,1.258,0,0,1,.877.278,3.082,3.082,0,0,1,.583.784,3.975,3.975,0,0,0,1.334,1.447,4.02,4.02,0,0,0,2.118.478,3.047,3.047,0,0,0,1.785-.485,1.358,1.358,0,0,0,.7-1.108,1.509,1.509,0,0,0-.723-1.394,8.921,8.921,0,0,0-2.382-.837,19.714,19.714,0,0,1-3.047-.975,5.015,5.015,0,0,1-1.88-1.348,3.092,3.092,0,0,1-.7-2.057,3.628,3.628,0,0,1,.651-2.059,4.452,4.452,0,0,1,1.919-1.547,7.342,7.342,0,0,1,3.06-.577,9.991,9.991,0,0,1,2.528.293,6.281,6.281,0,0,1,1.871.782,3.845,3.845,0,0,1,1.143,1.089,2.122,2.122,0,0,1,.391,1.169,1.351,1.351,0,0,1-.417,1.022,1.662,1.662,0,0,1-1.189.4,1.455,1.455,0,0,1-.949-.319,6.257,6.257,0,0,1-.9-.955,3.253,3.253,0,0,0-.97-.85,3.053,3.053,0,0,0-1.512-.319,2.962,2.962,0,0,0-1.633.418,1.22,1.22,0,0,0-.651,1.042,1.127,1.127,0,0,0,.478.937,3.953,3.953,0,0,0,1.288.6q.809.238,2.229.583a11.894,11.894,0,0,1,2.756.984,4.565,4.565,0,0,1,1.619,1.346A2.993,2.993,0,0,1,504.322,197.41Z"
        />
      </g>
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="11.794"
        cy="11.794"
        r="11.794"
        transform="translate(373.733 184.216)"
        :fill="logo"
      />
      <g id="Group_2" data-name="Group 2">
        <path
          id="Path_13"
          data-name="Path 13"
          d="M385.527,202.643a.75.75,0,0,1-.75-.75V190.127a.75.75,0,0,1,1.5,0v11.766A.75.75,0,0,1,385.527,202.643Z"
          fill="#fff"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M391.41,196.76H379.644a.75.75,0,0,1,0-1.5H391.41a.75.75,0,0,1,0,1.5Z"
          fill="#fff"
        />
      </g>
      <path
        id="Path_15"
        data-name="Path 15"
        d="M502.512,158.892a10.785,10.785,0,0,0-2.7-3.2,12.386,12.386,0,0,0-8.067-2.7,14.052,14.052,0,0,0-5.521,1.074,13.4,13.4,0,0,0-5.6,4.321,12.144,12.144,0,0,0-2.547,7.543,12.667,12.667,0,0,0,1.473,5.945A12.5,12.5,0,0,0,482.4,175.5a13.01,13.01,0,0,0,8.791,3.049,14.888,14.888,0,0,0,9.168-2.974q2.248-1.673,2.247-3.471a2.653,2.653,0,0,0-.623-1.649,1.7,1.7,0,0,0-1.274-.8,2.658,2.658,0,0,0-1.349.6,8.728,8.728,0,0,1-2.409,1.137,8.875,8.875,0,0,1-2.66.461,5.874,5.874,0,0,1-3.785-1.436,4.013,4.013,0,0,1-1.762-3.059c0-.449.258-.676.774-.676h1.567v-2.452a4.713,4.713,0,1,1,2.461,0v2.452h7.713a2.02,2.02,0,0,0,1.748-1.035,4.8,4.8,0,0,0,.673-2.66A8.361,8.361,0,0,0,502.512,158.892Z"
        fill="#333"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "eSports",
    props: {
        logo: {
            type: String,
            default: '#f54b0f'
        }
    }
};
</script>