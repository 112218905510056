<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.909"
    height="34.867"
    viewBox="0 0 42.909 34.867"
  >
    <path
      id="logo-twitter"
      d="M19.122,42.814A24.888,24.888,0,0,0,44.166,17.768V16.617a18.334,18.334,0,0,0,4.369-4.542,18.159,18.159,0,0,1-5.052,1.385,8.894,8.894,0,0,0,3.9-4.877,17.339,17.339,0,0,1-5.577,2.146,8.816,8.816,0,0,0-15.017,8.017A24.946,24.946,0,0,1,8.649,9.559a8.8,8.8,0,0,0,2.731,11.7,8.719,8.719,0,0,1-3.9-1.092v.1A8.835,8.835,0,0,0,14.4,28.946a8.835,8.835,0,0,1-3.9.156,8.8,8.8,0,0,0,8.152,6.026,17.651,17.651,0,0,1-10.941,3.9,19.054,19.054,0,0,1-2.087-.175,24.907,24.907,0,0,0,13.5,3.9"
      transform="translate(-5.625 -7.947)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTwitterButton",
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
};
</script>