// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDMom5GnbSzL1R4zNglfi7lZTXpw2Unlp4",
    authDomain: "donate-esport-945d0.firebaseapp.com",
    projectId: "donate-esport-945d0",
    storageBucket: "donate-esport-945d0.appspot.com",
    messagingSenderId: "315515977998",
    appId: "1:315515977998:web:a19064511998ce7b7cd75f"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;