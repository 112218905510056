import storage from '@/utils/storage'
import { userService } from '@/services'
import router from '@/router'

const state = {
    user: JSON.parse(storage.getEncrypt('user')),
    authenticated: !!storage.get('access_token'),
    sdkFacebook: storage.get('sdkFacebook') ? JSON.parse(storage.get('sdkFacebook')) : null,
    sdkTwitter: storage.get('sdkTwitter') ? storage.get('sdkTwitter') : null,
    isLazyLoading: false,
    isShowModalTicket: false,
    isShowNotifyTicketInvite: false,
    isLogin: false,
    deviceId: null,
    numberTickets: 1,
    numberTicketsRegister: 0,
    numberTicketsInvited: 0,
    numberTicketsFirstAccess: 0
}

const getters = {
    user(state) {
        return state.user;
    },
    authenticated(state) {
        return state.authenticated;
    },
    isLazyLoading(state) {
        return state.isLazyLoading;
    }
}

const mutations = {
    storeUser(state, user) {
        state.user = user;
        state.user.avatar = null;

        // if(!user.isRegister) {
        //     state.user.lastLogin = moment().format('YYYY-MM-DD');
        //     console.log(state.user.lastLogin);
        //     console.log(moment(state.user.lastLogin).add(1, 'days').format('YYYY-MM-DD'));
        //     console.log(moment().isSame(state.user.lastLogin, "day"));
        //     console.log(moment().isSame(null, "day"));
        // } else {
        //     state.user.lastLogin = null;
        // }
        
        storage.setEncrypt('user', JSON.stringify(user));
    },
    setTokenUser(state, user) {
        state.authenticated = true;
        storage.set('access_token', user.accessToken);
    },
    clearAuthData() {
        state.authenticated = false;
        state.user = null;
    },
    logout(state) {
        state.authenticated = false;
        state.user = null;

        storage.remove('user');
        storage.remove('access_token');
        storage.remove('twitter');
        storage.remove(`fblst_${process.env.VUE_APP_FACEBOOK_CLIENT_ID}`);
        
        if(state?.sdkFacebook && state.sdkFacebook?.logout) {
            state.sdkFacebook.logout();
        }

        if(state?.sdkTwitter && state.sdkFacebook?.logout) {
            state.sdkTwitter.logout();
        }
    },
    clearUser(state) {
        state.authenticated = false;
        state.user = null;

        storage.remove('user');
        storage.remove('access_token');
        storage.remove('twitter');
        storage.remove(`fblst_${process.env.VUE_APP_FACEBOOK_CLIENT_ID}`);
    },
    updateToken(state, payload) {
        state.user.accessToken = payload.accessToken;
        state.user.refreshToken = payload.refreshToken;
        storage.set('access_token', payload.accessToken);
        storage.setEncrypt('user', JSON.stringify(state.user));
    },
    setSdkFacebook(state, payload) {
        state.sdkFacebook = payload;
        storage.set('sdkFacebook', JSON.stringify(state.sdkFacebook));
    },
    setSdkTwitter(state, payload) {
        state.sdkTwitter = payload;
        storage.set('sdkTwitter', state.sdkTwitter);
    },
    updateUserProfile(state, payload) {
        state.user.avatar = payload.avatar && payload.avatar.includes('/images/avatar') ? process.env.VUE_APP_BACKEND + payload.avatar : null;
        state.user.currentPoints = payload.currentPoints;
        state.user.currentTickets = payload.currentTickets;
        state.user.username = payload.username;
        state.user.loginMethod = state.user.loginMethod || state.user.loginProvider; 
        state.user.createdGamer = payload.createdGamer !== undefined && payload.createdGamer === false ? false : true;
        storage.setEncrypt('user', JSON.stringify(state.user));
    },
    setTwitterAuth(state, payload) {
        if(!state.user) {
            state.user = Object.create({});
            state.user.twitter = payload;    
        } else {
            state.user.twitter = payload;
        }
        
        storage.setEncrypt('user', JSON.stringify(state.user));
    },
    clearSdk(state) {
        storage.remove('sdkFacebook');
        storage.remove('sdkTwitter');
    },
    updateAvatar(state, payload) {
        state.user.avatar = payload + '/?v' + new Date().getTime();
        storage.setEncrypt('user', JSON.stringify(state.user));
    },
    updateUsername(state, payload) {
        state.user.username = payload;
        storage.setEncrypt('user', JSON.stringify(state.user));
    },
    setLazyLoading(state, isLoading) {
        state.isLazyLoading = isLoading;
    },
    setUpModalTicket(state, payload) {
        state.isShowModalTicket = payload;
    },
    setUpModalTicketInvite(state, payload) {
        state.isShowNotifyTicketInvite = payload;
    },
    setNumberTicketInvited(state, payload) {
        state.numberTicketsInvited = payload;
    },
    setNumberTicketRegister(state, payload) {
        state.numberTicketsRegister = payload;
    },
    setNumberTicketFirstAccess(state, payload) {
        state.numberTicketsFirstAccess = payload;
    },
    setDeviceId(state, payload) {
        state.deviceId = payload;
    },
    handleLogin(state, payload) {
        state.isLogin = payload;
    }
}

const actions = {
    async login({ commit, dispatch }, payload) {
        try {
            const result = await userService.login(payload);
            if (result) {
                commit('storeUser', result.data);
                commit('setTokenUser', result.data);
                return Promise.resolve(result.data);
            }
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async logout({ commit }) {
        try {
            await userService.logout({ loginPlatform : 1 });
            commit('logout');
            router.push({ path: '/'});
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async verifyEmail({ commit }, payload) {
        try {
            const result = await userService.verifyEmail(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async checkOtp({ commit }, payload) {
        try {
            const result = await userService.checkOtp(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async resetOtp({ commit }, payload) {
        try {
            const result = await userService.resetOtp(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async resetPassOtp({ commit }, payload) {
        try {
            const result = await userService.resetPassOtp(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async signup({ commit }, payload) {
        try {
            const result = await userService.signup(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async forgotPassword({ commit }, payload) {
        try {
            const result = await userService.forgotPassword(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async updatePassword({ commit }, payload) {
        try {
            const result = await userService.updatePassword(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async loginSocialNetwork({ commit, dispatch }, payload) {
        try {
            const result = await userService.loginSocialNetwork(payload);
            if(result.data.status !== 0) {
                
                let newResult = {...result.data};
                newResult.loginMethod = payload.loginMethod;

                commit('storeUser', newResult);
                commit('setTokenUser', newResult);
            }
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async contactAdmin({ commit }, payload) {
        try {
            const result = await userService.contactAdmin(payload);
            return Promise.resolve(result.data || result.status);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}