const generalRoute = [
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/NotFound')
    },
    {
        path: '*',
        redirect: '/404'
    },
]

export default generalRoute