<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
  >
    <g
      id="_1331da0270284087fe6317347e202e7b"
      data-name="1331da0270284087fe6317347e202e7b"
      transform="translate(-3 -4)"
      opacity="0.5"
    >
      <path
        id="Path_2187"
        data-name="Path 2187"
        d="M28,7H4A1,1,0,0,0,3,8V23a5,5,0,0,0,5,5H24a5,5,0,0,0,5-5V8A1,1,0,0,0,28,7ZM27,9v2H5V9ZM24,26H8a3,3,0,0,1-3-3V13H27V23A3,3,0,0,1,24,26Z"
      />
      <path
        id="Path_2188"
        data-name="Path 2188"
        d="M19.29,16.29,15,20.59l-2.29-2.3a1,1,0,1,0-1.42,1.42l3,3a1,1,0,0,0,1.42,0l5-5a1,1,0,0,0-1.42-1.42ZM7,6h3a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6ZM22,6h3a1,1,0,0,0,0-2H22a1,1,0,0,0,0,2Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCalendarDate",
};
</script>