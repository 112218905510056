<template>
  <svg
    id="Component_10_4"
    data-name="Component 10 – 4"
    xmlns="http://www.w3.org/2000/svg"
    width="63.074"
    height="61.881"
    viewBox="0 0 63.074 61.881"
  >
    <ellipse
      id="Ellipse_16"
      data-name="Ellipse 16"
      cx="31.537"
      cy="30.94"
      rx="31.537"
      ry="30.94"
      transform="translate(0 0)"
      fill="#3d5a99"
    />
    <g
      id="Group_2600"
      data-name="Group 2600"
      transform="translate(22.86 15.028)"
    >
      <path
        id="Path_103"
        data-name="Path 103"
        d="M176.442,107.685V94.735h4.412l.66-5.047h-5.072V86.467c0-1.461.412-2.457,2.538-2.457h2.712V79.495a36.868,36.868,0,0,0-3.952-.2c-3.911,0-6.588,2.352-6.588,6.671v3.722H166.73v5.047h4.423v12.949Z"
        transform="translate(-166.73 -79.296)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCircleFacebook",
};
</script>