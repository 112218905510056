import api from './api'

const version = ''
const namespace = '/player-temp'
const resource = `${namespace}`

export default {
    upSertRegisterPlayer(params) {

        let formData = new FormData();
        if (params.file) {
            formData.append('file', params.file);
        }

        formData.append('playerInfo', params.playerInfo);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        if (params.playerId) {
            return api().put(`${resource}`, formData, config)
        } else {
            return api().post(`${resource}`, formData, config)
        }
    },
    getRegisterPlayersAll(params) {
        return api().get(`${resource}/admin/all-request`, { params })
    },
    getDetaiPlayerByAdmin(payload) {
        return api().get(`${resource}/admin/detail/${payload}`)
    },
    getDetailRegisterPlayer(payload) {
        return api().get(`${resource}/detail`)
    },
    approvePlayer(params) {
        return api().put(`${resource}/approve`, params)
    },
    rejectPlayer(params) {
        return api().put(`${resource}/reject`, params)
    },
}