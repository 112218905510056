<template>
  <div class="line-text"><span>{{ text }}</span></div>
</template>

<script>
export default {
    name: "LineText",
    props: {
        text: {
            type: String,
            default: "Line Text"
        }
    }
}
</script>