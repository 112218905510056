import axios from "axios"
import store from '@/store'
import router from '@/router'

export default () => {

    let instance = axios.create({
        baseURL: `${process.env.VUE_APP_BACKEND}`,
    });

    instance.interceptors.request.use(
        config => {
            let token;

            config.headers['Authorization'] = null;            

            if(store.state.auth.user) {
                token = store.state.auth.user.accessToken;
            }

            if(token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            config.headers['Content-Type'] = 'application/json';

            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    instance.interceptors.response.use(
        response => {
            //old: first-access
            if(response.headers['number-ticket-first-access'] && parseInt(response.headers['number-ticket-first-access'], 10) > 0) {
                store.commit('auth/setUpModalTicket', true);
                store.commit('auth/setNumberTicketFirstAccess', response.headers['number-ticket-first-access']);
            }

            return response;
        },
        error => {
            const originalRequest = error.config;

            if(error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                let refreshToken;

                if(store.state.auth.user) {
                    refreshToken = store.state.auth.user.refreshTokenWeb;
                }

                return axios.post(`${process.env.VUE_APP_BACKEND}/auth/refresh-token`, { refreshToken, loginPlatform: 1 })
                    .then(res => {
                        if(res && res.data) {
                            store.commit('auth/updateToken', res.data);
                            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
                            return instance(originalRequest);
                        }
                    })
                    .catch(async (err) => {
                        if(store.state?.auth?.user && store.state.auth.user.roleId === 1) {
                            await router.push({path: '/admin/login'});    
                        } else {
                            await router.push({path: '/login'});
                        }
                        
                        await store.commit('auth/logout');

                        location.reload();

                        return Promise.reject(err);
                    });
            }
            return Promise.reject(error?.response);
        }
    )

    return instance
}
