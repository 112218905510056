import { eventService } from '@/services'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    async getGameList({ commit }) {
        try {
            const result = await eventService.getGameList();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getEventList({ commit }, payload) {
        try {
            const result = await eventService.getEventList(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getEventDetail({ commit }, payload) {
        try {
            const result = await eventService.getEventDetail(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}