<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24.889"
    height="25.318"
    viewBox="0 0 24.889 25.318"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2604"
          data-name="Rectangle 2604"
          width="24.889"
          height="25.318"
          transform="translate(0 0)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="Group_2701"
      data-name="Group 2701"
      transform="translate(0 0)"
      clip-path="url(#clip-path)"
    >
      <path
        id="Path_554"
        data-name="Path 554"
        d="M40.306,30.14c3.323,0,3.716.013,5.029.074a6.786,6.786,0,0,1,2.311.436,3.85,3.85,0,0,1,1.431.947,3.927,3.927,0,0,1,.931,1.456,7.112,7.112,0,0,1,.429,2.351c.06,1.335.073,1.735.073,5.115s-.013,3.781-.073,5.115a7.112,7.112,0,0,1-.429,2.351,3.928,3.928,0,0,1-.931,1.456,3.849,3.849,0,0,1-1.431.947,6.782,6.782,0,0,1-2.311.436c-1.312.061-1.706.074-5.029.074s-3.717-.013-5.029-.074a6.783,6.783,0,0,1-2.311-.436,3.85,3.85,0,0,1-1.431-.947,3.931,3.931,0,0,1-.931-1.456,7.11,7.11,0,0,1-.429-2.351c-.06-1.335-.073-1.735-.073-5.115s.013-3.78.073-5.115a7.11,7.11,0,0,1,.429-2.351,3.93,3.93,0,0,1,.931-1.456,3.85,3.85,0,0,1,1.431-.947,6.786,6.786,0,0,1,2.311-.436c1.312-.061,1.706-.074,5.029-.074m0-2.281c-3.38,0-3.8.014-5.131.076a9,9,0,0,0-3.021.588,6.09,6.09,0,0,0-2.2,1.46,6.217,6.217,0,0,0-1.435,2.242,9.429,9.429,0,0,0-.578,3.073c-.061,1.35-.075,1.781-.075,5.219s.014,3.869.075,5.219a9.429,9.429,0,0,0,.578,3.073,6.217,6.217,0,0,0,1.435,2.242,6.089,6.089,0,0,0,2.2,1.46,9,9,0,0,0,3.021.588c1.327.062,1.751.076,5.131.076s3.8-.015,5.131-.076a9,9,0,0,0,3.021-.588,6.09,6.09,0,0,0,2.2-1.46A6.217,6.217,0,0,0,52.1,48.81a9.43,9.43,0,0,0,.578-3.073c.061-1.35.075-1.781.075-5.219s-.014-3.869-.075-5.219a9.43,9.43,0,0,0-.578-3.073,6.217,6.217,0,0,0-1.435-2.242,6.09,6.09,0,0,0-2.2-1.46,9,9,0,0,0-3.021-.588c-1.327-.062-1.751-.076-5.131-.076"
        transform="translate(-27.862 -27.859)"
        fill="#fff"
      />
      <path
        id="Path_555"
        data-name="Path 555"
        d="M145.239,138.846a6.5,6.5,0,1,0,6.39,6.5,6.446,6.446,0,0,0-6.39-6.5m0,10.72a4.22,4.22,0,1,1,4.148-4.22,4.184,4.184,0,0,1-4.148,4.22"
        transform="translate(-132.795 -132.688)"
        fill="#fff"
      />
      <path
        id="Path_556"
        data-name="Path 556"
        d="M353.393,108.359a1.494,1.494,0,1,1-1.493-1.519,1.506,1.506,0,0,1,1.493,1.519"
        transform="translate(-332.812 -102.458)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "IconMenuInstagram"
};
</script>