<template>
  <svg
    id="Component_61_1"
    data-name="Component 61 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="65.784"
    height="65.784"
    viewBox="0 0 65.784 65.784"
  >
    <rect
      id="Rectangle_2680"
      data-name="Rectangle 2680"
      width="49.519"
      height="48"
      transform="translate(8.132 12.152)"
      fill="#0a0a0a"
    />
    <g id="Group_2769" data-name="Group 2769">
      <path
        id="Path_568"
        data-name="Path 568"
        d="M0,0H65.784V65.784H0Z"
        fill="none"
      />
      <line
        id="Line_31"
        data-name="Line 31"
        x2="27"
        y2="27"
        transform="translate(19.892 21.652)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      />
      <line
        id="Line_32"
        data-name="Line 32"
        x1="27"
        y2="27"
        transform="translate(19.892 21.652)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "IconCloseModal"
};
</script>