<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.793"
    height="36.635"
    viewBox="0 0 45.793 36.635"
  >
    <path
      id="Icon_awesome-crown"
      data-name="Icon awesome-crown"
      d="M37.78,32.055H8.014A1.148,1.148,0,0,0,6.869,33.2v2.29a1.148,1.148,0,0,0,1.145,1.145H37.78a1.148,1.148,0,0,0,1.145-1.145V33.2A1.148,1.148,0,0,0,37.78,32.055Zm4.579-22.9a3.435,3.435,0,0,0-3.435,3.435,3.365,3.365,0,0,0,.315,1.417l-5.18,3.105a2.287,2.287,0,0,1-3.163-.83l-5.832-10.2a3.435,3.435,0,1,0-4.336,0L14.9,16.285a2.288,2.288,0,0,1-3.163.83L6.561,14.01a3.433,3.433,0,1,0-3.127,2.018,3.507,3.507,0,0,0,.551-.057l5.173,13.8H36.635l5.173-13.8a3.507,3.507,0,0,0,.551.057,3.435,3.435,0,1,0,0-6.869Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCrown",
};
</script>