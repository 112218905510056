<template>
  <transition enter-active-class="animate__animated animate__fadeIn">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "TransitionAlert",
};
</script>