<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="224.219"
    height="79.785"
    viewBox="0 0 224.219 79.785"
  >
    <defs>
      <filter
        id="Rectangle_2420"
        x="0"
        y="0"
        width="224.219"
        height="79.785"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="6" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.2" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Component_6_3"
      data-name="Component 6 – 3"
      transform="translate(9 2.999)"
    >
      <g transform="matrix(1, 0, 0, 1, -9, -3)" filter="url(#Rectangle_2420)">
        <g
          id="Rectangle_2420-2"
          data-name="Rectangle 2420"
          transform="translate(9 3)"
          stroke="#f8f1f0"
          stroke-width="2"
        >
          <rect width="206.219" height="61.786" rx="11" stroke="none" />
          <rect
            x="1"
            y="1"
            width="204.219"
            height="59.786"
            rx="10"
            fill="none"
          />
        </g>
      </g>
      <text
        id="Google_Play"
        data-name="Google Play"
        transform="translate(119.785 28.235)"
        fill="#fff"
        font-size="19"
        font-family="SegoeUI, Segoe UI"
        letter-spacing="-0.015em"
      >
        <tspan x="-48.742" y="0">Google Play</tspan>
      </text>
      <text
        id="で手に入れよう"
        transform="translate(102.785 47.912)"
        fill="#fff"
        font-size="12"
        font-family="YuGothicUI-Regular, Yu Gothic UI"
        letter-spacing="-0.015em"
      >
        <tspan x="-34.757" y="0">で手に入れよう</tspan>
      </text>
      <g
        id="Group_2588"
        data-name="Group 2588"
        transform="translate(16.528 11.467)"
      >
        <path
          id="path16"
          d="M74.747,55.521c4.3-2.36,7.585-4.158,7.866-4.3.9-.478,1.826-1.742,0-2.725-.59-.309-3.764-2.051-7.866-4.3L69.1,49.875Z"
          transform="translate(-49.688 -31.78)"
          fill="#ffd400"
        />
        <path
          id="path23"
          d="M22.935,64.4,4.9,82.576a2.392,2.392,0,0,0,1.461-.365c1.18-.646,13.709-7.5,22.221-12.164L22.935,64.4Z"
          transform="translate(-3.523 -46.306)"
          fill="#f33"
        />
        <path
          id="path30"
          d="M22.863,18.083l5.647-5.675S7.553.975,6.289.3A2.2,2.2,0,0,0,4.8.02Z"
          transform="translate(-3.452 0.011)"
          fill="#48ff48"
        />
        <path
          id="path37"
          d="M19.412,18.163,1.348.1C.618.269,0,.915,0,2.235V34.2c0,1.208.478,2.079,1.377,2.163Z"
          transform="translate(0 -0.069)"
          fill="#3bccff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'google-play'
};
</script>
