import api from './api'

const version = ''
const namespace = '/home'
const resource = `${namespace}`

export default {
    getEventTop() {
        return api().get(`${resource}/event/top`)
    },
    getEventMostVisited(params) {
        return api().get(`${resource}/event/most-visited`, { params })
    },
    getEventFinished(params) {
        return api().get(`${resource}/event/finished`, { params })
    },
    getGameTop(params) {
        return api().get(`${resource}/game/top`, { params })
    },
    addFirstAccess() {
        return api().post(`/ticket/add-first-access`)
    },
}