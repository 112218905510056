<template>
  <footer class="footer py-4 mt-2 mt-xxl-5">
    <b-container>
      <!-- <div class="top-footer text-center py-3 py-md-5">
        <b-link to="/about-us">About</b-link>
        <b-link to="/events">Event</b-link>
        <b-link to="/players">Player</b-link>
        <b-link v-if="authenticated && user.roleId !== 1" target="_blank" :href="`${anchor + user.hashUserId}`">Ticket</b-link>
        <b-link v-else-if="authenticated && user.roleId === 1">Ticket</b-link>
        <b-link v-else @click="handleLogin">Ticket</b-link>
      </div> -->
      <div class="middle-footer">
        <!-- <ShareNetwork
          :title="'Donate eSports'"
          network="twitter"
          :url="urlDetail"
          hashtags="Donate_eSports"
        >
          <span title="Twitter"><esports-twitter /></span>
        </ShareNetwork> -->

        <b-link href="https://twitter.com/Donate_eSports" target="_blank" title="Twitter"><esports-twitter /></b-link>
        <b-link v-b-tooltip.click custom-class="tooltip-title" title="Coming Soon!"><esports-facebook /></b-link>
        <!-- <ShareNetwork
          :title="'Donate eSports'"
          network="facebook"
          :url="urlDetail"
          hashtags="Donate_eSports"
        >
          <span title="Facebook"><esports-facebook /></span>
        </ShareNetwork> -->

        <!-- <b-link href="#" title="Facebook"><esports-facebook /></b-link>
        <b-link href="#" title="Instagram"><esports-instagram /></b-link> -->
      </div>
      <p class="logo-footer pt-3 pt-md-5 text-center">
        <b-link to="/" title="Donate eSports"><esports-logo-footer /></b-link>
      </p>
      <p class="bottom-footer text-center mt-md-5 pt-1 pb-1 mb-1">
        <b-link to="/contact-us">お問合せ</b-link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <b-link to="/term-of-use">利用規約</b-link>&nbsp;&nbsp;|&nbsp;&nbsp; 
        <b-link to="/privacy">プライバシーポリシー</b-link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <b-link class="d-block d-md-inline-block" to="/commercial-transaction">特定商取引法に関する表記</b-link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <b-link to="/operation-company">運営会社</b-link>
      </p>
      <p class="bottom-footer text-center pt-1 pb-3 pb-md-5 mb-0">
        Copyrights 2021 ©︎ Donate eSports all rights reserved.
      </p>
    </b-container>
    <LoginModal :isShowLoginModal="isShowLoginModal" @resetModal="resetModal" />
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import LoginModal from "@/components/Modals/LoginModal";

export default {
  name: "Footer",
  components: { LoginModal },
  data() {
    return {
      anchor: process.env.VUE_APP_ADVERTISEMENT,
      isShowLoginModal: false
    }
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
    urlDetail() {
      return window.location.href;
    },
  },
  methods: {
    resetModal(isHideModal) {
      this.isShowLoginModal = isHideModal;
    },
    handleLogin() {
      this.isShowLoginModal = true;
    },
    comingSoon() {
      alert('Coming Soon');
    }
  }
};
</script>