<template>
  <b-row align-h="between">
    <b-col sm="4" md="4"> {{ items.length }}件中{{ limit }}件表示 </b-col>
    <b-col sm="8" md="8" class="my-1">
      <div class="d-flex justify-content-end align-items-end">
        <b-form-group
          label="件表示"
          label-for="per-page-select"
          label-cols-sm="5"
          label-cols-md="5"
          label-cols-lg="5"
          label-align-sm="left"
          class="mb-0 w-10 flex-row-reverse"
        >
          <slot name="perpage"></slot>
        </b-form-group>
        <slot name="paging"></slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "NavPaging",
  props: {
    items: {
      type: [Array],
      default: () => [],
    },
    limit: {
      type: [Number, String],
      default: 0,
    },
    isResult: {
      type: [Boolean, Number],
      default: false,
    },
    totalRows: {
      type: [Number, String],
      default: 0,
    },
    perPage: {
      type: [Number, String],
      default: 20,
    },
  },
};
</script>