<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.208"
    height="29.217"
    viewBox="0 0 29.208 29.217"
  >
    <path
      id="_026d8e6a80492adbf57e41c9f0aaccf0"
      data-name="026d8e6a80492adbf57e41c9f0aaccf0"
      d="M19.969,24.344l-1.886,1.9V18.073a1.462,1.462,0,0,0-2.924,0v8.171l-1.886-1.9A1.468,1.468,0,1,0,11.2,26.42l4.385,4.385a1.462,1.462,0,0,0,.482.307,1.374,1.374,0,0,0,1.111,0,1.462,1.462,0,0,0,.482-.307l4.385-4.385a1.468,1.468,0,0,0-2.076-2.076ZM26.006,8.163A10.232,10.232,0,0,0,6.477,10.925,5.847,5.847,0,0,0,7.851,22.459a1.462,1.462,0,1,0,0-2.924,2.924,2.924,0,1,1,0-5.847,1.462,1.462,0,0,0,1.462-1.462A7.309,7.309,0,0,1,23.536,9.873a1.462,1.462,0,0,0,1.14.979,4.385,4.385,0,0,1,.351,8.537,1.464,1.464,0,0,0,.731,2.836,7.309,7.309,0,0,0,.248-14.062Z"
      transform="translate(-1.991 -2.013)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
    name: 'IconCloud',
    props: {
        color: {
            type: String,
            default: '#fff'
        }
    }
};
</script>