import LoadingOnButton from '@/components/Icons/LoadingOnButton'
import IconDown from '@/components/Icons/IconDown'
import Header from '@/components/Shared/Header'
import Footer from '@/components/Shared/Footer'
import TransitionAlert from '@/components/Shared/TransitionAlert'
import Logo from "@/components/Icons/Logo"
import OtpInput from "@bachdgvn/vue-otp-input"
import LogoFooter from "@/components/Icons/LogoFooter"
import LogoHeader from "@/components/Icons/LogoHeader"
import IconTwitter from "@/components/Icons/IconTwitter"
import IconFacebook from "@/components/Icons/IconFacebook"
import IconInstagram from "@/components/Icons/IconInstagram"
import IconCircleInstagram from "@/components/Icons/IconCircleInstagram"
import IconCircleTwitter from "@/components/Icons/IconCircleTwitter"
import IconCircleFacebook from "@/components/Icons/IconCircleFacebook"
import IconMenu from "@/components/Icons/IconMenu"
import IconSearch from "@/components/Icons/IconSearch"
import IconCloud from "@/components/Icons/IconCloud"
import IconGame from "@/components/Icons/IconGame"
import Phone from "@/assets/images/phone"
import Qcode from "@/assets/images/qcode"
import AppStore from "@/assets/images/app-store"
import GooglePlay from "@/assets/images/google-play"
import TitleArticle from "@/components/Shared/TitleArticle"
import BannerLoading from "@/components/ContentLoading/SectionBanner"
import PickupLoading from "@/components/ContentLoading/SectionPickup"
import IconCloseMenu from "@/components/Icons/IconCloseMenu"
import HeaderAuthen from "@/components/Shared/HeaderAuthen"
import FooterAuthen from "@/components/Shared/FooterAuthen"
import LogoHeaderAuthen from "@/components/Icons/LogoHeaderAuthen"
import LineText from "@/components/Shared/LineText"
import AppStoreFooter from "@/assets/images/app-store-footer"
import GooglePlayFooter from "@/assets/images/google-play-footer"
import IconMenuInstagram from "@/components/Icons/IconMenuInstagram"
import IconMenuTwitter from "@/components/Icons/IconMenuTwitter"
import IconMenuFacebook from "@/components/Icons/IconMenuFacebook"
import IconMenuSearch from "@/components/Icons/IconMenuSearch"
import IconCircleGoogle from "@/components/Icons/IconCircleGoogle"
import IconCalendar from "@/components/Icons/IconCalendar"
import IconCalendarDate from "@/components/Icons/IconCalendarDate"
import CalendarFilter from "@/components/Shared/CalendarFilter"
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import IconDoubleArrowLeft from "@/components/Icons/IconDoubleArrowLeft";
import IconArrowRight from "@/components/Icons/IconArrowRight";
import IconDoubleArrowRight from "@/components/Icons/IconDoubleArrowRight";
import IconCloseModal from "@/components/Icons/IconCloseModal";
import NotFoundResult from "@/components/Shared/NotFoundResult";
import IconCrown from "@/components/Icons/IconCrown"
import IconTwitterButton from "@/components/Icons/IconTwitterButton"
import IconCamera from "@/components/Icons/IconCamera"
import EventDetailLoading from "@/components/ContentLoading/EventDetailLoading"
import LogoHeaderMobile from "@/components/Icons/IconLogoHeaderMobile";
import IconHand from "@/components/Icons/IconHand";
import NavPaging from '@/views/AdminManager/ContentForm/NavPaging';
import IconCirclePlus from "@/components/Icons/IconCirclePlus"
import IconCrownEvent from "@/components/Icons/IconCrownEvent"
import IconAdd from "@/components/Icons/IconAdd"

const globalComponents = {
    install(Vue) {
        Vue.component('esports-loading-button', LoadingOnButton)
        Vue.component('esports-header', Header)
        Vue.component('esports-footer', Footer)
        Vue.component('esports-icon-down', IconDown)
        Vue.component('esports-logo', Logo)
        Vue.component('transition-alert', TransitionAlert)
        Vue.component('v-otp-input', OtpInput)
        Vue.component('esports-twitter', IconTwitter)
        Vue.component('esports-facebook', IconFacebook)
        Vue.component('esports-instagram', IconInstagram)
        Vue.component('esports-logo-footer', LogoFooter)
        Vue.component('esports-logo-header', LogoHeader)
        Vue.component('esports-icon-menu', IconMenu)
        Vue.component('esports-circle-twitter', IconCircleTwitter)
        Vue.component('esports-circle-facebook', IconCircleFacebook)
        Vue.component('esports-circle-instagram', IconCircleInstagram)
        Vue.component('esports-circle-google', IconCircleGoogle)
        Vue.component('esports-icon-search', IconSearch)
        Vue.component('esports-icon-cloud', IconCloud)
        Vue.component('esports-icon-game', IconGame)
        Vue.component('esports-phone', Phone)
        Vue.component('esports-qcode', Qcode)
        Vue.component('esports-app-store', AppStore)
        Vue.component('esports-google-play', GooglePlay)
        Vue.component('esports-title', TitleArticle)
        Vue.component('esports-banner-loading', BannerLoading)
        Vue.component('esports-pickup-loading', PickupLoading)
        Vue.component('esports-close-menu', IconCloseMenu)
        Vue.component('esports-header-authen', HeaderAuthen)
        Vue.component('esports-footer-authen', FooterAuthen)
        Vue.component('esports-logo-header-authen', LogoHeaderAuthen)
        Vue.component('esports-line-text', LineText)
        Vue.component('esports-app-store-footer', AppStoreFooter)
        Vue.component('esports-google-play-footer', GooglePlayFooter)
        Vue.component('esports-icon-menu-twitter', IconMenuTwitter)
        Vue.component('esports-icon-menu-facebook', IconMenuFacebook)
        Vue.component('esports-icon-menu-instagram', IconMenuInstagram)
        Vue.component('esports-icon-menu-search', IconMenuSearch)
        Vue.component('esports-calendar', IconCalendar)
        Vue.component('esports-calendar-date', IconCalendarDate)
        Vue.component('esports-calendar-filter', CalendarFilter)
        Vue.component('esports-icon-arrow-left', IconArrowLeft)
        Vue.component('esports-icon-double-arrow-left', IconDoubleArrowLeft)
        Vue.component('esports-icon-arrow-right', IconArrowRight)
        Vue.component('esports-icon-double-arrow-right', IconDoubleArrowRight)
        Vue.component('esports-icon-close', IconCloseModal)
        Vue.component('esports-not-result', NotFoundResult)
        Vue.component('esports-icon-crown', IconCrown)
        Vue.component('esports-twitter-button', IconTwitterButton)
        Vue.component('esports-icon-camera', IconCamera)
        Vue.component('esports-event-detail-loading', EventDetailLoading)
        Vue.component('esports-logo-header-mobile', LogoHeaderMobile)
        Vue.component('esports-icon-hand', IconHand)
        Vue.component('esports-icon-circle-plus', IconCirclePlus)
        Vue.component('esports-icon-crown-event', IconCrownEvent)
        Vue.component('esports-icon-add', IconAdd)
        Vue.component('esports-paging', NavPaging)
    }
}

export default globalComponents;