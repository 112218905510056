import api from '../api'

const version = ''
const namespace = '/ads-info'
const resource = `${namespace}`

export default {
    upSertAds(params) {
        let formData = new FormData();

        if (params.file) {
            formData.append('file', params.file);
        }
        formData.append('adsInfo', params.adsInfo);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        if (params.adsInfoId) {
            return api().put(`${resource}`, formData, config)
        } else {
            return api().post(`${resource}`, formData, config)
        }

    },
    getAdsAll(params) {
        return api().get(`${resource}/admin/all`, { params })
    },
    getDetailAds(payload) {
        return api().get(`${resource}/all/${payload}`)
    },
    deleteAds(params) {
        return api().delete(`${resource}/delete`, {params: { adsInfoId: params }})
    },
}