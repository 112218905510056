<template>
  <svg
    id="Component_11_4"
    data-name="Component 11 – 4"
    xmlns="http://www.w3.org/2000/svg"
    width="63.074"
    height="63.074"
    viewBox="0 0 63.074 63.074"
  >
    <circle
      id="Ellipse_15"
      data-name="Ellipse 15"
      cx="31.537"
      cy="31.537"
      r="31.537"
      transform="translate(0 0)"
      fill="#1da1f2"
    />
    <g
      id="Group_2596"
      data-name="Group 2596"
      transform="translate(16.934 21.226)"
    >
      <g id="Group_2595" data-name="Group 2595">
        <path
          id="Path_98"
          data-name="Path 98"
          d="M63.653,114.9c10.055,0,15.553-8.474,15.553-15.821,0-.241,0-.481-.016-.719a11.236,11.236,0,0,0,2.727-2.879,10.771,10.771,0,0,1-3.139.875,5.564,5.564,0,0,0,2.4-3.076,10.845,10.845,0,0,1-3.471,1.35,5.418,5.418,0,0,0-3.99-1.757,5.515,5.515,0,0,0-5.467,5.56A5.639,5.639,0,0,0,68.4,99.7a15.434,15.434,0,0,1-11.268-5.81,5.629,5.629,0,0,0,1.692,7.424,5.355,5.355,0,0,1-2.476-.7c0,.023,0,.047,0,.071a5.54,5.54,0,0,0,4.386,5.452,5.368,5.368,0,0,1-2.469.1,5.485,5.485,0,0,0,5.107,3.862,10.853,10.853,0,0,1-6.789,2.38,10.965,10.965,0,0,1-1.3-.077,15.286,15.286,0,0,0,8.379,2.5"
          transform="translate(-55.274 -92.868)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCircleTwitter",
};
</script>