<template>
  <b-card class="mb-2 rounded-0 border-0 p-0" body-class="px-0 pt-0 pt-md-3">
    <b-skeleton-img no-aspect height="539px"></b-skeleton-img>
    <div class="body-event px-3 px-xxl-0">
      <b-card-title class="my-4 py-md-4">
        <b-skeleton animation="wave" height="40px" width="85%"></b-skeleton
      ></b-card-title>
      <div class="line-title mb-5 pb-md-4">
        <h5>
          <b-skeleton animation="wave" height="20px" width="10%"></b-skeleton>
        </h5>
        <p class="pt-2 pt-md-4">
          <b-skeleton animation="wave" height="20px" width="60%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="60%"></b-skeleton>
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "eventDetailLoading",
};
</script>