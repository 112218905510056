import Vue from 'vue'
import VueRouter from 'vue-router'
import nProgress from 'nprogress'
import store from '@/store'
import routes from './routes'

Vue.use(VueRouter)

//Catching errors in push replace
//Of course, errors in replace can be captured the same way

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

nProgress.configure({ showSpinner: false });

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) return savedPosition

    if (to.hash) {
        return {
            selector: to.hash,
            behavior: 'smooth',
        }
    }

    return { x: 0, y: 0 }
}

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior
})

let fullPath = null;

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.getters['auth/authenticated']) {

            if (fullPath) {
                window.location.href = fullPath;
                return;
            }

            return next()
        } else {
            
            if (to.name === 'admin-manager') {
                return next({
                    path: '/admin/login',
                    params: { nextUrl: to.fullPath }
                })
            }

            if (to.name === 'my-page') {
                fullPath = to.fullPath;
            }

            return next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }
    }

    if (to.matched.some(record => record.meta.guest)) {
        if (store.getters['auth/authenticated']) {
            if (to.name === 'admin-login' && store.getters['auth/user'].roleId === 1) {
                return next({ path: '/admin-manager' })
            }
            return next({ path: '/home' })
        } else {
            return next()
        }
    }

    next()
})

router.beforeResolve((to, from, next) => {
    to.name && nProgress.start();

    next();
})

router.afterEach(() => {
    nProgress.done();
})

export default router