import cryptLib from 'cryptlib';

class Storage {
    get(key) {
        return localStorage.getItem(key);
    }
    set(key, value) {
        localStorage.setItem(key, value)
    }
    getEncrypt(key) {
        if(localStorage.getItem(key)) {
            return this.decryptStorage(localStorage.getItem(key))
        }
        return localStorage.getItem(key);
    }
    setEncrypt(key, value) {
        let encryptStr = this.encryptStorage(value);
        localStorage.setItem(key, encryptStr);
    }
    remove(key) {
        localStorage.removeItem(key)
    }
    clearAll() {
        localStorage.clear();
    }
    decryptStorage(value) {
        let iv = process.env.VUE_APP_SECRET_KEY;
        let key = cryptLib.getHashSha256(process.env.VUE_APP_SECRET_KEY, 32);
        let originalStr;

        try {
            originalStr = cryptLib.decrypt(value, key, iv);
        } catch (error) {
            this.remove('access_token');
            this.remove('user');
            window.location.href = '/';
        }

        return originalStr;
    }
    encryptStorage(value) {
        let iv = process.env.VUE_APP_SECRET_KEY;
        let key = cryptLib.getHashSha256(process.env.VUE_APP_SECRET_KEY, 32);
        let encryptedStr = cryptLib.encrypt(value, key, iv);

        return encryptedStr;
    }
}

export default new Storage();