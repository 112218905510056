<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.835"
    height="20.532"
    viewBox="0 0 24.835 20.532"
  >
    <path
      id="Path_553"
      data-name="Path 553"
      d="M63.084,113.4c9.372,0,14.5-7.9,14.5-14.747,0-.225,0-.448-.015-.67A10.473,10.473,0,0,0,80.109,95.3a10.039,10.039,0,0,1-2.926.816,5.187,5.187,0,0,0,2.24-2.867,10.109,10.109,0,0,1-3.235,1.258,5.05,5.05,0,0,0-3.719-1.638,5.141,5.141,0,0,0-5.1,5.183,5.255,5.255,0,0,0,.132,1.182A14.386,14.386,0,0,1,57,93.817a5.246,5.246,0,0,0,1.577,6.92,4.991,4.991,0,0,1-2.308-.648c0,.022,0,.043,0,.066a5.164,5.164,0,0,0,4.088,5.081,5,5,0,0,1-2.3.089,5.113,5.113,0,0,0,4.76,3.6,10.116,10.116,0,0,1-6.328,2.219,10.218,10.218,0,0,1-1.216-.072,14.248,14.248,0,0,0,7.811,2.328"
      transform="translate(-55.274 -92.868)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
    name: "IconMenuTwitter"
};
</script>