<template>
  <b-col md="10" lg="8">
    <b-card-group class="align-items-center h-100 verify-code">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="py-0 px-2 px-lg-5 mt-3">
          <b-form class="p-3" novalidate>
            <h5 class="text-center mb-3">
              <span class="headtitle">ご登録ありがとうございました。</span
              ><br />
              <span
                >会員登録で投票チケット<br /><span class="voted">{{ numberTicketsRegister }}票</span
                >獲得しました。</span
              >
              <!-- <b>ご登録ありがとうございました。<br /><span v-if="formRegisterData && formRegisterData.email">{{ formRegisterData.email }}</span>に、登録内容を送信しましたので、ご確認ください。</b> -->
            </h5>
            <!-- <p class="text-center py-2 py-xl-5 my-xl-4"><b-button class="btn-outline btn-home" @click="redirectHome">{{ $t('general_inputs.btn_home') }}</b-button></p> -->
            <!-- <div class="content-text p-3">
              <p>
                ご入力いただいたメールアドレスに受付完了について記載されたメールが送信されます。<br />
                なお、上記の内容のメールが届かない場合は「info@xxxxxxxxx.com」よりご連絡ください。
              </p>
              <ul>
                <li>
                  フリーメールアドレスでのご登録は、登録完了手続きメールの到着が遅れる場合がございます。
                </li>
                <li>
                  自動的に迷惑メールフィルタで迷惑メールとして別フォルダに振り分けられている場合があります。
                </li>
                <li>
                  メールは「info@xxxxxxxxxx.com」より送信されますので、受信拒否機能を設定されている場合は、ドメイン「xxxxxxxxxxk.com」のメールが 受信できるように設定後、改めてお試しください
                </li>
                <li>
                  入力していただいたメールアドレスが間違っていたと予想される場合は、お問い合わせ手続きを最初からやり直してください。
                </li>
              </ul>
            </div> -->
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NotifyVerifyUser",
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {},
    },
  },
  computed: {
    ...mapState("auth", ["numberTicketsRegister"]),
  },
  mounted() {
    setTimeout(() => {
      this.redirectHome();
    }, 5000);
  },
  methods: {
    redirectHome() {
      this.$router.push({ path: "/my-page" });
    },
  },
};
</script>