import api from './api'

const version = ''
const namespace = '/user'
const resource = `${namespace}`

export default {
    changeAvatar(params) {
        let formData = new FormData();
        formData.append('file', params.file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        return api().post(`${resource}/change-avatar`, formData, config);
    },
    inviteFriend(params) {
        return api().post('/invite-friend', params);
    },
    getMyPage() {
        return api().get(`${resource}/my-page`);
    },
    updateUsername(params) {
        return api().put(`${resource}/change-username`, params);
    },
    changePassword(params) {
        return api().post(`${resource}/change-password`, params);
    },
    getCurrentTicketUser() {
        return api().get(`${resource}/current-ticket`);
    },
    removeUser(params) {
        return api().post(`${resource}/remove`, params);
    },
    sendOtp(params) {
        return api().post(`${resource}/send-otp`, params);
    },
    checkOtp(params) {
        return api().post(`${resource}/check-otp`, params);
    },
    resetOtp(params) {
        return api().post(`${resource}/reset-remove-user-otp`, params);
    },
    getUsersAll(params) {
        return api().get(`${resource}/admin/all`, { params })
    },
    getDetailUser(payload) {
        return api().get(`${resource}/all/${payload}`)
    },
}