import userRoute from './user-web'
import adminManagerRoute from './admin-manager'
import store from '@/store'

const checkRoleAdmin = () => {
    return {
        path: '/admin-manager',
        name: 'admin',
        component: {
            render(c) {
                return c('router-view');
            }
        },
        meta: {
            requireAuth: true
        },
        beforeEnter(to, from, next) {
            if (store?.state?.auth?.user.roleId === 1) {
                return next();
            } 
            return next({ path: '/home'});         
        },
        children: [...adminManagerRoute]
    }
}

const middlewareRoute = [
    {
        path: '/home',
        name: 'home',
        component: {
            render(c) {
                return c('router-view');
            }
        },
        meta: {
            requireAuth: true
        },
        children: [...userRoute]
    },
    {
        path: '/events',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'events',
                component: () => import('@/views/Events/Index'),
            },
            {
                path: ':type_event',
                name: 'typeEvents',
                component: () => import('@/views/Events/Index'),
            },
            {
                path: 'detail/:id',
                name: 'eventDetail',
                component: () => import('@/views/Events/EventDetail'),
            }

        ]
    },
    {
        path: '/players',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'players',
                component: () => import('@/views/Players/Index'),
            }
        ]
    },
    // {
    //     path: '/tickets',
    //     component: () => import('@/containers/UserTemplate'),
    //     children: [
    //         {
    //             path: '',
    //             name: 'tickets',
    //             component: () => import('@/views/Tickets/Index'),
    //         }
    //     ]
    // },
    {
        path: '/games',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'games',
                component: () => import('@/views/Games/Index'),
            }
        ]
    },
    {
        path: '/term-of-use',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'term-of-use',
                component: () => import('@/views/TermPrivacy/Term'),
            }
        ]
    },
    {
        path: '/privacy',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'privacy-policy',
                component: () => import('@/views/TermPrivacy/Privacy'),
            }
        ]
    },
    {
        path: '/about-us',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'about-us',
                component: () => import('@/views/TermPrivacy/About'),
            }
        ]
    },
    {
        path: '/commercial-transaction',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'commercial-transaction',
                component: () => import('@/views/TermPrivacy/Commercial'),
            }
        ]
    },
    {
        path: '/operation-company',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'operation-company',
                component: () => import('@/views/TermPrivacy/Company'),
            }
        ]
    },
    {
        path: '/contact-us',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'about-us',
                component: () => import('@/views/Contact/Index'),
            }
        ]
    },
    {
        path: '/donate',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'donate',
                component: () => import('@/views/TermPrivacy/Donate'),
            }
        ]
    },
    {
        path: '/faqs',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'faqs',
                component: () => import('@/views/TermPrivacy/FAQ'),
            }
        ]
    },
    checkRoleAdmin(),
];

export default middlewareRoute;