import { adminEventService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async upSertEvent({ commit }, payload) {
        try {
            const result = await adminEventService.upSertEvent(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getEventsAll({ commit }, payload) {
        try {
            const result = await adminEventService.getEventsAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailEvent({ commit }, payload) {
        
        try {
            const result = await adminEventService.getDetailEvent(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async deleteEvent({ commit }, payload) {
        try {
            const result = await adminEventService.deleteEvent(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async voteWinnerByAdmin({ commit }, payload) {
        try {
            const result = await adminEventService.voteWinnerByAdmin(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}