<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.624"
    height="28.126"
    viewBox="0 0 14.624 28.126"
  >
    <path
      id="Path_103"
      data-name="Path 103"
      d="M176.222,107.422V94.592h4.312l.646-5h-4.957V86.4c0-1.448.4-2.434,2.481-2.434h2.651V79.493a35.55,35.55,0,0,0-3.863-.2c-3.822,0-6.439,2.33-6.439,6.609v3.687H166.73v5h4.323v12.829Z"
      transform="translate(-166.73 -79.296)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconFacebook",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>