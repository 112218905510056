<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g id="Group_3182" data-name="Group 3182" transform="translate(-1220 -602)">
      <circle
        id="Ellipse_75"
        data-name="Ellipse 75"
        cx="20"
        cy="20"
        r="20"
        transform="translate(1220 602)"
        fill="#ea6e30"
      />
      <g
        id="Group_3181"
        data-name="Group 3181"
        transform="translate(32.671 -145.847)"
      >
        <line
          id="Line_36"
          data-name="Line 36"
          x2="18.659"
          transform="translate(1198 767.847)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="4"
        />
        <line
          id="Line_37"
          data-name="Line 37"
          y2="17.694"
          transform="translate(1207.329 759)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconAdd",
};
</script>