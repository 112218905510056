<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.002"
    height="20"
    viewBox="0 0 20.002 20"
  >
    <path
      id="Layer_24"
      data-name="Layer 24"
      d="M23.709,22.6,19.3,18.186A8.687,8.687,0,1,0,18.186,19.3L22.6,23.709A.788.788,0,1,0,23.709,22.6ZM5.548,12.642a7.094,7.094,0,1,1,12.113,4.992h0A7.089,7.089,0,0,1,5.548,12.642Z"
      transform="translate(-3.94 -3.94)"
    />
  </svg>
</template>

<script>
export default {
    name: "IconSearch"
};
</script>
