import { userProfile } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async inviteFriend({ commit }, payload) {
        try {
            const result = await userProfile.inviteFriend(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async changeAvatar({ commit }, payload) {
        try {
            const result = await userProfile.changeAvatar(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getMyPage({ commit }) {
        try {
            const result = await userProfile.getMyPage();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async updateUsername({ commit }, payload) {
        try {
            const result = await userProfile.updateUsername(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async changePassword({ commit }, payload) {
        try {
            const result = await userProfile.changePassword(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getCurrentTicketUser({ commit }, payload) {
        try {
            const result = await userProfile.getCurrentTicketUser();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async removeUser({ commit }, payload) {
        try {
            const result = await userProfile.removeUser(payload);
            return Promise.resolve(result.data || result.status);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async sendOtp({ commit }, payload) {
        try {
            const result = await userProfile.sendOtp(payload);
            return Promise.resolve(result.data || result.status);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async checkOtp({ commit }, payload) {
        try {
            const result = await userProfile.checkOtp(payload);
            return Promise.resolve(result.data || result.status);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async resetOtp({ commit }, payload) {
        try {
            const result = await userProfile.resetOtp(payload);
            return Promise.resolve(result.data || result.status);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getUsersAll({ commit }, payload) {
        try {
            const result = await userProfile.getUsersAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailUser({ commit }, payload) {
        try {
            const result = await userProfile.getDetailUser(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}