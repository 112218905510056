const adminManagerRoute = [
    {
        path: '/',
        component: () => import('@/containers/AdminManagerTemplate'),
        children: [
            {
                path: '',
                name: 'admin-manager',
                component: () => import('@/views/AdminManager/Index')
            },
            {
                path: 'events',
                name: 'events-manage',
                component: () => import('@/views/AdminManager/Events')
            },
            {
                path: 'players',
                name: 'players-manage',
                component: () => import('@/views/AdminManager/Players')
            },
            {
                path: 'games',
                name: 'games-manage',
                component: () => import('@/views/AdminManager/Games')
            },
            {
                path: 'teams',
                name: 'teams-manage',
                component: () => import('@/views/AdminManager/Teams')
            },
            {
                path: 'cards',
                name: 'cards-manage',
                component: () => import('@/views/AdminManager/Cards')
            },
            {
                path: 'ads',
                name: 'ads-manage',
                component: () => import('@/views/AdminManager/Ads')
            },
            {
                path: 'send-requests',
                name: 'send-request-manage',
                component: () => import('@/views/AdminManager/SendRequest')
            },
            {
                path: 'users',
                name: 'users-manage',
                component: () => import('@/views/AdminManager/Users')
            },
            {
                path: 'sys-params',
                name: 'sys-params-manage',
                component: () => import('@/views/AdminManager/SettingsSys')
            },
        ]
    }
]

export default adminManagerRoute;