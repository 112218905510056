import { pointExchange } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async exchangePointCard({ commit }, payload) {
        try {
            const result = await pointExchange.exchangePointCard(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getCardHistory({ commit }, payload) {
        try {
            const result = await pointExchange.getCardHistory(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getPointHistory({ commit }, payload) {
        try {
            const result = await pointExchange.getPointHistory(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}