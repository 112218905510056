export default {
    data() {
        return {
            dismissSecs: 5,
            dismissCountDown: 0
        }
    },
    methods: {
        countDownChange(count) {
            this.dismissCountDown = count;
        }
    }
}