import auth from './auth'
import home from './home'
import event from './event'
import player from './player'
import game from './game'
import vote from './vote'
import team from './team'
import user from './user'
import point_exchange from './point_exchange'
import ads_info from './ads_info'
import register_player from './register_player'
import adminGame from './admin/admin_game'
import adminEvent from './admin/admin_event'
import adminPlayer from './admin/admin_player'
import adminTeam from './admin/admin_team'
import adminCard from './admin/admin_card'
import adminAds from './admin/admin_ads_info'
import adminSysParams from './admin/admin_sys_param'

export default {
    auth,
    home,
    event,
    player,
    game,
    vote,
    team,
    user,
    point_exchange,
    ads_info,
    register_player,
    adminGame,
    adminEvent,
    adminPlayer,
    adminTeam,
    adminCard,
    adminAds,
    adminSysParams
}