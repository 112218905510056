<template>
  <svg
    id="Group_2713"
    data-name="Group 2713"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path id="Path_557" data-name="Path 557" d="M0,0H32V32H0Z" fill="none" />
    <path
      id="Path_558"
      data-name="Path 558"
      d="M21.676,3.623h5.247a1.312,1.312,0,0,1,1.312,1.312V25.923a1.312,1.312,0,0,1-1.312,1.312H3.312A1.312,1.312,0,0,1,2,25.923V4.935A1.312,1.312,0,0,1,3.312,3.623H8.559V1h2.623V3.623h7.87V1h2.623ZM4.623,11.494V24.611H25.611V11.494Zm2.623,5.247h6.559v5.247H7.247Z"
      transform="translate(0.883 0.441)"
      fill="#ea6e30"
    />
  </svg>
</template>

<script>
export default {
    name: "IconCalendar"
};
</script>