<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.184"
    height="25.356"
    viewBox="0 0 13.184 25.356"
  >
    <path
      id="Path_552"
      data-name="Path 552"
      d="M175.288,104.652V93.086h3.887l.582-4.508h-4.469V85.7c0-1.305.363-2.194,2.237-2.194h2.39V79.474a32.051,32.051,0,0,0-3.482-.178c-3.446,0-5.8,2.1-5.8,5.958v3.324h-3.9v4.508h3.9v11.566Z"
      transform="translate(-166.73 -79.296)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
    name: "IconMenuFacebook"
};
</script>