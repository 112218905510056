import api from './api'

const version = ''
const namespace = '/team'
const resource = `${namespace}`

export default {
    getTeamDetail(teamId) {
        return api().get(`${resource}/all/${teamId}`)
    },
}