<template>
  <div class="text-center not-result p-4">
    <h2>{{ txt_result }} <slot></slot></h2>
  </div>
</template>

<script>
export default {
  name: "NotFoundResult",
  props: {
    txt_result: {
      type: String,
      default: "結果が見つかりません",
    },
  },
};
</script>