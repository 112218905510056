<template>
  <div class="calendar-filter d-flex align-items-center justify-content-center justify-content-md-start mb-3 pb-2 pb-md-0 mb-md-0">
    <esports-calendar />
    <span>大会一覧</span>
  </div>
</template>

<script>
export default {
    name: "CalendarFilter"
}
</script>
