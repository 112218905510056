import api from '../api'

const version = ''
const namespace = '/game'
const resource = `${namespace}`

export default {
    upSertGame(params) {
        let formData = new FormData();
        if (params.file) {
            formData.append('file', params.file);
        }
        formData.append('gameInfo', params.gameInfo);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        if (params.gameId) {
            return api().put(`${resource}`, formData, config)
        } else {
            return api().post(`${resource}`, formData, config)
        }

    },
    getGamesAll(params) {
        return api().get(`${resource}/admin/all`, { params })
    },
    getDetailGame(payload) {
        return api().get(`${resource}/all/${payload}`)
    },
    deleteGame(params) {
        return api().delete(`${resource}/delete`, {params: {gameId: params}})
    },
}