import { registerPlayerService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async upSertRegisterPlayer({ commit }, payload) {
        try {
            const result = await registerPlayerService.upSertRegisterPlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailRegisterPlayer({ commit }, payload) {
        try {
            const result = await registerPlayerService.getDetailRegisterPlayer();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetaiPlayerByAdmin({ commit }, payload) {
        try {
            const result = await registerPlayerService.getDetaiPlayerByAdmin(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getRegisterPlayersAll({ commit }, payload) {
        try {
            const result = await registerPlayerService.getRegisterPlayersAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async approvePlayer({ commit }, payload) {
        try {
            const result = await registerPlayerService.approvePlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async rejectPlayer({ commit }, payload) {
        try {
            const result = await registerPlayerService.rejectPlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}