<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52.777"
    height="35.867"
    viewBox="0 0 52.777 35.867"
  >
    <g id="_Group_" data-name="&lt;Group&gt;" transform="translate(0 0)">
      <path
        id="_Group_2"
        data-name="&lt;Group&gt;"
        d="M347.559,151.152a1.064,1.064,0,0,0,.314-.769,1.114,1.114,0,0,0-.314-.769,1.1,1.1,0,0,0-1.537,0,1.064,1.064,0,0,0-.314.769,1.113,1.113,0,0,0,.314.769,1.076,1.076,0,0,0,1.537,0Zm-5.706,3.086a1.083,1.083,0,1,0,.769.314A1.084,1.084,0,0,0,341.853,154.238Z"
        transform="translate(-304.496 -143.792)"
        fill="#ea6e30"
      />
      <ellipse
        id="_Group_3"
        data-name="&lt;Group&gt;"
        cx="5.438"
        cy="5.438"
        rx="5.438"
        ry="5.438"
        transform="translate(5.077 5.683)"
        fill="#ea6e30"
      />
      <path
        id="_Group_4"
        data-name="&lt;Group&gt;"
        d="M218.636,103.326A3.688,3.688,0,0,0,215.76,102h-3.645a3.688,3.688,0,0,0-2.876,1.328l-.838,1h11.074Z"
        transform="translate(-187.544 -101.998)"
        fill="#ea6e30"
      />
      <path
        id="_Group_5"
        data-name="&lt;Group&gt;"
        d="M72.363,110.748a10.052,10.052,0,0,0-7.441,2.9.916.916,0,0,1-.652.268H47.187a.925.925,0,0,1-.675-.291c-.012-.012-.012-.023-.023-.023l-.012-.012A10.138,10.138,0,0,0,29.3,120.856a10.265,10.265,0,0,0,3.412,7.616.9.9,0,0,1,.279.687v12.123A4.367,4.367,0,0,0,37.4,145.59H39.42a4.341,4.341,0,0,0,4.32-3.8l.78-6.242a6.224,6.224,0,0,1,6.172-5.45h.023l10.061.035a6.237,6.237,0,0,1,6.149,5.438l.792,6.23a4.349,4.349,0,0,0,4.32,3.785h1.886a4.324,4.324,0,0,0,4.355-4.309V129.159a.931.931,0,0,1,.326-.71,9.827,9.827,0,0,0,3.47-7.43A10.108,10.108,0,0,0,72.363,110.748Zm-32.548,17.4a7.3,7.3,0,1,1,7.3-7.3A7.3,7.3,0,0,1,39.815,128.146ZM69.51,114.23a2.948,2.948,0,1,1,0,4.169A2.945,2.945,0,0,1,69.51,114.23Zm-9.619,12.728H51.507a.932.932,0,1,1,0-1.863h8.384a.932.932,0,1,1,0,1.863Zm6.766-2.76a2.947,2.947,0,1,1,2.084-5.031h0a2.945,2.945,0,0,1,0,4.169A2.9,2.9,0,0,1,66.657,124.2Zm6.987,4.076a2.946,2.946,0,1,1,.035-4.181A2.946,2.946,0,0,1,73.644,128.274Zm4.972-4.938a2.923,2.923,0,0,1-2.073.827h-.023a2.9,2.9,0,0,1-2.084-.85,2.94,2.94,0,1,1,5.042-2.026A2.771,2.771,0,0,1,78.617,123.336Z"
        transform="translate(-29.3 -109.723)"
        fill="#ea6e30"
      />
      <path
        id="_Group_6"
        data-name="&lt;Group&gt;"
        d="M389.055,191.8a1.1,1.1,0,0,0-.769.314,1.058,1.058,0,0,0-.012,1.514,1.043,1.043,0,0,0,.769.291h.012a1.082,1.082,0,0,0,.769-.279,1.049,1.049,0,0,0,.326-.757,1.064,1.064,0,0,0-.314-.769A1.131,1.131,0,0,0,389.055,191.8Zm-4.961,4.914h0a1.079,1.079,0,0,0-.78,1.84,1.087,1.087,0,1,0,1.549-1.525A1.113,1.113,0,0,0,384.094,196.714Z"
        transform="translate(-341.811 -181.343)"
        fill="#ea6e30"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "IconGame"
};
</script>