<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="178"
    height="68"
    viewBox="0 0 178 68"
  >
    <defs>
      <filter
        id="Rectangle_2420"
        x="0"
        y="0"
        width="178"
        height="68"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="6" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.2" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Component_6_3"
      data-name="Component 6 – 3"
      transform="translate(9 3)"
    >
      <g transform="matrix(1, 0, 0, 1, -9, -3)" filter="url(#Rectangle_2420)">
        <g
          id="Rectangle_2420-2"
          data-name="Rectangle 2420"
          transform="translate(9 3)"
          stroke="#f8f1f0"
          stroke-width="2"
        >
          <rect width="160" height="50" rx="11" stroke="none" />
          <rect x="1" y="1" width="158" height="48" rx="10" fill="none" />
        </g>
      </g>
      <text
        id="Google_Play"
        data-name="Google Play"
        transform="translate(91.882 22.7)"
        fill="#fff"
        font-size="13"
        font-family="SegoeUI, Segoe UI"
        letter-spacing="-0.015em"
      >
        <tspan x="-33.35" y="0">Google Play</tspan>
      </text>
      <text
        id="で手に入れよう"
        transform="translate(85.882 36.183)"
        fill="#fff"
        font-size="10"
        font-family="YuGothicUI-Regular, Yu Gothic UI"
        letter-spacing="-0.015em"
      >
        <tspan x="-28.964" y="0">で手に入れよう</tspan>
      </text>
      <g
        id="Group_2588"
        data-name="Group 2588"
        transform="translate(16.541 10.7)"
      >
        <path
          id="path16"
          d="M73.384,52.79c3.261-1.791,5.755-3.155,5.968-3.261.682-.362,1.386-1.322,0-2.068-.448-.234-2.856-1.556-5.968-3.261L69.1,48.506Z"
          transform="translate(-54.371 -34.776)"
          fill="#ffd400"
        />
        <path
          id="path23"
          d="M18.585,64.4,4.9,78.191a1.815,1.815,0,0,0,1.108-.277c.9-.49,10.4-5.691,16.861-9.23L18.585,64.4Z"
          transform="translate(-3.856 -50.67)"
          fill="#f33"
        />
        <path
          id="path30"
          d="M18.506,13.719l4.285-4.306S6.889.737,5.93.226A1.667,1.667,0,0,0,4.8.013Z"
          transform="translate(-3.777 0.011)"
          fill="#48ff48"
        />
        <path
          id="path37"
          d="M14.729,13.806,1.023.1C.469.228,0,.718,0,1.72V25.978c0,.917.362,1.577,1.044,1.641Z"
          transform="translate(0 -0.076)"
          fill="#3bccff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "GooglePlayFooter"
};
</script>