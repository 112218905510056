import moment from 'moment'
import gsap from 'gsap'
import { mapMutations } from "vuex";

export default {
    data() {
        return {
            hideBeforeLoading: false,
            animated: false,
            isAnimatedMenu: false
        }
    },
    mounted() { },
    computed: {},
    methods: {
        letterName(full_name) {
            if (!full_name) return 'ES';
            let initials = full_name.match(/\b\w/g) || [];
            initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
            return initials;
        },
        catchErrorNetwork(err) {
            if (err?.errors ||
                err?.status === 404 ||
                err?.status === 500 ||
                err?.response?.status === 401 ||
                err?.response?.status === 400 ||
                err?.response?.status === 403 ||
                err?.status === 403
            ) {
                console.log(err);
            } else {
                alert('Network error, please reload again');
            }
        },
        getUniqueListBy(arr, key) {
            if (!key) return [...new Set(arr)]
            return [...new Map(arr.map(item => [item[key], item])).values()]
        },
        mainProps(color = '#f7f7f7') {
            return {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: color
            }
        },
        scrollToTop(ele) {
            if(ele) {
                document.getElementById(ele).scroll({top: 0,behavior:'smooth'});
                return;
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        redirectEventDetail(router, id) {
            return router.push({ path: '/events/detail/' + id });
        },
        getArchivement(archives) {
            if (archives.length === 1) return archives[0];
            let newArchives = archives.filter(item => item !== null).filter(item => item !== '');

            return newArchives.reduce((result, elem, index) => {
                return (
                result + "" + elem + (newArchives.length - 1 !== index ? ", " : "")
                );
            }, "");
        },
        formatPoint(number) {
            if (!number) return 0;
            return parseFloat(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        makeToast(variant = null, message) {
            this.$bvToast.toast(message, {
                variant: variant,
                solid: true,
                toastClass: 'toast-modal'
            });
        },
        imageUserUrl(user) {
            return process.env.VUE_APP_BACKEND + user.picture;
        },
        isMobile() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            }) || window.innerWidth <= 767;
        },
        getNamePicture(picture) {
            if (!picture) return '';
            let indexImg = picture.lastIndexOf("/images/name/");
            let lastIndexPicture = picture.lastIndexOf("/images/name/");

            lastIndexPicture = lastIndexPicture > -1 ? lastIndexPicture : picture.lastIndexOf("/images/avatar");
            let substringFilename = picture.substring(0, lastIndexPicture);

            return picture.replace(
                substringFilename + `${indexImg > -1 ? '/images/name/' : '/images/avatar/'}`,
                ""
            );
        },
        initStagger() {
            this.$nextTick(() => {
                gsap.from(".card-stagger", {
                    duration: 0.5,
                    opacity: 0,
                    scale: 0,
                    y: 0,
                    ease: "power1",
                    stagger: {
                        each: 0.1,
                        form: "start",
                    },
                });
            });
        },
        handleOverBtn() {
            this.animated = true;
        },
        handleLeaveBtn() {
            this.animated = false;
        },
        showDropdownMenu() {
            this.isAnimatedMenu = true;
        },
        hideDropdownMenu() {
            this.isAnimatedMenu = false;
        },
        truncateText(str, maxLength) {
            if(!str) return '';
            if(str.length > maxLength) {
                return str.substring(0, maxLength) + '...';
            }
            return str;
        },
        getSkyFlatAds() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                return process.env.VUE_APP_ADVERTISEMENT_ANDROID;
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return process.env.VUE_APP_ADVERTISEMENT_IOS;
            }

            return process.env.VUE_APP_ADVERTISEMENT;
        },
        ...mapMutations({
            setLazyLoading: 'auth/setLazyLoading'
        })
    }
}