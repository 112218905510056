<template>
  <b-modal
    body-class="p-0"
    dialog-class="modal-login"
    @hidden="resetModal"
    hide-footer
    hide-header
    v-model="isShow"
  >
    <div class="register-page login-page">
      <b-row class="justify-content-center w-100">
        <component
          @resetModal="resetModal"
          @activeComponent="activeComponent"
          :formRegisterData="formRegisterData"
          :is="currentTabComponent"
          :isModal="true"
        ></component>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import LoginForm from "@/views/Auth/Login/LoginForm";
import NotifyVerifyUser from "@/views/Auth/Register/NotifyVerifyUser";
import RegisterEnterUserNameSocial from "@/views/Auth/Register/RegisterEnterUserNameSocial";

export default {
  name: "LoginModal",
  components: {
    LoginForm,
    RegisterEnterUserNameSocial,
    NotifyVerifyUser,
  },
  props: {
    isShowLoginModal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShowLoginModal(nVal) {
      this.isShow = nVal;
    },
  },
  data() {
    return {
      isShow: false,
      formRegisterData: null,
      currentTabComponent: LoginForm,
    };
  },
  methods: {
    activeComponent(payload) {
      this.formRegisterData = payload.user;
      this.currentTabComponent = payload.name;
    },
    resetModal() {
      this.$emit("resetModal", false);
    },
  },
};
</script>