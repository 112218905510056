<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.445"
    height="28.778"
    viewBox="0 0 44.445 28.778"
  >
    <g
      id="_3a564bd7a73a373e916d99a872758ffc"
      data-name="3a564bd7a73a373e916d99a872758ffc"
      transform="translate(-4 -11.29)"
    >
      <path
        id="Path_2310"
        data-name="Path 2310"
        d="M13.11,41.966c.444-.087.984-.19,1.6-.294a75.734,75.734,0,0,1,12.619-1.016,75.736,75.736,0,0,1,12.619,1.016c.619.1,1.159.206,1.6.294a17.418,17.418,0,0,1,3.437-11.1,1.437,1.437,0,0,0,.278-1.087,1.475,1.475,0,0,0-2.333-.992,15.611,15.611,0,0,1-4.564,2.468.643.643,0,0,1-.556-.1.691.691,0,0,1-.3-.516,15.567,15.567,0,0,1,.294-3.4c.27-1.667.413-2.587-.143-3.119a1.013,1.013,0,0,0-.929-.262c-.865.175-1.7,1.23-3,2.944a15.174,15.174,0,0,1-1.968,2.3c-.6-.516-1.556-3.437-1.683-4.921a6.633,6.633,0,0,0-1.063-2.849,2.119,2.119,0,0,0-3.381,0A6.761,6.761,0,0,0,24.57,24.2c-.119,1.452-1.071,4.365-1.675,4.889A14.891,14.891,0,0,1,20.928,26.8c-1.3-1.714-2.135-2.77-3-2.944a1.013,1.013,0,0,0-.929.262c-.556.532-.413,1.452-.143,3.119a15.566,15.566,0,0,1,.294,3.4.691.691,0,0,1-.3.516.65.65,0,0,1-.556.1,15.611,15.611,0,0,1-4.564-2.468,1.476,1.476,0,0,0-1.754.016,1.46,1.46,0,0,0-.579.976,1.437,1.437,0,0,0,.278,1.087A17.418,17.418,0,0,1,13.11,41.966Z"
        transform="translate(-1.11 -1.898)"
        fill="#ea6e30"
      />
      <circle
        id="Ellipse_72"
        data-name="Ellipse 72"
        cx="2.841"
        cy="2.841"
        r="2.841"
        transform="translate(23.381 11.29)"
        fill="#ea6e30"
      />
      <circle
        id="Ellipse_73"
        data-name="Ellipse 73"
        cx="2.024"
        cy="2.024"
        r="2.024"
        transform="translate(4 21.869)"
        fill="#ea6e30"
      />
      <path
        id="Path_2311"
        data-name="Path 2311"
        d="M16.037,16.1a2.437,2.437,0,1,0,2.437,2.437A2.437,2.437,0,0,0,16.037,16.1Z"
        transform="translate(-1.981 -0.993)"
        fill="#ea6e30"
      />
      <circle
        id="Ellipse_74"
        data-name="Ellipse 74"
        cx="2.024"
        cy="2.024"
        r="2.024"
        transform="translate(44.397 21.869)"
        fill="#ea6e30"
      />
      <path
        id="Path_2312"
        data-name="Path 2312"
        d="M44.26,18.537A2.433,2.433,0,1,0,46.7,16.1,2.433,2.433,0,0,0,44.26,18.537Z"
        transform="translate(-8.307 -0.993)"
        fill="#ea6e30"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCrownEvent",
};
</script>