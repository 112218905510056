import { adsInfoService } from '@/services'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    async getTicketList({ commit }, payload) {
        try {
            const result = await adsInfoService.getTicketList(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getTicket({ commit }, payload) {
        try {
            const result = await adsInfoService.getTicket(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async shareEvent({ commit, dispatch }, payload) {
        try {
            const result = await adsInfoService.shareEvent(payload);
            const dataUser = await this.dispatch('user/getMyPage', { root: true });
            this.commit('auth/updateUserProfile', dataUser, { root: true });
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async postTicketByAds({ commit, dispatch }, payload) {
        try {
            const result = await adsInfoService.postTicketByAds(payload);
            const dataUser = await this.dispatch('user/getMyPage', { root: true });
            this.commit('auth/updateUserProfile', dataUser, { root: true });
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async postAd({ commit, dispatch }, payload) {
        try {
            const result = await adsInfoService.postAd(payload);
            const dataUser = await this.dispatch('user/getMyPage', { root: true });
            this.commit('auth/updateUserProfile', dataUser, { root: true });
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    }
    
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}