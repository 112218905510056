<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    if(this.$route.name && this.$route.name === 'eventDetail') return null;

    return {
      meta:[
        { name: 'description', content: '毎日のトーナメントを要約し、それらのトーナメントの結果を投稿します, トーナメント情報、アスリート、トーナメント結果、投票結果、勝利結果を管理する' },
        { property: 'og:description', content: '毎日のトーナメントを要約し、それらのトーナメントの結果を投稿します, トーナメント情報、アスリート、トーナメント結果、投票結果、勝利結果を管理する' },
        { property: 'og:image', content: `${process.env.VUE_APP_FRONT_END}/app-icon.jpg` },
        { property: 'og:site_name', content: `eSport` },
        { property: 'og:type', content: `website` },
        { property: 'og:url', content: `${process.env.VUE_APP_FRONT_END}` },
        { name: 'robots', content: `index, follow` },
        { name: "twitter:card", content: 'summary' },
        { name: "twitter:url", content: process.env.VUE_APP_FRONT_END },
        { name: "twitter:description", content: '毎日のトーナメントを要約し、それらのトーナメントの結果を投稿します, トーナメント情報、アスリート、トーナメント結果、投票結果、勝利結果を管理する' },
        { name: "twitter:image", content: `${process.env.VUE_APP_FRONT_END}/app-icon.jpg` },
      ]
    }
  }
};
</script>

<style lang="scss">
@import "~animate.css/animate.min.css";
@import "~font-awesome/css/font-awesome.css";
@import "~vue-slick-carousel/dist/vue-slick-carousel.css";
@import "~nprogress/nprogress.css";
@import "@/assets/scss/style";
</style>
