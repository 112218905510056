import api from './api'

const version = ''
const namespace = '/event'
const resource = `${namespace}`

export default {
    getGameList() {
        return api().get(`/game/ref`)
    },
    getEventList(params) {
        return api().get(`${resource}/all`, { params })
    },
    getEventDetail(eventId) {
        return api().get(`${resource}/all/${eventId}`)
    },
}