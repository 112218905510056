<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.035"
    height="18.471"
    viewBox="0 0 34.035 18.471"
  >
    <path
      id="Path_2175"
      data-name="Path 2175"
      d="M1318.442-9796.383l12.784,11.237,12.784-11.237"
      transform="translate(-1314.208 9800.616)"
      fill="none"
      stroke="#ea6e30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="6"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDown",
};
</script>