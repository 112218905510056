<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.045"
    height="27.826"
    viewBox="0 0 21.045 27.826"
  >
    <g
      id="Component_80_6"
      data-name="Component 80 – 6"
      transform="translate(0.5 0.5)"
    >
      <path
        id="Union_10"
        data-name="Union 10"
        d="M7.282,26.826v-7.16a10.023,10.023,0,1,1,5.234.066v7.093Z"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
      />
      <g
        id="Group_3150"
        data-name="Group 3150"
        transform="translate(4.386 4.385)"
      >
        <path
          id="Path_132"
          data-name="Path 132"
          d="M385.414,200.651a.638.638,0,0,1-.637-.637v-10a.637.637,0,0,1,1.275,0v10A.638.638,0,0,1,385.414,200.651Z"
          transform="translate(-379.777 -189.377)"
          :fill="color"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M389.531,196.535h-10a.637.637,0,0,1,0-1.275h10a.637.637,0,0,1,0,1.275Z"
          transform="translate(-378.894 -190.26)"
          :fill="color"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCirclePlus",
  props: {
    color: {
      type: String,
      default: "#0A0A0A"
    }
  }
};
</script>