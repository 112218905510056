<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="103.365"
    height="40.496"
    viewBox="0 0 103.365 40.496"
  >
    <g id="Group_2754" data-name="Group 2754" transform="translate(0 0)">
      <rect
        id="Rectangle_2431"
        data-name="Rectangle 2431"
        width="3.437"
        height="9.442"
        transform="translate(35.281 31.054)"
        fill="#231f20"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M407.891,158.206l.2,4.907a1.985,1.985,0,0,0,.174.821,7.046,7.046,0,0,0,.858,1.018.883.883,0,0,1,.209.626q0,.92-1.1,1.282a14.41,14.41,0,0,1-3.931.363,7.748,7.748,0,0,1-2.717-.334,1.173,1.173,0,0,1-.823-1.129.8.8,0,0,1,.084-.412,7.448,7.448,0,0,1,.558-.648q.558-.584.558-3.9a6.846,6.846,0,0,0-.446-2.843,1.623,1.623,0,0,0-2.621-.14,4.743,4.743,0,0,0-.347,2.175v2.76a4.105,4.105,0,0,0,.1,1.129,6.866,6.866,0,0,0,.691,1.184,1.085,1.085,0,0,1,.152.572q0,.822-1.142,1.212a11.933,11.933,0,0,1-3.568.391q-3.875,0-3.875-1.6a.9.9,0,0,1,.118-.509,2.541,2.541,0,0,1,.564-.494,1.16,1.16,0,0,0,.461-.607,10.826,10.826,0,0,0,.18-1.679q.1-1.588.1-3.331a3.449,3.449,0,0,0-.147-1.26,2.3,2.3,0,0,0-.844-.732,1.048,1.048,0,0,1-.112-1.833,9.188,9.188,0,0,1,2.007-.9q1.338-.473,2.579-.8a8.124,8.124,0,0,1,1.7-.327.925.925,0,0,1,.7.307,1.031,1.031,0,0,1,.286.725c0,.028-.006.144-.015.349a1.571,1.571,0,0,0-.013.2q0,.669.557.668c.224,0,.459-.176.711-.529a3.974,3.974,0,0,1,3.484-1.728,4.954,4.954,0,0,1,3.01.92,3.536,3.536,0,0,1,1.226,1.539A8.564,8.564,0,0,1,407.891,158.206Z"
        transform="translate(-350.748 -147.599)"
        fill="#444c4c"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M462.208,153.987v4.71a2.173,2.173,0,0,0,.411,1.367,1.277,1.277,0,0,0,1.052.529,3.171,3.171,0,0,0,1.436-.5.812.812,0,0,1,.419-.125.77.77,0,0,1,.613.4,1.519,1.519,0,0,1,.278.878,2.217,2.217,0,0,1-.948,1.659,7.145,7.145,0,0,1-4.794,1.686,5.036,5.036,0,0,1-3.583-1.262,4.335,4.335,0,0,1-1.352-3.324v-6.44a.648.648,0,0,0-.075-.4.64.64,0,0,0-.4-.077h-1.436c-.269,0-.441-.045-.516-.133a1.094,1.094,0,0,1-.112-.619v-.613a.705.705,0,0,1,.32-.614l6.383-4.014a1.253,1.253,0,0,1,.586-.125h1.129a.555.555,0,0,1,.468.16,1.168,1.168,0,0,1,.118.634v1.882a.779.779,0,0,0,.132.543.871.871,0,0,0,.564.125h2.58a.863.863,0,0,1,.668.189,1.4,1.4,0,0,1,.153.8v.822a1.6,1.6,0,0,1-.2.927.838.838,0,0,1-.71.257h-2.523a.544.544,0,0,0-.669.67Z"
        transform="translate(-378.362 -144.872)"
        fill="#444c4c"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M339.685,147.581a7.851,7.851,0,0,0-4.154-3.651,14.2,14.2,0,0,0-5.044-.726c-.531,0-.971.009-1.325.028q-2.507.125-4.5.125-.529,0-1.7-.042c-.354-.009-.66-.013-.92-.013a3.706,3.706,0,0,0-1.846.383,1.182,1.182,0,0,0-.663,1.066,1.276,1.276,0,0,0,.962,1.241,1.375,1.375,0,0,1,.85.627,4.334,4.334,0,0,1,.182,1.561q0,2.982-.09,6.007t-.2,4.069a3.405,3.405,0,0,1-.307,1.22,1.175,1.175,0,0,1-.753.453,1.848,1.848,0,0,0-.843.439,1.054,1.054,0,0,0-.257.759,1.341,1.341,0,0,0,.585,1.129,2.492,2.492,0,0,0,1.519.432q.265,0,.949-.056,1.086-.083,1.965-.084h1q1.283,0,2.968.056l1.248.036v-6.662a2.641,2.641,0,1,1,1.379,0v6.706l.508.017a11.207,11.207,0,0,0,3.568-.557,8.524,8.524,0,0,0,4.459-3.289,9.645,9.645,0,0,0,1.24-2.738,11.23,11.23,0,0,0,.461-3.157A11.008,11.008,0,0,0,339.685,147.581Z"
        transform="translate(-319.072 -143.204)"
        fill="#ea6e30"
      />
      <path
        id="Path_123"
        data-name="Path 123"
        d="M375.659,156.761a7.113,7.113,0,0,0-2.217-2.467,7.7,7.7,0,0,0-4.473-1.324,9.235,9.235,0,0,0-6.23,2.2,6.748,6.748,0,0,0-2.453,5.381,6.2,6.2,0,0,0,2.007,4.794,7.653,7.653,0,0,0,5.394,1.9h.027v-4.3a2.641,2.641,0,1,1,1.379,0v4.209a9.609,9.609,0,0,0,4.656-1.924,6.292,6.292,0,0,0,2.773-5.142A6.867,6.867,0,0,0,375.659,156.761Z"
        transform="translate(-337.29 -147.521)"
        fill="#444c4c"
      />
      <path
        id="Path_124"
        data-name="Path 124"
        d="M440.211,163.438a1.938,1.938,0,0,1-.585-.153.964.964,0,0,1-.293-.391,7.958,7.958,0,0,1-.306-2.8l.027-2.077a6.128,6.128,0,0,0-.354-2.321,3.622,3.622,0,0,0-1.248-1.5,6.8,6.8,0,0,0-4.111-1.227,11.1,11.1,0,0,0-5.269,1.339,4.729,4.729,0,0,0-1.456,1.17,2.134,2.134,0,0,0-.536,1.337,1.383,1.383,0,0,0,.431,1.06,1.568,1.568,0,0,0,1.115.4,3.362,3.362,0,0,0,2.592-1.77,4.652,4.652,0,0,1,.815-.919,1.192,1.192,0,0,1,.746-.251,1.038,1.038,0,0,1,1,.661,5.442,5.442,0,0,1,.307,2.139c0,.391-.07.64-.21.746a1.981,1.981,0,0,1-1.017.161,7.645,7.645,0,0,0-5.463,1.769,3.449,3.449,0,0,0-1.172,2.564,3.816,3.816,0,0,0,1.6,3.081,4.735,4.735,0,0,0,2.342.747v-1.585a2.629,2.629,0,1,1,1.373,0v1.563a5.168,5.168,0,0,0,2.4-.962,1.383,1.383,0,0,1,.739-.348.776.776,0,0,1,.543.363,2.878,2.878,0,0,0,2.314,1,4.254,4.254,0,0,0,2.187-.614,4.454,4.454,0,0,0,1.387-1.212,2.365,2.365,0,0,0,.536-1.394A.509.509,0,0,0,440.211,163.438Z"
        transform="translate(-365.996 -147.521)"
        fill="#444c4c"
      />
      <g
        id="Group_2605"
        data-name="Group 2605"
        transform="translate(46.681 21.66)"
      >
        <path
          id="Path_125"
          data-name="Path 125"
          d="M408.755,192.01H404.8a2.7,2.7,0,0,0,.278,1.215,1.859,1.859,0,0,0,1.707,1.058,2.467,2.467,0,0,0,.663-.084,2.169,2.169,0,0,0,.581-.267,4.374,4.374,0,0,0,.518-.389c.158-.138.363-.325.615-.563a.662.662,0,0,1,.445-.133.731.731,0,0,1,.5.171.608.608,0,0,1,.192.481,1.318,1.318,0,0,1-.214.641,2.558,2.558,0,0,1-.649.7,3.684,3.684,0,0,1-1.089.56,4.692,4.692,0,0,1-1.507.222,4.032,4.032,0,0,1-3.029-1.111,4.138,4.138,0,0,1-1.082-3.016,5.051,5.051,0,0,1,.266-1.662,3.68,3.68,0,0,1,.778-1.316,3.384,3.384,0,0,1,1.26-.84,4.512,4.512,0,0,1,1.659-.292,3.936,3.936,0,0,1,2.033.5,3.291,3.291,0,0,1,1.27,1.292,3.4,3.4,0,0,1,.422,1.615q0,.764-.436.989A2.734,2.734,0,0,1,408.755,192.01Zm-3.956-1.149h3.667a2.462,2.462,0,0,0-.559-1.552,1.676,1.676,0,0,0-1.278-.514,1.621,1.621,0,0,0-1.241.522A2.608,2.608,0,0,0,404.8,190.861Z"
          transform="translate(-402.733 -184.394)"
          fill="#231f20"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M427.773,189.808a3.2,3.2,0,0,1-.508,1.77,3.371,3.371,0,0,1-1.485,1.23,5.566,5.566,0,0,1-2.318.445,5.214,5.214,0,0,1-2.652-.608,3.529,3.529,0,0,1-1.2-1.167,2.645,2.645,0,0,1-.464-1.418.962.962,0,0,1,.985-.97.841.841,0,0,1,.589.221,1.755,1.755,0,0,1,.411.66,4.4,4.4,0,0,0,.449.867,1.812,1.812,0,0,0,.677.574,2.5,2.5,0,0,0,1.149.227,2.59,2.59,0,0,0,1.589-.456,1.37,1.37,0,0,0,.611-1.137,1.205,1.205,0,0,0-.329-.878,2.125,2.125,0,0,0-.852-.514,14.244,14.244,0,0,0-1.4-.378,10.264,10.264,0,0,1-1.96-.642,3.155,3.155,0,0,1-1.252-1,2.592,2.592,0,0,1-.463-1.574,2.708,2.708,0,0,1,.489-1.593,3.087,3.087,0,0,1,1.415-1.07,5.792,5.792,0,0,1,2.178-.374,5.4,5.4,0,0,1,1.73.248,3.67,3.67,0,0,1,1.211.66,2.762,2.762,0,0,1,.7.864,2,2,0,0,1,.222.882,1.041,1.041,0,0,1-.278.707.882.882,0,0,1-.692.315.8.8,0,0,1-.574-.189,2.542,2.542,0,0,1-.426-.619,2.789,2.789,0,0,0-.711-.959,2.076,2.076,0,0,0-1.333-.344,2.319,2.319,0,0,0-1.374.373,1.088,1.088,0,0,0-.522.9.913.913,0,0,0,.177.564,1.517,1.517,0,0,0,.489.407,3.267,3.267,0,0,0,.63.267q.318.1,1.051.282a16.467,16.467,0,0,1,1.663.473,5.1,5.1,0,0,1,1.267.631,2.59,2.59,0,0,1,.815.937A3,3,0,0,1,427.773,189.808Z"
          transform="translate(-409.986 -182.023)"
          fill="#231f20"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M439.534,188.547v.244a4,4,0,0,1,1.186-1.033,2.9,2.9,0,0,1,1.392-.33,3.225,3.225,0,0,1,1.719.482,3.319,3.319,0,0,1,1.242,1.408,4.919,4.919,0,0,1,.456,2.192,5.352,5.352,0,0,1-.263,1.716,3.859,3.859,0,0,1-.722,1.311,3.154,3.154,0,0,1-1.085.815,3.213,3.213,0,0,1-1.345.285,2.814,2.814,0,0,1-1.455-.349,4.167,4.167,0,0,1-1.123-1.022V197.3q0,1.334-.97,1.334a.779.779,0,0,1-.756-.344,2.173,2.173,0,0,1-.185-1v-8.726a1.277,1.277,0,0,1,.252-.863.873.873,0,0,1,.689-.285.914.914,0,0,1,.7.292A1.2,1.2,0,0,1,439.534,188.547Zm3.963,2.941a3.511,3.511,0,0,0-.244-1.374,2.02,2.02,0,0,0-.678-.882,1.624,1.624,0,0,0-.96-.307,1.8,1.8,0,0,0-1.411.66,2.881,2.881,0,0,0-.574,1.941,2.8,2.8,0,0,0,.571,1.877,1.781,1.781,0,0,0,1.414.671,1.627,1.627,0,0,0,.934-.292,2.012,2.012,0,0,0,.689-.878A3.486,3.486,0,0,0,443.5,191.487Z"
          transform="translate(-418.155 -184.405)"
          fill="#231f20"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M461.886,191.525a4.8,4.8,0,0,1-.282,1.666,3.692,3.692,0,0,1-.815,1.311,3.6,3.6,0,0,1-1.274.841,4.492,4.492,0,0,1-1.667.293,4.4,4.4,0,0,1-1.653-.3,3.656,3.656,0,0,1-2.085-2.152,5.156,5.156,0,0,1,0-3.344,3.665,3.665,0,0,1,.808-1.3,3.588,3.588,0,0,1,1.273-.833,4.826,4.826,0,0,1,3.319,0,3.586,3.586,0,0,1,2.093,2.149A4.792,4.792,0,0,1,461.886,191.525Zm-2.03,0a3.04,3.04,0,0,0-.544-1.927,1.771,1.771,0,0,0-1.463-.689,1.816,1.816,0,0,0-1.044.307,1.948,1.948,0,0,0-.7.908,4.122,4.122,0,0,0-.005,2.785,1.994,1.994,0,0,0,.689.908,1.8,1.8,0,0,0,1.056.315,1.766,1.766,0,0,0,1.463-.692A3.026,3.026,0,0,0,459.856,191.525Z"
          transform="translate(-425.321 -184.405)"
          fill="#231f20"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M473.114,192.718v1.688a1.305,1.305,0,0,1-.289.923.966.966,0,0,1-.734.307.927.927,0,0,1-.718-.311,1.33,1.33,0,0,1-.282-.92v-5.629q0-1.363.985-1.363a.825.825,0,0,1,.726.318,1.765,1.765,0,0,1,.244.941,3.549,3.549,0,0,1,.745-.941,1.54,1.54,0,0,1,1.018-.318,2.615,2.615,0,0,1,1.236.318.953.953,0,0,1,.6.845.8.8,0,0,1-.255.611.792.792,0,0,1-.552.24,2.508,2.508,0,0,1-.537-.137,2.513,2.513,0,0,0-.753-.137,1.1,1.1,0,0,0-.725.233,1.492,1.492,0,0,0-.437.693,4.842,4.842,0,0,0-.215,1.092Q473.113,191.805,473.114,192.718Z"
          transform="translate(-432.949 -184.405)"
          fill="#231f20"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M482.481,185.414h.222V184.2c0-.326.008-.581.026-.767a1.123,1.123,0,0,1,.145-.478.915.915,0,0,1,.34-.337.969.969,0,0,1,.5-.129,1,1,0,0,1,.7.288.856.856,0,0,1,.263.47,4.3,4.3,0,0,1,.055.789v1.378h.741a.947.947,0,0,1,.655.2.672.672,0,0,1,.227.522.586.586,0,0,1-.323.571,2.1,2.1,0,0,1-.922.164h-.378v3.718a5.744,5.744,0,0,0,.034.729.741.741,0,0,0,.177.416.611.611,0,0,0,.47.159,2.58,2.58,0,0,0,.482-.062,2.512,2.512,0,0,1,.474-.063.609.609,0,0,1,.44.2.658.658,0,0,1,.2.485.812.812,0,0,1-.533.748,3.6,3.6,0,0,1-1.534.259,2.647,2.647,0,0,1-1.436-.319,1.484,1.484,0,0,1-.642-.882,6,6,0,0,1-.151-1.5v-3.881h-.267a.966.966,0,0,1-.667-.208.681.681,0,0,1-.229-.527.655.655,0,0,1,.241-.522A1.058,1.058,0,0,1,482.481,185.414Z"
          transform="translate(-437.568 -182.229)"
          fill="#231f20"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M498.829,192.991a2.345,2.345,0,0,1-1.611,2.337,5.4,5.4,0,0,1-1.938.307,4.723,4.723,0,0,1-1.866-.333,2.72,2.72,0,0,1-1.148-.834,1.678,1.678,0,0,1-.371-1,.78.78,0,0,1,.238-.571.812.812,0,0,1,.6-.237.7.7,0,0,1,.489.155,1.72,1.72,0,0,1,.325.437,2.218,2.218,0,0,0,.744.807,2.243,2.243,0,0,0,1.182.267,1.7,1.7,0,0,0,1-.271.757.757,0,0,0,.389-.618.842.842,0,0,0-.4-.778,4.975,4.975,0,0,0-1.329-.467,10.994,10.994,0,0,1-1.7-.544,2.8,2.8,0,0,1-1.049-.752,1.725,1.725,0,0,1-.392-1.148,2.024,2.024,0,0,1,.363-1.149,2.485,2.485,0,0,1,1.071-.863,4.1,4.1,0,0,1,1.707-.322,5.576,5.576,0,0,1,1.411.164,3.5,3.5,0,0,1,1.044.436,2.146,2.146,0,0,1,.638.608,1.184,1.184,0,0,1,.218.652.754.754,0,0,1-.233.57.927.927,0,0,1-.663.222.812.812,0,0,1-.529-.178,3.48,3.48,0,0,1-.5-.533,1.818,1.818,0,0,0-.541-.474,1.7,1.7,0,0,0-.844-.178,1.653,1.653,0,0,0-.911.233.681.681,0,0,0-.363.581.629.629,0,0,0,.267.523,2.205,2.205,0,0,0,.719.337q.451.133,1.244.325a6.636,6.636,0,0,1,1.538.549,2.549,2.549,0,0,1,.9.751A1.671,1.671,0,0,1,498.829,192.991Z"
          transform="translate(-442.145 -184.405)"
          fill="#231f20"
        />
      </g>
      <ellipse
        id="Ellipse_19"
        data-name="Ellipse 19"
        cx="6.581"
        cy="6.581"
        rx="6.581"
        ry="6.581"
        transform="translate(30.499 22.883)"
        fill="#ea6e30"
      />
      <g
        id="Group_2606"
        data-name="Group 2606"
        transform="translate(33.379 25.763)"
      >
        <path
          id="Path_132"
          data-name="Path 132"
          d="M385.2,196.779a.419.419,0,0,1-.418-.418V189.8a.419.419,0,0,1,.837,0v6.565A.419.419,0,0,1,385.2,196.779Z"
          transform="translate(-381.494 -189.377)"
          fill="#fff"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M385.878,196.1h-6.565a.418.418,0,1,1,0-.837h6.565a.418.418,0,1,1,0,.837Z"
          transform="translate(-378.894 -191.977)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_134"
        data-name="Path 134"
        d="M491.714,156.286a6.02,6.02,0,0,0-1.505-1.785,6.912,6.912,0,0,0-4.5-1.5,7.841,7.841,0,0,0-3.08.6,7.476,7.476,0,0,0-3.122,2.411,6.775,6.775,0,0,0-1.421,4.209,7.067,7.067,0,0,0,.822,3.317,6.974,6.974,0,0,0,1.589,2.02,7.259,7.259,0,0,0,4.905,1.7,8.307,8.307,0,0,0,5.115-1.659,2.534,2.534,0,0,0,1.254-1.937,1.481,1.481,0,0,0-.348-.92.948.948,0,0,0-.711-.446,1.484,1.484,0,0,0-.753.334,4.869,4.869,0,0,1-1.344.634,4.953,4.953,0,0,1-1.484.257,3.278,3.278,0,0,1-2.112-.8,2.239,2.239,0,0,1-.983-1.707c0-.251.144-.377.432-.377h.874v-1.368a2.629,2.629,0,1,1,1.373,0v1.368h4.3a1.127,1.127,0,0,0,.975-.578,2.677,2.677,0,0,0,.376-1.484A4.664,4.664,0,0,0,491.714,156.286Z"
        transform="translate(-389.36 -147.533)"
        fill="#444c4c"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "IconLogoHeaderMobile"
};
</script>