<template>
  <b-row class="content-loading-banner" no-gutters align-v="stretch">
    <b-col lg="9">
      <div ref="outer-thumbs" class="outer-thumbs">
        <div class="carousel"><b-skeleton-img></b-skeleton-img></div>
        <div ref="thumbs-slide" class="thumbs d-flex ">
          <b-skeleton-img
            v-for="(thumb, index) in 3"
            :key="index"
            no-aspect
          ></b-skeleton-img>
        </div>
      </div>
    </b-col>
    <b-col lg="3">
        <div class="events h-100 d-flex flex-column">
            <div class="box-event d-flex" v-for="(event, index) in 3" :key="index">
              <b-skeleton-img no-aspect></b-skeleton-img>
              <div class="title">
                <h2><b-skeleton animation width="7rem"></b-skeleton></h2>
                <h6><b-skeleton animation height="10px" width="4rem"></b-skeleton></h6>
              </div>
            </div>
          </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SectionBannerContent",
};
</script>
