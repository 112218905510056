import api from './api'

const version = ''
const namespace = '/player'
const resource = `${namespace}`

export default {
    getPlayerList(params) {
        return api().get(`${resource}/all`, { params })
    },
    getPlayerDetail(playerId) {
        return api().get(`${resource}/all/${playerId}`)
    },
}