<template>
  <header class="header header-authen">
    <b-container class="pl-xl-0 pr-xl-0">
      <b-navbar toggleable="lg" class="px-0">
        <b-navbar-brand
          class="mr-2 pl-xl-4 pr-xl-5 mr-xl-5"
          :title="$t('site_map.menu.esports')"
          to="/"
          ><esports-logo-header-authen /></b-navbar-brand>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "HeaderAuthen",
};
</script>