import api from './api'

const version = ''
const namespace = '/point-exchange'
const resource = `${namespace}`

export default {
    exchangePointCard(params) {
        return api().post(`${resource}`, params);
    },
    getCardHistory(params) {
        return api().get(`/card/all`, { params });
    },
    getPointHistory(params) {
        return api().get(`${resource}/user/history`, { params });
    }
}