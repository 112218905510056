import api from '../api'

const version = ''
const namespace = '/system-param'
const resource = `${namespace}`

export default {
    getParamsSysAll(params) {
        return api().get(`${resource}/admin/all`, { params })
    },
    getDetailParamSys(payload) {
        return api().get(`${resource}/all/${payload}`)
    },
    updateParamSys(params) {
        return api().put(`${resource}`, params)
    }
}