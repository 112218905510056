<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="63.074"
    height="61.967"
    viewBox="0 0 63.074 61.967"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.924"
        y1="0.222"
        x2="0.088"
        y2="0.834"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#8212bf" />
        <stop offset="0.552" stop-color="#d17a67" />
        <stop offset="0.9" stop-color="#ffb12f" />
        <stop offset="1" stop-color="#ffbe00" />
      </linearGradient>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2428"
          data-name="Rectangle 2428"
          width="29.51"
          height="29.619"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="Component_9_4" data-name="Component 9 – 4" transform="translate(0)">
      <ellipse
        id="Ellipse_17"
        data-name="Ellipse 17"
        cx="31.537"
        cy="30.984"
        rx="31.537"
        ry="30.984"
        transform="translate(0 0)"
        fill="url(#linear-gradient)"
      />
      <g
        id="Group_2599"
        data-name="Group 2599"
        transform="translate(16.222 15.309)"
      >
        <g
          id="Group_2598"
          data-name="Group 2598"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_100"
            data-name="Path 100"
            d="M42.617,30.527c3.94,0,4.406.015,5.962.086a8.14,8.14,0,0,1,2.74.51,4.9,4.9,0,0,1,2.8,2.811,8.223,8.223,0,0,1,.508,2.75c.071,1.562.086,2.03.086,5.984s-.015,4.423-.086,5.984a8.223,8.223,0,0,1-.508,2.75,4.9,4.9,0,0,1-2.8,2.811,8.136,8.136,0,0,1-2.74.51c-1.556.071-2.022.086-5.962.086s-4.407-.015-5.962-.086a8.136,8.136,0,0,1-2.74-.51,4.895,4.895,0,0,1-2.8-2.811,8.221,8.221,0,0,1-.508-2.75c-.071-1.562-.086-2.03-.086-5.984s.015-4.423.086-5.984a8.221,8.221,0,0,1,.508-2.75,4.9,4.9,0,0,1,2.8-2.811,8.14,8.14,0,0,1,2.74-.51c1.556-.071,2.023-.086,5.962-.086m0-2.668c-4.007,0-4.51.017-6.084.089a10.8,10.8,0,0,0-3.582.688,7.559,7.559,0,0,0-4.315,4.331,10.9,10.9,0,0,0-.686,3.595c-.072,1.58-.089,2.084-.089,6.106s.017,4.526.089,6.106a10.9,10.9,0,0,0,.686,3.595A7.559,7.559,0,0,0,32.952,56.7a10.8,10.8,0,0,0,3.582.688c1.574.072,2.076.089,6.084.089s4.51-.017,6.084-.089a10.8,10.8,0,0,0,3.582-.688A7.559,7.559,0,0,0,56.6,52.369a10.9,10.9,0,0,0,.686-3.595c.072-1.58.089-2.084.089-6.106s-.017-4.526-.089-6.106a10.9,10.9,0,0,0-.686-3.595,7.559,7.559,0,0,0-4.315-4.331,10.8,10.8,0,0,0-3.582-.688c-1.574-.072-2.076-.089-6.084-.089"
            transform="translate(-27.862 -27.859)"
            fill="#fff"
          />
          <path
            id="Path_101"
            data-name="Path 101"
            d="M146.426,138.846a7.6,7.6,0,1,0,7.577,7.6,7.591,7.591,0,0,0-7.577-7.6m0,12.541a4.937,4.937,0,1,1,4.918-4.937,4.928,4.928,0,0,1-4.918,4.937"
            transform="translate(-131.671 -131.641)"
            fill="#fff"
          />
          <path
            id="Path_102"
            data-name="Path 102"
            d="M353.947,108.617a1.771,1.771,0,1,1-1.771-1.777,1.774,1.774,0,0,1,1.771,1.777"
            transform="translate(-329.545 -101.713)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCircleInstagram",
};
</script>