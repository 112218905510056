import api from '../api'

const version = ''
const namespace = '/event'
const resource = `${namespace}`

export default {
    upSertEvent(params) {
        let formData = new FormData();
        if (params.file) {
            formData.append('file', params.file);
        }
        formData.append('eventInfo', params.eventInfo);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        if (params.eventId) {
            return api().put(`${resource}`, formData, config)
        } else {
            return api().post(`${resource}`, formData, config)
        }

    },
    getEventsAll(params) {
        return api().get(`${resource}/admin/all`, { params })
    },
    getDetailEvent(payload) {
        return api().get(`${resource}/all/${payload}`)
    },
    deleteEvent(params) {
        return api().delete(`${resource}/delete`, {params: {eventId: params}})
    },
    voteWinnerByAdmin(params) {
        return api().post(`/point/event-winner-vote`, params)
    }
}