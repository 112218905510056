import { playerService } from '@/services'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    async getPlayerList({ commit }, payload) {
        try {
            const result = await playerService.getPlayerList(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getPlayerDetail({ commit }, payload) {
        try {
            const result = await playerService.getPlayerDetail(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}