<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.548"
    height="22.775"
    viewBox="0 0 27.548 22.775"
  >
    <path
      id="Path_185"
      data-name="Path 185"
      d="M63.938,115.643c10.4,0,16.081-8.761,16.081-16.358,0-.249,0-.5-.016-.743a11.617,11.617,0,0,0,2.82-2.977,11.136,11.136,0,0,1-3.246.905,5.753,5.753,0,0,0,2.485-3.18,11.213,11.213,0,0,1-3.589,1.4,5.6,5.6,0,0,0-4.126-1.816,5.7,5.7,0,0,0-5.653,5.749,5.829,5.829,0,0,0,.147,1.311,15.958,15.958,0,0,1-11.65-6.007,5.82,5.82,0,0,0,1.75,7.675,5.537,5.537,0,0,1-2.56-.719c0,.024,0,.048,0,.073a5.728,5.728,0,0,0,4.534,5.637,5.55,5.55,0,0,1-2.552.1,5.671,5.671,0,0,0,5.28,3.993,11.221,11.221,0,0,1-7.02,2.461,11.335,11.335,0,0,1-1.348-.08,15.8,15.8,0,0,0,8.664,2.582"
      transform="translate(-55.274 -92.868)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTwitter",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>