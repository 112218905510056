<template>
  <b-col md="8" lg="6" xl="4">
    <b-card-group class="align-items-center h-100">
      <b-card no-body class="p-4 border-0 rounded-0 card-400">
        <b-card-body class="p-0">
          <h3 class="title pb-md-3 text-center">
            {{ $t("general_inputs.login") }}
          </h3>

          <esports-line-text text="SNSアカウントでログイン" />
          <SignupSocialNetwork
            @resetModal="resetModal"
            @activeComponent="activeComponent"
            :isModal="isModal"
          />
          <esports-line-text text="メールアドレスでログイン" />

          <b-form novalidate @submit.prevent="onSubmitLogin" class="pt-4 form-inputs">
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <b-input-group>
              <b-form-input
                type="email"
                :placeholder="$t('general_inputs.email')"
                v-model="formLogin.email"
                autocomplete="off"
              />
            </b-input-group>
            <b-input-group>
              <b-form-input
                class="password"
                v-model="formLogin.password"
                :placeholder="$t('general_inputs.password')"
                :type="type"
                autocomplete="off"
              />
            </b-input-group>
            <b-button
              class="primary px-4"
              block
              :disabled="isSubmitted"
              type="submit"
              @click="onSubmitLogin"
              >{{ $t("general_inputs.btn_login") }}
              <esports-loading-button
                v-if="isSubmitted"
                width="30px"
                height="30px"
                outerBorder="#333"
                innerBorder="#eee"
            /></b-button>
            <div class="text-right pt-1 pr-2 mb-4 pb-2">
              <router-link class="anchor" to="/forgot-password">{{
                $t("general_inputs.txt_forgot_password")
              }}</router-link>
            </div>
          </b-form>
        </b-card-body>
        <b-card-footer class="pt-4 px-0" v-if="!isModal">
          <div class="d-flex justify-content-center align-items-center">
            <span class="pr-3"
              >{{ $t("general_inputs.txt_account") }} &nbsp;</span
            >
            <b-link
              class="btn btn-outline-primary px-xl-5 rounded-pill"
              to="/register"
              >{{ $t("general_inputs.register") }}</b-link
            >
          </div>
        </b-card-footer>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { dismissCount } from "@/mixins";
import SignupSocialNetwork from "@/components/Shared/SignupSocialNetwork";
import firebase from '@/utils/firebase'

export default {
  name: "LoginForm",
  components: { SignupSocialNetwork },
  data() {
    return {
      type: "password",
      isSubmitted: false,
      msgErrors: [],
      dismissSecs: 8,
      formLogin: {
        email: "",
        password: "",
        loginPlatform: 1,
        deviceId: null
      },
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mounted() {
    this.notifyEsport();
  },
  mixins: [dismissCount],
  methods: {
    async onSubmitLogin() {
      this.isSubmitted = true;
      const result = await this.$store
        .dispatch("auth/login", this.formLogin)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        if (result.roleId !== 1) {
          const dataUser = await this.$store.dispatch("user/getMyPage");
          this.$store.commit("auth/updateUserProfile", dataUser);
        }

        if (result.roleId === 1) {
          this.$store.commit("auth/clearUser");
          this.msgErrors = ["アクセスが拒否されました"];
          return;
        }

        if (this.isModal) {
          this.$emit("resetModal", false);
          return;
        }

        this.$router.push({ path: "/home" });
      }
    },
    getTokenFirebase() {
      firebase.messaging()
        .getToken()
        .then((currentToken) => {
          this.formLogin.deviceId = currentToken;
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
    notifyEsport() {
      if (!("Notification" in window)) {
        //alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        this.getTokenFirebase();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            this.getTokenFirebase();
          }
        });
      } else {
        //alert("No permission to send notification");
      }
    },
    activeComponent(payload) {
      this.$emit("activeComponent", {
        user: payload.user,
        name: payload.name,
      });
    },
    resetModal(payload) {
      this.$emit("resetModal", payload);
    },
  },
};
</script>