import { homeService } from '@/services'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    async getEventTop({ commit }, payload) {
        try {
            const result = await homeService.getEventTop(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getEventMostVisited({ commit }, payload) {
        try {
            const result = await homeService.getEventMostVisited(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getEventFinished({ commit }, payload) {
        try {
            const result = await homeService.getEventFinished(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getGameTop({ commit }, payload) {
        try {
            const result = await homeService.getGameTop(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async addFirstAccess({ commit }) {
        try {
            const result = await homeService.addFirstAccess();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}