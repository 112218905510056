import api from './api'

const version = ''
const namespace = '/auth'
const resource = `${namespace}`

export default {
    verifyEmail(params) {
        return api().post(`${resource}/verify-email`, params)
    },
    checkOtp(params) {
        return api().post(`${resource}/check-otp`, params);
    },
    resetOtp(params) {
        return api().post(`${resource}/reset-otp`, params);
    },
    resetPassOtp(params) {
        return api().post(`${resource}/reset-pass-otp`, params);
    },
    signup(params) {
        return api().post(`${resource}/signup`, params);
    },
    login(params) {
        return api().post(`${namespace}/login`, params);
    },
    forgotPassword(params) {
        return api().post(`${namespace}/forgot-password`, params);
    },
    updatePassword(params) {
        return api().post(`${namespace}/reset-password`, params);
    },
    loginSocialNetwork(params) {
        return api().post(`${namespace}/login-oauth2`, params);
    },
    register(params) {
        return api().post(`/user/register`, params);
    },
    logout(params) {
        return api().post(`/user/logout`, params);
    },
    contactAdmin(params) {
        return api().post(`/contact`, params);
    }
}