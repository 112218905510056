import storage from '@/utils/storage'
import store from '@/store'

const checkRoleUser = () => {
    let user;
    try {
        user = JSON.parse(storage.getEncrypt('user'));
    } catch (error) {
        storage.remove('user');
        storage.remove('access_token');
        storage.remove('sdkTwitter');
        storage.remove('sdkFacebook');
    }


    if (user && user?.roleId !== 1) {
        return {
            path: '/my-page',
            component: {
                render(c) {
                    return c('router-view');
                }
            },
            children: [
                {
                    path: '',
                    name: 'my-page',
                    component: () => import('@/views/MyPage/Index')
                },
            ]
        }
    }

    return { path: '/home', redirect: { path: '/home' } };
}

const userRoute = [
    {
        path: '/',
        component: () => import('@/containers/UserTemplate'),
        children: [
            {
                path: '',
                name: 'user-home',
                component: () => import('@/views/Home/Index')
            },
            {
                path: '/my-page',
                name: 'my-page',
                beforeEnter(to, from, next) {
                    if (store?.state?.auth?.user.roleId !== 1) {
                        return next();
                    }

                    return next({ path: '/home'});         
                },
                component: () => import('@/views/MyPage/Index')
            },
        ]
    },
]

export default userRoute;