<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="224.219"
    height="78.983"
    viewBox="0 0 224.219 78.983"
  >
    <defs>
      <filter
        id="Rectangle_2420"
        x="0"
        y="0"
        width="224.219"
        height="78.983"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="6" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.2" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient
        id="linear-gradient"
        x1="0.068"
        y1="0.33"
        x2="1.017"
        y2="0.698"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="0.2" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
    </defs>
    <g id="Group_2590" data-name="Group 2590" transform="translate(9 3)">
      <g transform="matrix(1, 0, 0, 1, -9, -3)" filter="url(#Rectangle_2420)">
        <g
          id="Rectangle_2420-2"
          data-name="Rectangle 2420"
          transform="translate(9 3)"
          stroke="#f8f1f0"
          stroke-width="2"
        >
          <rect width="206.219" height="60.983" rx="11" stroke="none" />
          <rect
            x="1"
            y="1"
            width="204.219"
            height="58.983"
            rx="10"
            fill="none"
          />
        </g>
      </g>
      <text
        id="App_Store"
        data-name="App Store"
        transform="translate(61.786 28.233)"
        fill="#fff"
        font-size="19"
        font-family="SegoeUI, Segoe UI"
        letter-spacing="-0.015em"
      >
        <tspan x="0" y="0">App Store</tspan>
      </text>
      <text
        id="からダウンロード"
        transform="translate(62.588 47.108)"
        fill="#fff"
        font-size="12"
        font-family="YuGothicUI-Regular, Yu Gothic UI"
        letter-spacing="-0.015em"
      >
        <tspan x="0" y="0">からダウンロード</tspan>
      </text>
      <g
        id="Group_2589"
        data-name="Group 2589"
        transform="translate(16.558 8.913)"
      >
        <path
          id="Path_78"
          data-name="Path 78"
          d="M12.784,9.409a.711.711,0,0,0,.163-.017A8.639,8.639,0,0,0,19.84.777a.786.786,0,0,0-.949-.759A8.639,8.639,0,0,0,12,8.633a.784.784,0,0,0,.784.776Z"
          transform="translate(3.678 0)"
          fill="#ddd"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M32.84,22.511a6.743,6.743,0,0,1-4.186-6.319,6.989,6.989,0,0,1,3.465-6.039.783.783,0,0,0,.18-1.2A9.353,9.353,0,0,0,25.518,6a9.579,9.579,0,0,0-3.674.815,8.742,8.742,0,0,1-3.382.754,8.742,8.742,0,0,1-3.382-.754A9.609,9.609,0,0,0,11.407,6C10.445,6,2,6.307,2,16.975,2,25.7,7.952,34.222,11.407,34.222a10.383,10.383,0,0,0,4.406-.931,6.435,6.435,0,0,1,2.65-.635,6.435,6.435,0,0,1,2.65.635,10.407,10.407,0,0,0,4.406.931c2.808,0,6.115-5.53,7.8-10.732A.783.783,0,0,0,32.84,22.511Z"
          transform="translate(-2 3.407)"
          fill="#ddd"
        />
        <path
          id="Path_81"
          data-name="Path 81"
          d="M18.463,9.409a.711.711,0,0,0,.163-.017A8.639,8.639,0,0,0,25.518.777.786.786,0,0,0,24.57.019a8.639,8.639,0,0,0-6.891,8.614.784.784,0,0,0,.784.776ZM32.84,25.919A6.743,6.743,0,0,1,28.654,19.6a6.989,6.989,0,0,1,3.465-6.039.783.783,0,0,0,.18-1.2,9.353,9.353,0,0,0-6.781-2.954,9.579,9.579,0,0,0-3.674.815,8.742,8.742,0,0,1-3.382.754,8.742,8.742,0,0,1-3.382-.754,9.609,9.609,0,0,0-3.674-.815C10.445,9.407,2,9.714,2,20.382c0,8.725,5.952,17.247,9.407,17.247a10.383,10.383,0,0,0,4.406-.931,6.435,6.435,0,0,1,2.65-.635,6.435,6.435,0,0,1,2.65.635,10.383,10.383,0,0,0,4.406.931c2.808,0,6.115-5.53,7.8-10.732A.783.783,0,0,0,32.84,25.919Z"
          transform="translate(-2 0)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "app-store"
};
</script>