import { adminCardService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async upSertCard({ commit }, payload) {
        try {
            const result = await adminCardService.upSertCard(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getCardsAll({ commit }, payload) {
        try {
            const result = await adminCardService.getCardsAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailCard({ commit }, payload) {
        
        try {
            const result = await adminCardService.getDetailCard(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async deleteCard({ commit }, payload) {
        try {
            const result = await adminCardService.deleteCard(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}