<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="214.929"
    height="84.205"
    viewBox="0 0 214.929 84.205"
  >
    <g
      id="Group_2645"
      data-name="Group 2645"
      transform="translate(-574.22 -3735.022)"
    >
      <g
        id="Group_2642"
        data-name="Group 2642"
        transform="translate(574.22 3735.022)"
      >
        <rect
          id="Rectangle_2513"
          data-name="Rectangle 2513"
          width="7.146"
          height="19.633"
          transform="translate(73.361 64.572)"
          fill="#fff"
        />
        <path
          id="Path_170"
          data-name="Path 170"
          d="M612.813,417.07l.406,10.2a4.109,4.109,0,0,0,.361,1.708,14.665,14.665,0,0,0,1.784,2.117,1.838,1.838,0,0,1,.434,1.3q0,1.911-2.288,2.666a29.982,29.982,0,0,1-8.175.753,16.083,16.083,0,0,1-5.649-.7,2.438,2.438,0,0,1-1.711-2.347,1.657,1.657,0,0,1,.174-.856,15.516,15.516,0,0,1,1.159-1.347q1.159-1.215,1.159-8.113a14.215,14.215,0,0,0-.927-5.912,3.374,3.374,0,0,0-5.45-.291,9.865,9.865,0,0,0-.723,4.524v5.737a8.526,8.526,0,0,0,.216,2.347,14.233,14.233,0,0,0,1.436,2.462,2.258,2.258,0,0,1,.317,1.19q0,1.709-2.375,2.52a24.8,24.8,0,0,1-7.418.812q-8.058,0-8.058-3.332a1.861,1.861,0,0,1,.247-1.058,5.227,5.227,0,0,1,1.173-1.028,2.431,2.431,0,0,0,.958-1.263,22.53,22.53,0,0,0,.375-3.492q.2-3.3.2-6.926a7.146,7.146,0,0,0-.305-2.621,4.775,4.775,0,0,0-1.753-1.521,2.179,2.179,0,0,1-.232-3.811,19.077,19.077,0,0,1,4.172-1.87q2.781-.983,5.364-1.667a16.9,16.9,0,0,1,3.534-.68,1.926,1.926,0,0,1,1.465.638,2.144,2.144,0,0,1,.593,1.507c0,.059-.01.3-.03.725a3.189,3.189,0,0,0-.028.406q0,1.391,1.159,1.389c.465,0,.955-.367,1.479-1.1a8.264,8.264,0,0,1,7.244-3.593,10.3,10.3,0,0,1,6.259,1.912,7.359,7.359,0,0,1,2.549,3.2A17.844,17.844,0,0,1,612.813,417.07Z"
          transform="translate(-493.996 -395.015)"
          fill="#fff"
        />
        <path
          id="Path_171"
          data-name="Path 171"
          d="M658.331,414.958v9.793a4.517,4.517,0,0,0,.854,2.843,2.654,2.654,0,0,0,2.187,1.1,6.6,6.6,0,0,0,2.985-1.044,1.685,1.685,0,0,1,.871-.261,1.6,1.6,0,0,1,1.274.84,3.148,3.148,0,0,1,.58,1.826,4.609,4.609,0,0,1-1.971,3.45,14.859,14.859,0,0,1-9.967,3.506,10.472,10.472,0,0,1-7.45-2.624,9.009,9.009,0,0,1-2.811-6.911v-13.39a.749.749,0,0,0-.984-.983h-2.985c-.56,0-.919-.093-1.072-.277a2.273,2.273,0,0,1-.233-1.288v-1.275a1.468,1.468,0,0,1,.667-1.276l13.271-8.347a2.605,2.605,0,0,1,1.218-.26h2.347a1.156,1.156,0,0,1,.971.333,2.428,2.428,0,0,1,.247,1.319v3.912a1.626,1.626,0,0,0,.274,1.129,1.813,1.813,0,0,0,1.174.261h5.362a1.8,1.8,0,0,1,1.39.391,2.907,2.907,0,0,1,.319,1.667V411.1a3.327,3.327,0,0,1-.406,1.926,1.743,1.743,0,0,1-1.476.535h-5.245a1.13,1.13,0,0,0-1.392,1.392Z"
          transform="translate(-483.988 -396.004)"
          fill="#fff"
        />
        <path
          id="Path_172"
          data-name="Path 172"
          d="M548.336,405.71a16.327,16.327,0,0,0-8.637-7.592,29.555,29.555,0,0,0-10.488-1.509c-1.1,0-2.019.02-2.755.059q-5.214.261-9.359.26-1.1,0-3.534-.086c-.737-.02-1.373-.029-1.913-.029a7.719,7.719,0,0,0-3.839.8,2.46,2.46,0,0,0-1.378,2.218,2.651,2.651,0,0,0,2,2.579,2.853,2.853,0,0,1,1.767,1.305,8.979,8.979,0,0,1,.378,3.245q0,6.2-.187,12.491t-.42,8.46a7.1,7.1,0,0,1-.639,2.537,2.443,2.443,0,0,1-1.565.941,3.854,3.854,0,0,0-1.753.913,2.195,2.195,0,0,0-.535,1.58,2.79,2.79,0,0,0,1.215,2.346,5.185,5.185,0,0,0,3.159.9c.367,0,1.026-.039,1.972-.117q2.259-.172,4.085-.174H518q2.666,0,6.172.118l2.594.074V423.172a5.491,5.491,0,1,1,2.868,0v13.946l1.057.034a23.3,23.3,0,0,0,7.418-1.159,17.723,17.723,0,0,0,9.274-6.839,20.054,20.054,0,0,0,2.579-5.693,23.388,23.388,0,0,0,.957-6.564A22.893,22.893,0,0,0,548.336,405.71Z"
          transform="translate(-505.477 -396.608)"
          fill="#fff"
        />
        <path
          id="Path_173"
          data-name="Path 173"
          d="M578.656,414.258a14.774,14.774,0,0,0-4.61-5.13,16.005,16.005,0,0,0-9.3-2.753,19.209,19.209,0,0,0-12.956,4.579,14.033,14.033,0,0,0-5.1,11.188,12.891,12.891,0,0,0,4.172,9.967q4.29,3.945,11.217,3.944l.057,0V427.1a5.491,5.491,0,1,1,2.868,0v8.751a19.979,19.979,0,0,0,9.681-4q5.767-4.4,5.766-10.692A14.269,14.269,0,0,0,578.656,414.258Z"
          transform="translate(-498.874 -395.043)"
          fill="#fff"
        />
        <path
          id="Path_174"
          data-name="Path 174"
          d="M642.786,428.141a4.02,4.02,0,0,1-1.215-.319,1.986,1.986,0,0,1-.61-.812q-.635-1.331-.636-5.825l.056-4.318a12.744,12.744,0,0,0-.737-4.825,7.533,7.533,0,0,0-2.6-3.114,14.13,14.13,0,0,0-8.547-2.552,23.1,23.1,0,0,0-10.956,2.784,9.857,9.857,0,0,0-3.027,2.433,4.438,4.438,0,0,0-1.115,2.781,2.872,2.872,0,0,0,.9,2.2,3.258,3.258,0,0,0,2.318.84q2.81,0,5.391-3.679a9.674,9.674,0,0,1,1.7-1.913,2.487,2.487,0,0,1,1.551-.521,2.158,2.158,0,0,1,2.086,1.375,11.324,11.324,0,0,1,.638,4.447c0,.812-.145,1.331-.436,1.552a4.143,4.143,0,0,1-2.115.333q-7.565.03-11.358,3.68a7.168,7.168,0,0,0-2.436,5.332,7.937,7.937,0,0,0,3.332,6.4,9.843,9.843,0,0,0,4.871,1.552v-3.295a5.469,5.469,0,1,1,2.855,0v3.25a10.734,10.734,0,0,0,5-2,2.882,2.882,0,0,1,1.538-.723c.347,0,.722.249,1.128.754a5.987,5.987,0,0,0,4.811,2.086,8.843,8.843,0,0,0,4.548-1.277,9.235,9.235,0,0,0,2.885-2.52,4.912,4.912,0,0,0,1.114-2.9C643.713,428.642,643.405,428.236,642.786,428.141Z"
          transform="translate(-488.47 -395.043)"
          fill="#fff"
        />
        <g
          id="Group_2640"
          data-name="Group 2640"
          transform="translate(97.064 45.038)"
        >
          <path
            id="Path_175"
            data-name="Path 175"
            d="M601.66,450.4h-8.226a5.618,5.618,0,0,0,.578,2.526,3.866,3.866,0,0,0,3.55,2.2,5.127,5.127,0,0,0,1.38-.175,4.51,4.51,0,0,0,1.209-.555,9.336,9.336,0,0,0,1.078-.81c.328-.288.755-.676,1.279-1.171a1.375,1.375,0,0,1,.925-.277,1.52,1.52,0,0,1,1.048.355,1.265,1.265,0,0,1,.4,1,2.731,2.731,0,0,1-.447,1.333,5.3,5.3,0,0,1-1.348,1.463,7.678,7.678,0,0,1-2.265,1.164,9.767,9.767,0,0,1-3.134.462,8.379,8.379,0,0,1-6.3-2.31,8.6,8.6,0,0,1-2.25-6.271,10.47,10.47,0,0,1,.553-3.456,7.65,7.65,0,0,1,1.617-2.736,7.037,7.037,0,0,1,2.62-1.747,9.385,9.385,0,0,1,3.45-.608,8.184,8.184,0,0,1,4.228,1.04,6.845,6.845,0,0,1,2.642,2.688,7.059,7.059,0,0,1,.877,3.356q0,1.589-.908,2.057A5.677,5.677,0,0,1,601.66,450.4Zm-8.226-2.389h7.625a5.12,5.12,0,0,0-1.163-3.228,3.482,3.482,0,0,0-2.657-1.07,3.37,3.37,0,0,0-2.581,1.086A5.423,5.423,0,0,0,593.434,448.014Z"
            transform="translate(-589.138 -434.568)"
            fill="#fff"
          />
          <path
            id="Path_176"
            data-name="Path 176"
            d="M623.492,451.615a6.644,6.644,0,0,1-1.055,3.681,7.016,7.016,0,0,1-3.089,2.557,11.572,11.572,0,0,1-4.821.925,10.854,10.854,0,0,1-5.515-1.262,7.359,7.359,0,0,1-2.5-2.427,5.5,5.5,0,0,1-.963-2.949,2,2,0,0,1,2.049-2.018,1.744,1.744,0,0,1,1.225.462,3.647,3.647,0,0,1,.854,1.371,9.269,9.269,0,0,0,.933,1.8,3.747,3.747,0,0,0,1.408,1.192,5.2,5.2,0,0,0,2.388.471,5.388,5.388,0,0,0,3.3-.948,2.848,2.848,0,0,0,1.271-2.363,2.506,2.506,0,0,0-.685-1.826,4.43,4.43,0,0,0-1.773-1.07,29.542,29.542,0,0,0-2.9-.785,21.325,21.325,0,0,1-4.075-1.334,6.562,6.562,0,0,1-2.6-2.079,5.39,5.39,0,0,1-.963-3.273,5.625,5.625,0,0,1,1.016-3.312,6.421,6.421,0,0,1,2.941-2.224,12.054,12.054,0,0,1,4.529-.779,11.233,11.233,0,0,1,3.6.515,7.64,7.64,0,0,1,2.518,1.371,5.723,5.723,0,0,1,1.463,1.8,4.137,4.137,0,0,1,.462,1.833,2.166,2.166,0,0,1-.578,1.47,1.836,1.836,0,0,1-1.44.654,1.655,1.655,0,0,1-1.193-.392,5.3,5.3,0,0,1-.888-1.286,5.787,5.787,0,0,0-1.478-2,4.325,4.325,0,0,0-2.772-.716,4.816,4.816,0,0,0-2.858.776,2.26,2.26,0,0,0-1.085,1.871,1.9,1.9,0,0,0,.368,1.172,3.148,3.148,0,0,0,1.016.847,6.8,6.8,0,0,0,1.311.555q.661.2,2.186.586a34.688,34.688,0,0,1,3.458.984,10.6,10.6,0,0,1,2.635,1.311,5.4,5.4,0,0,1,1.694,1.948A6.237,6.237,0,0,1,623.492,451.615Z"
            transform="translate(-586.509 -435.427)"
            fill="#fff"
          />
          <path
            id="Path_177"
            data-name="Path 177"
            d="M628,443.175v.508a8.288,8.288,0,0,1,2.464-2.149,6.032,6.032,0,0,1,2.9-.687,6.7,6.7,0,0,1,3.573,1,6.882,6.882,0,0,1,2.58,2.926,10.213,10.213,0,0,1,.949,4.558,11.135,11.135,0,0,1-.548,3.568,8.032,8.032,0,0,1-1.5,2.727,6.568,6.568,0,0,1-2.257,1.694,6.69,6.69,0,0,1-2.8.593,5.854,5.854,0,0,1-3.026-.725A8.677,8.677,0,0,1,628,455.066v6.316q0,2.772-2.016,2.772a1.619,1.619,0,0,1-1.572-.716,4.5,4.5,0,0,1-.385-2.088V443.206a2.65,2.65,0,0,1,.524-1.8,1.815,1.815,0,0,1,1.433-.593,1.9,1.9,0,0,1,1.456.609A2.489,2.489,0,0,1,628,443.175Zm8.241,6.114a7.3,7.3,0,0,0-.507-2.858,4.2,4.2,0,0,0-1.411-1.833,3.371,3.371,0,0,0-1.994-.639,3.745,3.745,0,0,0-2.934,1.371,5.984,5.984,0,0,0-1.194,4.036,5.837,5.837,0,0,0,1.186,3.9,3.706,3.706,0,0,0,2.942,1.395,3.38,3.38,0,0,0,1.941-.608,4.184,4.184,0,0,0,1.433-1.826A7.246,7.246,0,0,0,636.243,449.289Z"
            transform="translate(-583.548 -434.563)"
            fill="#fff"
          />
          <path
            id="Path_178"
            data-name="Path 178"
            d="M656.981,449.367a9.969,9.969,0,0,1-.585,3.464,7.67,7.67,0,0,1-1.694,2.727,7.51,7.51,0,0,1-2.65,1.75,9.364,9.364,0,0,1-3.467.608,9.108,9.108,0,0,1-3.435-.616,7.6,7.6,0,0,1-4.336-4.475,9.931,9.931,0,0,1-.577-3.457,10.053,10.053,0,0,1,.584-3.5,7.591,7.591,0,0,1,1.681-2.713,7.421,7.421,0,0,1,2.648-1.732,10.026,10.026,0,0,1,6.9.007A7.451,7.451,0,0,1,656.4,445.9,9.969,9.969,0,0,1,656.981,449.367Zm-4.221,0a6.322,6.322,0,0,0-1.131-4.005,3.681,3.681,0,0,0-3.043-1.433,3.768,3.768,0,0,0-2.171.639,4.036,4.036,0,0,0-1.448,1.887,8.561,8.561,0,0,0-.009,5.792,4.139,4.139,0,0,0,1.433,1.888,3.734,3.734,0,0,0,2.2.654,3.674,3.674,0,0,0,3.043-1.44A6.3,6.3,0,0,0,652.76,449.367Z"
            transform="translate(-580.951 -434.563)"
            fill="#fff"
          />
          <path
            id="Path_179"
            data-name="Path 179"
            d="M661.7,451.847v3.51a2.709,2.709,0,0,1-.6,1.919,2,2,0,0,1-1.526.639,1.929,1.929,0,0,1-1.493-.646,2.766,2.766,0,0,1-.586-1.912V443.651q0-2.835,2.049-2.834a1.714,1.714,0,0,1,1.511.663,3.659,3.659,0,0,1,.507,1.957,7.346,7.346,0,0,1,1.549-1.957,3.2,3.2,0,0,1,2.117-.663,5.426,5.426,0,0,1,2.572.663,1.979,1.979,0,0,1,1.247,1.757,1.676,1.676,0,0,1-.53,1.27,1.65,1.65,0,0,1-1.149.5,5.227,5.227,0,0,1-1.116-.284,5.217,5.217,0,0,0-1.564-.284,2.278,2.278,0,0,0-1.508.484,3.119,3.119,0,0,0-.911,1.441,10,10,0,0,0-.446,2.272Q661.7,449.95,661.7,451.847Z"
            transform="translate(-578.187 -434.563)"
            fill="#fff"
          />
          <path
            id="Path_180"
            data-name="Path 180"
            d="M669.9,441.976h.462V439.45c0-.679.016-1.209.053-1.594a2.333,2.333,0,0,1,.3-.994,1.905,1.905,0,0,1,.708-.7,2.011,2.011,0,0,1,1.033-.269,2.076,2.076,0,0,1,1.448.6,1.79,1.79,0,0,1,.548.978,8.973,8.973,0,0,1,.114,1.641v2.866h1.542a1.971,1.971,0,0,1,1.362.424,1.4,1.4,0,0,1,.471,1.086,1.217,1.217,0,0,1-.672,1.186,4.35,4.35,0,0,1-1.917.34h-.787v7.732a12.183,12.183,0,0,0,.071,1.516,1.549,1.549,0,0,0,.369.864,1.267,1.267,0,0,0,.978.329,5.317,5.317,0,0,0,1-.129,5.162,5.162,0,0,1,.986-.132,1.27,1.27,0,0,1,.917.408,1.368,1.368,0,0,1,.407,1.009,1.688,1.688,0,0,1-1.108,1.555A7.479,7.479,0,0,1,675,458.7a5.522,5.522,0,0,1-2.988-.661,3.089,3.089,0,0,1-1.333-1.834,12.459,12.459,0,0,1-.314-3.126v-8.072h-.556a2,2,0,0,1-1.386-.432,1.414,1.414,0,0,1-.477-1.094,1.358,1.358,0,0,1,.5-1.086A2.2,2.2,0,0,1,669.9,441.976Z"
            transform="translate(-576.513 -435.352)"
            fill="#fff"
          />
          <path
            id="Path_181"
            data-name="Path 181"
            d="M692.718,452.416a4.876,4.876,0,0,1-3.35,4.86,11.236,11.236,0,0,1-4.029.639,9.822,9.822,0,0,1-3.88-.693,5.661,5.661,0,0,1-2.389-1.733,3.5,3.5,0,0,1-.77-2.087,1.616,1.616,0,0,1,.494-1.187,1.689,1.689,0,0,1,1.247-.492,1.454,1.454,0,0,1,1.016.323,3.576,3.576,0,0,1,.677.91,4.613,4.613,0,0,0,1.548,1.679,4.662,4.662,0,0,0,2.456.556,3.539,3.539,0,0,0,2.072-.564,1.578,1.578,0,0,0,.809-1.285,1.753,1.753,0,0,0-.839-1.617,10.345,10.345,0,0,0-2.765-.971,22.857,22.857,0,0,1-3.534-1.131,5.81,5.81,0,0,1-2.181-1.564,3.589,3.589,0,0,1-.817-2.387,4.2,4.2,0,0,1,.757-2.389,5.177,5.177,0,0,1,2.225-1.795,8.522,8.522,0,0,1,3.55-.67,11.6,11.6,0,0,1,2.934.34,7.3,7.3,0,0,1,2.171.907,4.489,4.489,0,0,1,1.326,1.263,2.471,2.471,0,0,1,.454,1.356,1.576,1.576,0,0,1-.484,1.187,1.933,1.933,0,0,1-1.379.461,1.682,1.682,0,0,1-1.1-.37,7.2,7.2,0,0,1-1.039-1.108,3.8,3.8,0,0,0-1.125-.986,3.548,3.548,0,0,0-1.755-.37,3.435,3.435,0,0,0-1.893.485,1.414,1.414,0,0,0-.756,1.209,1.309,1.309,0,0,0,.556,1.087,4.568,4.568,0,0,0,1.493.7q.94.277,2.587.678a13.768,13.768,0,0,1,3.2,1.141,5.32,5.32,0,0,1,1.878,1.562A3.473,3.473,0,0,1,692.718,452.416Z"
            transform="translate(-574.854 -434.563)"
            fill="#fff"
          />
        </g>
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="13.683"
          cy="13.683"
          r="13.683"
          transform="translate(63.418 47.583)"
          fill="#fff"
        />
        <g
          id="Group_2641"
          data-name="Group 2641"
          transform="translate(69.405 53.57)"
        >
          <path
            id="Path_182"
            data-name="Path 182"
            d="M572.051,458.172a.87.87,0,0,1-.87-.87V443.651a.87.87,0,1,1,1.74,0V457.3A.87.87,0,0,1,572.051,458.172Z"
            transform="translate(-564.355 -442.781)"
            fill="#ea6e30"
          />
          <path
            id="Path_183"
            data-name="Path 183"
            d="M579.82,450.4H566.168a.87.87,0,1,1,0-1.74H579.82a.87.87,0,1,1,0,1.74Z"
            transform="translate(-565.298 -441.839)"
            fill="#ea6e30"
          />
        </g>
        <path
          id="Path_184"
          data-name="Path 184"
          d="M692.831,413.24a12.5,12.5,0,0,0-3.131-3.711,14.366,14.366,0,0,0-9.359-3.128,16.3,16.3,0,0,0-6.4,1.246,15.558,15.558,0,0,0-6.493,5.013,14.1,14.1,0,0,0-2.954,8.751,14.717,14.717,0,0,0,1.708,6.9,14.534,14.534,0,0,0,3.306,4.2,15.1,15.1,0,0,0,10.2,3.537,17.273,17.273,0,0,0,10.637-3.451q2.609-1.94,2.607-4.027a3.086,3.086,0,0,0-.723-1.913,1.972,1.972,0,0,0-1.478-.927,3.09,3.09,0,0,0-1.566.7,10.1,10.1,0,0,1-2.795,1.319,10.292,10.292,0,0,1-3.086.535,6.817,6.817,0,0,1-4.391-1.666,4.659,4.659,0,0,1-2.044-3.549c0-.521.3-.783.9-.783h1.817v-2.846a5.469,5.469,0,1,1,2.855,0v2.846h8.95a2.344,2.344,0,0,0,2.028-1.2,5.567,5.567,0,0,0,.781-3.086A9.7,9.7,0,0,0,692.831,413.24Z"
          transform="translate(-480.002 -395.039)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "LogoFooter"
};
</script>