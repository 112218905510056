<template>
  <div>
    <p class="text-center socials m-0">
      <span title="Apple">
        <vue-apple-login
          mode="logo-only"
          type="sign in"
          color="black"
          :border="true"
          :radius="50"
          width="100%"
          height="100%"
          logoSize="small"
          :logoPosition="0"
          :labelPosition="0"
          className="vue-apple-login"
          :onSuccess="callSuccessFromApple"
          :onFailure="callFailApple"
        >
        </vue-apple-login>
      </span>
      <span @click="onLoginTwitter" title="Twitter"
        ><esports-circle-twitter title="Twitter"
      /></span>
      <v-facebook-login-scope
        title="Facebook"
        @sdk-init="sdkLoaded"
        :app-id="face_id"
      >
        <span title="Facebook" @click="onLoginFacebook(scope)"
          ><esports-circle-facebook
        /></span>
      </v-facebook-login-scope>
      <GoogleLogin :params="params" :onSuccess="onSuccessGoogle">
        <span title="Google"><esports-circle-google /></span>
      </GoogleLogin>
    </p>
    <template v-if="msgErrors.length">
      <b-alert
        :show="dismissCountDown"
        dismissible
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChange"
        variant="danger"
      >
        <span class="d-block" v-for="(msg, index) in msgErrors" :key="index">{{
          msg
        }}</span>
      </b-alert>
    </template>
    <esports-loading-button
      class="overlay-content-page"
      width="100"
      height="100"
      v-if="isLoading"
    />
  </div>
</template>

<script>
import { dismissCount } from "@/mixins";
import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";
import GoogleLogin from "vue-google-login";
import hello from "hellojs/dist/hello.all.js";
import { OAUTH_PROXY } from "@/utils/constants";
import firebase from "@/utils/firebase";

export default {
  name: "SignupSocialNetwork",
  components: { VFacebookLoginScope, GoogleLogin },
  data() {
    return {
      FB: null,
      scope: null,
      msgErrors: [],
      face_id: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      },
      helloSocial: null,
      loginMethod: null,
      formRegister: {
        email: "",
        loginProvider: "",
        otpCode: "",
      },
      isLoading: false,
      deviceId: null,
      twitter: null,
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dismissCount],
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mounted() {
    this.helloSocial = hello.init(
      {
        twitter: process.env.VUE_APP_TWITTER_CLIENT_ID,
      },
      {
        oauth_proxy: OAUTH_PROXY,
        redirect_uri: `${process.env.VUE_APP_FRONT_END}/callback`,
      }
    );

    this.$store.commit("auth/setSdkTwitter", this.helloSocial);

    this.helloSocial.on("auth.login", async (response) => {
      // Get Profile
      let loginMethod = this.loginMethod;
      if (response && loginMethod) {

        this.twitter = { ...response };

        const accessToken = response.authResponse.access_token;
        await this.loginSocial({
          loginMethod,
          accessToken,
          loginPlatform: 1,
          deviceId: this.deviceId,
          twitterUsername: this.twitter.authResponse.screen_name
        });
      }
    });

    this.notifyEsport();
  },
  methods: {
    async getUserData(response) {
      if (response?.authResponse) {
        let loginMethod = this.loginMethod;
        let tokenId = response.authResponse.userID;
        let accessToken = response.authResponse.accessToken;
        await this.loginSocial({
          loginMethod,
          tokenId,
          accessToken,
          loginPlatform: 1,
          deviceId: this.deviceId,
        });
      }
    },
    async onLoginFacebook(scope) {
      this.loginMethod = 1;
      await this.scope.login().then((response) => {
        this.getUserData(response);
      });
    },
    async onSuccessGoogle(googleUser) {
      const result = await googleUser.reloadAuthResponse();
      let loginMethod = 2;
      let tokenId = result.id_token;
      let accessToken = result.access_token;
      await this.loginSocial({
        loginMethod,
        tokenId,
        accessToken,
        loginPlatform: 1,
        deviceId: this.deviceId,
      });
    },
    async loginSocial(params) {
      this.isLoading = true;
      const result = await this.$store
        .dispatch("auth/loginSocialNetwork", params)
        .catch((err) => {
          this.isLoading = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = err ? [err] : [];
          }
        });

      if(result) {
        if(params.loginMethod === 3) {
          this.$store.commit('auth/setTwitterAuth', this.twitter);
        }
      }

      if (result && result.status === 0) {
        this.formRegister.email = result.email;
        this.formRegister.loginProvider = params.loginMethod;
        this.formRegister.otpCode = result.otpCode;
        this.formRegister.username = result.username || '';

        if(params.loginMethod === 3) {
          this.formRegister.twitterUsername = this.twitter.authResponse.screen_name;
        }

        this.$emit("activeComponent", {
          user: this.formRegister,
          name: "RegisterEnterUserNameSocial",
        });
        return;
      }

      if (result) {
        this.isLoading = true;
        const dataUser = await this.$store.dispatch("user/getMyPage");
        this.$store.commit("auth/updateUserProfile", dataUser);

        if (this.isModal) {
          this.$emit("resetModal", false);
          return;
        }
        this.$router.push({ path: "/home" });
      }
    },
    getTokenFirebase() {
      firebase
        .messaging()
        .getToken()
        .then((currentToken) => {
          this.deviceId = currentToken;
          this.$store.commit("auth/setDeviceId", currentToken);
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
    notifyEsport() {
      if (!("Notification" in window)) {
        //alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        this.getTokenFirebase();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            this.getTokenFirebase();
          }
        });
      } else {
        //alert("No permission to send notification");
      }
    },
    onLoginTwitter() {
      this.loginMethod = 3;
      this.helloSocial("twitter").login();
    },
    onLoginInstagram() {
      this.loginMethod = 2;
      this.helloSocial("instagram").login();
    },
    sdkLoaded(payload) {
      this.FB = payload.FB;
      this.scope = payload.scope;
      this.$store.commit("auth/setSdkFacebook", this.scope);
    },
    async callSuccessFromApple(data) {
      this.loginMethod = 4;
      let loginMethod = 4;
      let tokenId = data.authorization.id_token;
      let accessToken = data.authorization.id_token;
      let authorizationCode = data.authorization.code;
      
      await this.loginSocial({
        loginMethod,
        tokenId,
        accessToken,
        loginPlatform: 1,
        authorizationCode,
        deviceId: this.deviceId,
      });
    },
    callFailApple(error) {
      console.log(error);
    }
  },
};
</script>