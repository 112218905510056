<template>
  <header
    class="header header-fix"
    :class="[!showNavbar ? 'py-xxl-3' : 'header-px']"
  >
    <b-container class="pl-xl-0 pr-xl-0">
      <b-navbar
        toggleable="lg"
        class="px-0"
        :class="[showNavbar && isMobile() ? 'my--5' : '']"
      >
        <b-navbar-brand
          class="mr-2 pl-xl-4 pr-xl-5 mr-xl-3"
          :title="$t('site_map.menu.esports')"
          to="/"
        >
          <!-- <esports-logo-header /> -->
          <span class="d-none d-md-block"><esports-logo-header /></span>
          <span class="d-block d-md-none"><esports-logo-header-mobile /></span>
        </b-navbar-brand>

        <b-navbar-toggle
          v-b-toggle.sidebar-menu
          target=""
          class="btn-right-toggle"
        >
          <template #default="{ expanded }">
            <esports-icon-menu v-if="!expanded" />
            <esports-close-menu v-else />
          </template>
        </b-navbar-toggle>

        <!-- <b-navbar-toggle class="group-menu-buttons" tag="div" target="">
          <div class="outline-primary" v-if="!authenticated">
            <template>
              <b-link href="/register">{{
                $t("general_inputs.btn_register")
              }}</b-link>
              <span>/</span>
              <b-link href="/login">{{ $t("general_inputs.login") }}</b-link>
            </template>
          </div>
          <template v-else>
            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              class="dropdown-account"
              no-caret
              :menu-class="[
                'account-user',
                isAnimatedMenu
                  ? 'animate__animated animate__fadeIn'
                  : 'animate__animated animate__fadeOut',
              ]"
              @show="showDropdownMenu"
              @hide="hideDropdownMenu"
              right
              toggle-tag="div"
            >
              <template #button-content>
                <b-avatar
                  href="#foo"
                  variant="primary"
                  :src="user.avatar"
                  v-if="user && user.avatar"
                  class="avatar-link"
                ></b-avatar>
                <b-avatar
                  href="#foo"
                  variant="primary"
                  :text="letterName(user.username)"
                  class="align-baseline"
                  v-else
                ></b-avatar>
                <span class="pl-2" v-if="user">{{ user.username }} さん</span>
              </template>
              <b-dropdown-item
                class="border-bottom py-2"
                v-if="user && user.roleId !== 1"
                to="/my-page"
                ><i class="fa fa-id-badge pr-2" aria-hidden="true"></i
                >マイページ</b-dropdown-item
              >
              <b-dropdown-item
                class="border-bottom py-2"
                v-if="user && user.roleId === 1"
                to="/admin-manager"
                ><i class="fa fa-user-secret pr-2" aria-hidden="true"></i>
                管理者ページ</b-dropdown-item
              >
              <b-dropdown-item @click="handleLogout" class="py-2"
                ><i class="fa fa-sign-out pr-2" aria-hidden="true"></i>
                {{ $t("general_inputs.btn_logout") }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-navbar-toggle> -->

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="align-items-center justify-content-end w-100">
            <!-- <b-nav-item to="/events">
              <span class="title">{{ $t("site_map.menu.event") }}</span>
              <span class="sub-title">大会情報</span>
            </b-nav-item>
            <b-nav-item class="player" to="/players">
              <span class="title">{{ $t("site_map.menu.player") }}</span>
              <span class="sub-title">選手情報</span>
            </b-nav-item> -->
            <!-- <b-nav-item
              v-if="authenticated && user.roleId !== 1"
              class="ticket"
              target="_blank"
              :href="`${anchor + user.hashUserId}`"
            >
              <span class="title">{{ $t("site_map.menu.ticket") }}</span>
              <span class="sub-title">票取得</span>
            </b-nav-item>
            <b-nav-item
              v-else-if="authenticated && user.roleId === 1"
              class="ticket"
            >
              <span class="title">{{ $t("site_map.menu.ticket") }}</span>
              <span class="sub-title">票取得</span>
            </b-nav-item>
            <b-nav-item v-else class="ticket" @click="handleLogin">
              <span class="title">{{ $t("site_map.menu.ticket") }}</span>
              <span class="sub-title">票取得</span>
            </b-nav-item> -->
            <b-nav-item class="donate" to="/donate">
              <span class="title">Donate</span>
              <span class="sub-title">ドネトについて</span>
            </b-nav-item>
            <b-nav-item class="faq" to="/faqs">
              <span class="title">Faq</span>
              <span class="sub-title">よくある質問</span>
            </b-nav-item>
            <b-nav-item to="/about-us">
              <span class="title">{{ $t("site_map.menu.about") }}</span>
              <span class="sub-title sub-title-about">Donate eSportsとは</span>
            </b-nav-item>
            <li
              class="
                nav-item
                d-flex
                justify-content-center
                d-lg-block
                ml-xxl-5
                pl-xxl-3
              "
              v-if="isShowSocial"
            >
              <b-link href="#" title="Twitter"
                ><esports-circle-twitter width="38" height="38"
              /></b-link>
              <b-link class="mx-2 mx-xl-3 mx-xxl-4" href="#" title="Facebook"
                ><esports-circle-facebook width="38" height="38"
              /></b-link>
              <b-link href="#" title="Instagram"
                ><esports-circle-instagram width="38" height="38"
              /></b-link>
            </li>
            <li
              class="nav-item ml-2 mx-xl-3 mx-xxl-4 nav-search py-2 py-xxl-0"
              v-if="isShowSocial"
            >
              <b-link title="Search"><esports-icon-search /></b-link>
            </li>

            <!-- <li class="nav-item mb-2 mb-lg-0 ml-xxlg-4 group-auth pr-xl-4">
              <template v-if="!authenticated">
                <b-link
                  to="/register"
                  class="
                    btn
                    rounded-0
                    btn-outline-secondary btn-register
                    active
                  "
                  id="register"
                  ><span class="text">{{
                    $t("general_inputs.register")
                  }}</span></b-link
                >
                <b-link
                  to="/login"
                  class="btn rounded-0 btn-outline-secondary btn-login"
                  id="login"
                  @mouseover="mouseOverBtnLogin"
                  @mouseleave="mouseLeaveBtnLogin"
                  ><span class="slide" aria-hidden="true"></span>
                  <span class="text">{{
                    $t("general_inputs.login")
                  }}</span></b-link
                >
              </template>
              <template v-else>
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none"
                  class="dropdown-account"
                  no-caret
                  :menu-class="[
                    'account-user',
                    isAnimatedMenu
                      ? 'animate__animated animate__fadeIn'
                      : 'animate__animated animate__fadeOut',
                  ]"
                  right
                  toggle-tag="div"
                  @show="showDropdownMenu"
                  @hide="hideDropdownMenu"
                >
                  <template #button-content>
                    <b-avatar
                      href="#foo"
                      variant="primary"
                      :src="user.avatar"
                      v-if="user && user.avatar"
                      class="avatar-link"
                    ></b-avatar>
                    <b-avatar
                      href="#foo"
                      variant="primary"
                      :text="letterName(user.username)"
                      class="align-baseline"
                      v-else
                    ></b-avatar>
                    <span class="pl-2" :title="user.username" v-if="user"
                      >{{ user.username }} さん</span
                    >
                  </template>
                  <b-dropdown-item
                    class="border-bottom"
                    v-if="user && user.roleId !== 1"
                    to="/my-page"
                    ><i class="fa fa-id-badge pr-2" aria-hidden="true"></i
                    >マイページ</b-dropdown-item
                  >
                  <b-dropdown-item
                    class="border-bottom"
                    v-if="user && user.roleId === 1"
                    to="/admin-manager"
                    ><i class="fa fa-user-secret pr-2" aria-hidden="true"></i>
                    管理者ページ</b-dropdown-item
                  >
                  <b-dropdown-item @click="handleLogout"
                    ><i class="fa fa-sign-out pr-2" aria-hidden="true"></i>
                    {{ $t("general_inputs.btn_logout") }}</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </li> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-sidebar id="sidebar-menu" sidebar-class="sidebar-menu" backdrop shadow>
        <!-- <template #default="{ hide }"> -->
        <template>
          <div class="px-3 py-2">
            <!-- <template v-if="!authenticated">
              <b-button
                @click="redirectLink('/register', hide)"
                class="primary px-4"
                block
                >{{ $t("general_inputs.register") }}
              </b-button>
              <b-button
                @click="redirectLink('/login', hide)"
                class="primary px-4"
                block
                >{{ $t("general_inputs.btn_login") }}
              </b-button>
            </template> -->
            <b-list-group class="border-0 rounded-0">
              <!-- <b-list-group-item to="/events">
                <span class="title d-block">{{
                  $t("site_map.menu.event")
                }}</span>
                <span class="sub-title d-block">大会情報</span>
              </b-list-group-item>
              <b-list-group-item to="/players">
                <span class="title d-block">{{
                  $t("site_map.menu.player")
                }}</span>
                <span class="sub-title d-block">選手情報</span>
              </b-list-group-item> -->
              <!-- <b-list-group-item
                v-if="authenticated && user.roleId !== 1"
                target="_blank"
                :href="`${anchor + user.hashUserId}`"
              >
                <span class="title d-block">{{
                  $t("site_map.menu.ticket")
                }}</span>
                <span class="sub-title d-block">票取得</span>
              </b-list-group-item>
              <b-list-group-item v-else-if="authenticated && user.roleId === 1">
                <span class="title d-block">{{
                  $t("site_map.menu.ticket")
                }}</span>
                <span class="sub-title d-block">票取得</span>
              </b-list-group-item>
              <b-list-group-item v-else @click="handleLogin">
                <span class="title d-block">{{
                  $t("site_map.menu.ticket")
                }}</span>
                <span class="sub-title d-block">票取得</span>
              </b-list-group-item> -->
              <b-list-group-item to="/donate">
                <span class="title d-block">Donate</span>
                <span class="sub-title sub-title-about d-block"
                  >ドネトについて</span
                >
              </b-list-group-item>
              <b-list-group-item to="/faqs">
                <span class="title d-block">Faq</span>
                <span class="sub-title sub-title-about d-block"
                  >よくある質問</span
                >
              </b-list-group-item>
              <b-list-group-item to="/about-us">
                <span class="title d-block">{{
                  $t("site_map.menu.about")
                }}</span>
                <span class="sub-title sub-title-about d-block"
                  >Donate eSportsとは</span
                >
              </b-list-group-item>
              <b-list-group-item to="/contact-us" class="link-service mt-3">
                <span class="title d-block">お問合せ</span>
              </b-list-group-item>
              <b-list-group-item to="/term-of-use" class="link-service">
                <span class="title d-block">利用規約</span>
              </b-list-group-item>
              <b-list-group-item to="/privacy" class="link-service py-2">
                <span class="title d-block">プライバシーポリシー</span>
              </b-list-group-item>
              <b-list-group-item
                to="/commercial-transaction"
                class="link-service py-2"
              >
                <span class="title d-block">特定商取引法に関する表記</span>
              </b-list-group-item>
              <b-list-group-item
                to="/operation-company"
                class="link-service py-2"
              >
                <span class="title d-block">運営会社</span>
              </b-list-group-item>
              <b-list-group-item
                class="no-anchor pt-4 mt-1"
                v-if="isShowSocial"
              >
                <div class="d-flex justify-content-around">
                  <b-link><esports-icon-menu-search /></b-link>
                  <b-link><esports-icon-menu-twitter /></b-link>
                  <b-link><esports-icon-menu-facebook /></b-link>
                  <b-link><esports-icon-menu-instagram /></b-link>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>
      </b-sidebar>
    </b-container>

    <!-- modal notify ticket login -->
    <b-modal
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      @hide="hideNotify('login')"
      v-model="showModalTicketLogin"
    >
      <div class="vote-twitter text-center">
        <h5 class="mb-4 text-center">
          ログイン報酬で投票チケット<br /><span class="font-weight-bold"
            >{{ numberTicketsFirstAccess }}票</span
          >獲得しました。
        </h5>
        <b-button class="font-weight-bold" @click="hideNotify('login')"
          >閉じる</b-button
        >
      </div>
    </b-modal>

    <!-- modal notify ticket ads -->
    <b-modal
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      @hide="hideNotify('ads')"
      v-model="showNotifyTicketAds"
    >
      <div class="vote-twitter text-center">
        <h5 class="mb-4 text-center" v-if="notifyAds">
          広告で投票チケット<br /><span class="font-weight-bold"
            >{{ notifyAds.data.tickets }}票</span
          >獲得しました。
        </h5>
        <b-button class="font-weight-bold" @click="hideNotify('ads')"
          >閉じる</b-button
        >
      </div>
    </b-modal>

    <!-- modal notify ticket invite -->
    <b-modal
      ref="add_ticket_modal"
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      @hide="hideNotify('invite')"
      v-model="showNotifyTicketInvite"
    >
      <div class="vote-twitter text-center">
        <h5 class="mb-4 text-center" v-if="notifyInvite">
          友達招待で投票チケット<br /><span class="font-weight-bold"
            >{{ notifyInvite.data.tickets }}票</span
          >獲得しました。
        </h5>
        <h5 class="mb-4 text-center" v-else>
          友達招待で投票チケット<br /><span class="font-weight-bold"
            >{{ numberTicketsInvited }}票</span
          >獲得しました。
        </h5>
        <b-button class="font-weight-bold" @click="hideNotify('invite')"
          >閉じる</b-button
        >
      </div>
    </b-modal>
    <LoginModal :isShowLoginModal="isShowLoginModal" @resetModal="resetModal" />
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LoginModal from "@/components/Modals/LoginModal";
import firebase from "@/utils/firebase";

export default {
  name: "Header",
  components: { LoginModal },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
    ...mapState("auth", [
      "isShowModalTicket",
      "isShowNotifyTicketInvite",
      "numberTicketsInvited",
      "numberTicketsFirstAccess"
    ]),
    showModalTicketLogin: {
      get() {
        return this.authenticated && this.isShowModalTicket;
      },
      set(isShowTicket) {
        return isShowTicket;
      },
    },
    showNotifyTicketInvite: {
      get() {
        return this.authenticated && this.isShowNotifyTicketInvite;
      },
      set(isShowTicketInvite) {
        return isShowTicketInvite;
      },
    },
    isUserNotify() {
      return this.authenticated && this.user.roleId !== 1;
    },
  },
  data() {
    return {
      isShowSocial: false,
      showNavbar: false,
      showNotifyTicketAds: false,
      notifyAds: null,
      notifyInvite: null,
      anchor:
        "https://ow.skyflag.jp/ad/p/ow/index?_owp=XS1XxA44rFzJtfeEByQBngAdMaGe3DAdMaGe3D&suid=",
      isShowLoginModal: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (!this.authenticated) {
      this.$store.commit("auth/clearSdk");
    }

    firebase.messaging().onMessage(async (payload) => {
      console.log("Message received", payload);

      if (this.isUserNotify && payload?.data?.type === "0") {
        this.showNotifyTicketAds = true;
        this.notifyAds = payload;
      }

      if (this.isUserNotify && payload?.data?.type === "1") {
        this.$store.commit("auth/setUpModalTicketInvite", true);
        this.notifyInvite = payload;
      }
    });
  },
  watch: {
    $route(to, from) {
      //handle to route changes...
    },
  },
  methods: {
    redirectLink(path, hide) {
      this.$router.push({ path: path });
      hide();
    },
    mouseOverBtnLogin() {
      document.getElementById("register").classList.remove("active");
    },
    mouseLeaveBtnLogin() {
      document.getElementById("register").classList.add("active");
    },
    async handleLogout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push({ path: "/" });
    },
    resetModal(isHideModal) {
      this.isShowLoginModal = isHideModal;
    },
    handleScroll(event) {
      this.showNavbar = window.pageYOffset > 0 ? true : false;
    },
    hideNotify(action) {
      switch (action) {
        case "ads":
          this.showNotifyTicketAds = false;
          this.notifyAds = null;
          break;
        case "invite":
          this.$store.commit("auth/setUpModalTicketInvite", false);
          this.notifyInvite = null;
          break;
        case "login":
          this.$store.commit("auth/setUpModalTicket", false);
          break;
      }
    },
    handleLogin() {
      this.isShowLoginModal = true;
    },
  },
};
</script>