<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.857"
    height="19.356"
    viewBox="0 0 23.857 19.356"
  >
    <line
      id="Line_23"
      data-name="Line 23"
      y1="17"
      x2="20.983"
      transform="translate(0.944 1.191)"
      fill="none"
      stroke="#ea6e30"
      stroke-width="3"
    />
    <line
      id="Line_23-2"
      data-name="Line 23"
      x2="22"
      y2="16.857"
      transform="translate(0.944 1.191)"
      fill="none"
      stroke="#ea6e30"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
    name: "IconCloseMenu"
};
</script>