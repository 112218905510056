<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.321"
    height="25.318"
    viewBox="0 0 25.321 25.318"
  >
    <path
      id="Layer_24"
      data-name="Layer 24"
      d="M28.966,27.556l-5.581-5.581a11,11,0,1,0-1.41,1.41l5.581,5.581a1,1,0,1,0,1.41-1.41Zm-22.99-12.6a8.981,8.981,0,1,1,15.334,6.32h0a8.974,8.974,0,0,1-15.334-6.32Z"
      transform="translate(-3.94 -3.94)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
    name: "IconMenuSearch"
};
</script>