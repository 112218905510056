import { voteService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async getRanksEvent({ commit }, payload) {
        try {
            const result = await voteService.getRanksEvent(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getHistoryVote({ commit }) {
        try {
            const result = await voteService.getHistoryVote();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getHistoryTicket({ commit }, payload) {
        try {
            const result = await voteService.getHistoryTicket(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getHistoryPoint({ commit }) {
        try {
            const result = await voteService.getHistoryPoint();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getAllHistoryPoint({ commit }) {
        try {
            const result = await voteService.getAllHistoryPoint();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getAllHistoryVote({ commit }) {
        try {
            const result = await voteService.getAllHistoryVote();
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getAllHistoryTicket({ commit }, payload) {
        try {
            const result = await voteService.getAllHistoryTicket(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async voteGamerEvent({ commit, dispatch }, payload) {
        try {
            const result = await voteService.voteGamerEvent(payload);
            const dataUser = await this.dispatch('user/getMyPage', { root: true });
            this.commit('auth/updateUserProfile', dataUser, { root: true });
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async voteHistoryEvent({ commit }, payload) {
        try {
            const result = await voteService.voteHistoryEvent(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}