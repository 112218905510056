<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.033"
    height="21"
    viewBox="0 0 25.033 21"
  >
    <line
      id="Line_23"
      data-name="Line 23"
      x2="25.033"
      transform="translate(0 1.5)"
      fill="none"
      stroke="#ea6e30"
      stroke-width="3"
    />
    <line
      id="Line_23-2"
      data-name="Line 23"
      x2="25.033"
      transform="translate(0 10.5)"
      fill="none"
      stroke="#ea6e30"
      stroke-width="3"
    />
    <line
      id="Line_23-3"
      data-name="Line 23"
      x2="25.033"
      transform="translate(0 19.5)"
      fill="none"
      stroke="#ea6e30"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
    name: "IconMenu"
};
</script>