<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="178"
    height="68"
    viewBox="0 0 178 68"
  >
    <defs>
      <filter
        id="Rectangle_2420"
        x="0"
        y="0"
        width="178"
        height="68"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="6" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.2" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient
        id="linear-gradient"
        x1="0.068"
        y1="0.33"
        x2="1.017"
        y2="0.698"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="0.2" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
    </defs>
    <g id="Group_2590" data-name="Group 2590" transform="translate(13 5)">
      <g transform="matrix(1, 0, 0, 1, -13, -5)" filter="url(#Rectangle_2420)">
        <g
          id="Rectangle_2420-2"
          data-name="Rectangle 2420"
          transform="translate(9 3)"
          stroke="#f8f1f0"
          stroke-width="2"
        >
          <rect width="160" height="50" rx="11" stroke="none" />
          <rect x="1" y="1" width="158" height="48" rx="10" fill="none" />
        </g>
      </g>
      <text
        id="App_Store"
        data-name="App Store"
        transform="translate(47.882 20.523)"
        fill="#fff"
        font-size="13"
        font-family="SegoeUI, Segoe UI"
        letter-spacing="-0.015em"
      >
        <tspan x="0" y="0">App Store</tspan>
      </text>
      <text
        id="からダウンロード"
        transform="translate(47.491 33.398)"
        fill="#fff"
        font-size="10"
        font-family="YuGothicUI-Regular, Yu Gothic UI"
        letter-spacing="-0.015em"
      >
        <tspan x="0" y="0">からダウンロード</tspan>
      </text>
      <g
        id="Group_2589"
        data-name="Group 2589"
        transform="translate(16.564 6.763)"
      >
        <path
          id="Path_78"
          data-name="Path 78"
          d="M12.595,7.139a.54.54,0,0,0,.124-.013A6.555,6.555,0,0,0,17.948.59a.6.6,0,0,0-.72-.576A6.555,6.555,0,0,0,12,6.55a.595.595,0,0,0,.595.589Z"
          transform="translate(-0.104 0)"
          fill="#ddd"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M25.4,18.529a5.117,5.117,0,0,1-3.176-4.794,5.3,5.3,0,0,1,2.629-4.583.594.594,0,0,0,.137-.91A7.1,7.1,0,0,0,19.845,6a7.269,7.269,0,0,0-2.787.619,6.633,6.633,0,0,1-2.566.572,6.633,6.633,0,0,1-2.566-.572A7.291,7.291,0,0,0,9.138,6C8.408,6,2,6.233,2,14.328c0,6.621,4.516,13.087,7.138,13.087a7.878,7.878,0,0,0,3.343-.707,4.883,4.883,0,0,1,2.011-.482,4.883,4.883,0,0,1,2.011.482,7.9,7.9,0,0,0,3.343.707c2.131,0,4.64-4.2,5.92-8.143A.594.594,0,0,0,25.4,18.529Z"
          transform="translate(-2 1.138)"
          fill="#ddd"
        />
        <path
          id="Path_81"
          data-name="Path 81"
          d="M14.492,7.139a.54.54,0,0,0,.124-.013A6.555,6.555,0,0,0,19.845.59a.6.6,0,0,0-.72-.576A6.555,6.555,0,0,0,13.9,6.55a.595.595,0,0,0,.595.589ZM25.4,19.667a5.117,5.117,0,0,1-3.176-4.794,5.3,5.3,0,0,1,2.629-4.583.594.594,0,0,0,.137-.91,7.1,7.1,0,0,0-5.145-2.241,7.269,7.269,0,0,0-2.787.619,6.633,6.633,0,0,1-2.566.572,6.633,6.633,0,0,1-2.566-.572,7.291,7.291,0,0,0-2.787-.619C8.408,7.138,2,7.371,2,15.466c0,6.621,4.516,13.087,7.138,13.087a7.878,7.878,0,0,0,3.343-.707,4.883,4.883,0,0,1,2.011-.482,4.883,4.883,0,0,1,2.011.482,7.878,7.878,0,0,0,3.343.707c2.131,0,4.64-4.2,5.92-8.143A.594.594,0,0,0,25.4,19.667Z"
          transform="translate(-2 0)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "AppStoreFooter"
};
</script>