import api from './api'

const version = ''
const namespace = '/game'
const resource = `${namespace}`

export default {
    getGameList(params) {
        return api().get(`${resource}/all`, { params })
    },
    getGameDetail(eventId) {
        return api().get(`${resource}/all/${eventId}`)
    },
}