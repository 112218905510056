import api from './api'

const version = ''
const namespace = '/ads-info'
const resource = `${namespace}`

export default {
    getTicketList(params) {
        return api().get(`${resource}/all`, { params })
    },
    getTicket(params) {
        return api().post(`/ticket/register-game`, params)
    },
    shareEvent(params) {
        return api().post(`/ticket/share-event`, params)
    },
    postTicketByAds(params) {
        return api().post(`/ticket`, params)
    },
    postAd(params) {
        return api().post(`/ticket/view-ad`, params)
    }
}