<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="56"
    viewBox="0 0 57 56"
  >
    <g id="Group_3058" data-name="Group 3058" transform="translate(-787 -251)">
      <ellipse
        id="Ellipse_62"
        data-name="Ellipse 62"
        cx="28.5"
        cy="28"
        rx="28.5"
        ry="28"
        transform="translate(787 251)"
        fill="#d9182e"
      />
      <path
        id="Icon_ionic-logo-google"
        data-name="Icon ionic-logo-google"
        d="M29.083,14.409l-.132-.558H16.821v5.134h7.247a7.254,7.254,0,0,1-7.1,5.454,8.487,8.487,0,0,1-5.711-2.276,8.153,8.153,0,0,1-2.432-5.755,8.409,8.409,0,0,1,2.389-5.749A8.125,8.125,0,0,1,16.89,8.441a7.4,7.4,0,0,1,4.827,1.881l3.649-3.63a12.909,12.909,0,0,0-8.595-3.31h0a13.287,13.287,0,0,0-9.4,3.824,13.233,13.233,0,0,0-3.711,9.209,13.1,13.1,0,0,0,3.567,9.059,13.608,13.608,0,0,0,9.811,3.981,12.1,12.1,0,0,0,8.814-3.7,13.014,13.014,0,0,0,3.385-9.015A15.011,15.011,0,0,0,29.083,14.409Z"
        transform="translate(799.055 263.08)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCircleGoogle",
};
</script>