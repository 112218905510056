import { adminAdsService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async upSertAds({ commit }, payload) {
        try {
            const result = await adminAdsService.upSertAds(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getAdsAll({ commit }, payload) {
        try {
            const result = await adminAdsService.getAdsAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailAds({ commit }, payload) {
        
        try {
            const result = await adminAdsService.getDetailAds(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async deleteAds({ commit }, payload) {
        try {
            const result = await adminAdsService.deleteAds(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}