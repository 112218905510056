import { adminTeamService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async upSertTeam({ commit }, payload) {
        try {
            const result = await adminTeamService.upSertTeam(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getTeamsAll({ commit }, payload) {
        try {
            const result = await adminTeamService.getTeamsAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailTeam({ commit }, payload) {
        
        try {
            const result = await adminTeamService.getDetailTeam(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async deleteTeam({ commit }, payload) {
        try {
            const result = await adminTeamService.deleteTeam(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailTeamByAdmin({ commit }, payload) {
        try {
            const result = await adminTeamService.getDetailTeamByAdmin(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}