<template>
  <svg
    id="_22fe707fb0c477ff7d13fbe2964e30ae"
    data-name="22fe707fb0c477ff7d13fbe2964e30ae"
    xmlns="http://www.w3.org/2000/svg"
    width="39.5"
    height="27.901"
    viewBox="0 0 39.5 27.901"
  >
    <path
      id="Path_2204"
      data-name="Path 2204"
      d="M9.025,49.576a7.657,7.657,0,0,0,6.928-4.42A7.636,7.636,0,0,0,30.374,43.4h.533V63.808H1V43.431h.533A7.636,7.636,0,0,0,9.025,49.576Z"
      transform="translate(8.593 -35.908)"
      fill="#fff"
    />
    <path
      id="Path_2205"
      data-name="Path 2205"
      d="M57.55,31.6a6.05,6.05,0,1,1,6.05-6.05A6.034,6.034,0,0,1,57.55,31.6Zm0-8.4a2.32,2.32,0,1,0,2.32,2.32A2.315,2.315,0,0,0,57.55,23.2Z"
      transform="translate(-39.932 -19.5)"
      fill="#fff"
    />
    <path
      id="Path_2206"
      data-name="Path 2206"
      d="M13.35,31.6a6.05,6.05,0,1,1,6.05-6.05A6.034,6.034,0,0,1,13.35,31.6Zm0-8.4a2.32,2.32,0,1,0,2.32,2.32A2.315,2.315,0,0,0,13.35,23.2Z"
      transform="translate(18.124 -19.5)"
      fill="#fff"
    />
    <path
      id="Path_2207"
      data-name="Path 2207"
      d="M109.494,50.6V62.265l-7.994,3.1V47.5Z"
      transform="translate(-101.5 -38.722)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCamera",
};
</script>