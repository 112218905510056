<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.459"
    height="20.918"
    viewBox="0 0 17.459 20.918"
  >
    <path
      id="Path_2186"
      data-name="Path 2186"
      d="M-3135.2-5919.052l9.045,9.045-9.045,9.045"
      transform="translate(3136.618 5920.466)"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      id="Path_2214"
      data-name="Path 2214"
      d="M-3135.2-5919.052l9.045,9.045-9.045,9.045"
      transform="translate(3142.618 5920.466)"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDoubleArrowLeft",
  props: {
    color: {
      type: String,
      default: "rgba(35,31,32,0.51)",
    },
  },
};
</script>
<style scoped>
svg {
  transform: rotate(180deg);
}
</style>