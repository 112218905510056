<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="223.082"
    height="419.87"
    viewBox="0 0 223.082 419.87"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1.176"
        y1="0.5"
        x2="0.783"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="0" />
        <stop offset="0.165" stop-color="#fff" stop-opacity="0.024" />
        <stop offset="0.394" stop-color="#fff" stop-opacity="0.094" />
        <stop offset="0.661" stop-color="#fff" stop-opacity="0.212" />
        <stop offset="0.955" stop-color="#fff" stop-opacity="0.373" />
        <stop offset="1" stop-color="#fff" stop-opacity="0.4" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-41.883"
        y1="0.5"
        x2="-42.277"
        y2="0.5"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="0.5"
        y1="-0.022"
        x2="0.5"
        y2="1.022"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#302d2c" />
        <stop offset="0.019" stop-color="#343130" />
        <stop offset="0.154" stop-color="#514f4e" />
        <stop offset="0.287" stop-color="#656464" />
        <stop offset="0.415" stop-color="#717171" />
        <stop offset="0.533" stop-color="#767676" />
        <stop offset="0.632" stop-color="#717170" />
        <stop offset="0.749" stop-color="#636262" />
        <stop offset="0.876" stop-color="#4c4b4a" />
        <stop offset="1" stop-color="#302d2c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="0.5"
        y1="-0.022"
        x2="0.5"
        y2="1.022"
        xlink:href="#linear-gradient-3"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="-2660.85"
        y1="-30.574"
        x2="-2660.85"
        y2="-29.53"
        xlink:href="#linear-gradient-3"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="0.5"
        y1="-0.04"
        x2="0.5"
        y2="1.112"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#302d2c" />
        <stop offset="0.24" stop-color="#312e2d" />
        <stop offset="0.327" stop-color="#383534" />
        <stop offset="0.388" stop-color="#434140" />
        <stop offset="0.438" stop-color="#535151" />
        <stop offset="0.481" stop-color="#686767" />
        <stop offset="0.518" stop-color="#818181" />
        <stop offset="0.533" stop-color="#8e8e8f" />
        <stop offset="0.538" stop-color="#818181" />
        <stop offset="0.549" stop-color="#686767" />
        <stop offset="0.563" stop-color="#535151" />
        <stop offset="0.579" stop-color="#434140" />
        <stop offset="0.599" stop-color="#383534" />
        <stop offset="0.626" stop-color="#312e2d" />
        <stop offset="0.703" stop-color="#302d2c" />
      </linearGradient>
      <radialGradient
        id="radial-gradient"
        cx="0.5"
        cy="0.5"
        r="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#707070" />
        <stop offset="0.165" stop-color="#fff" stop-opacity="0.024" />
        <stop offset="0.394" stop-color="#fff" stop-opacity="0.094" />
        <stop offset="0.661" stop-color="#fff" stop-opacity="0.212" />
        <stop offset="0.955" stop-color="#fff" stop-opacity="0.373" />
        <stop offset="1" stop-color="#fff" stop-opacity="0.4" />
      </radialGradient>
      <linearGradient
        id="linear-gradient-7"
        x1="0.5"
        y1="-0.022"
        x2="0.5"
        y2="1.022"
        xlink:href="#linear-gradient-3"
      />
    </defs>
    <g id="Group_2585" data-name="Group 2585">
      <path
        id="Path_63"
        data-name="Path 63"
        d="M1625.242,664.839c20.045,0,36.355,15.514,36.355,34.582v350.708c0,19.068-16.31,34.58-36.355,34.58H1480.307c-20.045,0-36.355-15.512-36.355-34.58V699.421c0-19.068,16.31-34.582,36.355-34.582"
        transform="translate(-1441.238 -664.839)"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M1519.608,665.469h-5.767c18.238,0,36.021,14.839,36.021,33.08v350.708c0,18.238-17.782,33.077-36.021,33.077h5.767a33.115,33.115,0,0,0,33.078-33.077V698.549A33.115,33.115,0,0,0,1519.608,665.469Z"
        transform="translate(-1333.832 -663.966)"
        fill="#707070"
      />
      <!-- <path
        id="Path_65"
        data-name="Path 65"
        d="M1477.383,665.469h5.767c-18.238,0-36.023,14.839-36.023,33.08v350.708c0,18.238,17.785,33.077,36.023,33.077h-5.767a33.114,33.114,0,0,1-33.078-33.077V698.549A33.115,33.115,0,0,1,1477.383,665.469Z"
        transform="translate(-1440.748 -663.966)"
        fill="url(#linear-gradient-2)"
      /> -->
      <rect
        id="Rectangle_2418"
        data-name="Rectangle 2418"
        width="196.593"
        height="398.821"
        rx="10.087"
        transform="translate(13.239 10.524)"
        fill="#1a1311"
      />
      <path
        id="Path_66"
        data-name="Path 66"
        d="M1445.529,703.56h-.715c-1.1,0-2-.405-2-.9V689.276c0-.5.9-.9,2-.9h.715S1445.519,703.521,1445.529,703.56Z"
        transform="translate(-1442.814 -632.246)"
        fill="url(#linear-gradient-3)"
      />
      <path
        id="Path_67"
        data-name="Path 67"
        d="M1445.529,730.428h-.715c-1.1,0-2-.405-2-.9v-27.48c0-.5.9-.9,2-.9h.715S1445.519,730.389,1445.529,730.428Z"
        transform="translate(-1442.814 -614.562)"
        fill="url(#linear-gradient-4)"
      />
      <path
        id="Path_77"
        data-name="Path 77"
        d="M1471.965,1063.318c-12.292,0-22.291-9.442-22.291-21.048V691.563c0-11.608,10-21.05,22.291-21.05h17.674c.651,0,1.076.389,1.076.589,0,.012-.028,4.2-.028,4.281,0,5.361,5.013,9.724,11.176,9.724h91.189c6.163,0,11.176-4.362,11.176-9.724,0-.086-.028-4.3-.028-4.3,0-.181.424-.568,1.091-.57h12.671c12.289,0,22.288,9.442,22.288,21.05v350.708c0,11.605-10,21.048-22.288,21.048Z"
        transform="translate(-1433.312 -656.982)"
        fill="#fafafa"
      />
      <path
        id="Path_68"
        data-name="Path 68"
        d="M1531.387,705.04h.716c1.1,0,2.008.406,2.008.9v47.4c0,.5-.906.9-2.008.9h-.716S1531.4,705.078,1531.387,705.04Z"
        transform="translate(-1311.029 -609.161)"
        fill="url(#linear-gradient-5)"
      />
      <path
        id="Path_69"
        data-name="Path 69"
        d="M1471.965,1063.318c-12.292,0-22.291-9.442-22.291-21.048V691.563c0-11.608,10-21.05,22.291-21.05h17.674c.651,0,1.076.389,1.076.589,0,.012-.028,4.2-.028,4.281,0,5.361,5.013,9.724,11.176,9.724h91.189c6.163,0,11.176-4.362,11.176-9.724,0-.086-.028-4.3-.028-4.3,0-.181.424-.568,1.091-.57h12.671c12.289,0,22.288,9.442,22.288,21.05v350.708c0,11.605-10,21.048-22.288,21.048Z"
        transform="translate(-1433.313 -656.981)"
        fill="none"
      />
      <path
        id="Path_70"
        data-name="Path 70"
        d="M1508.366,672.39c0,.916-1.352,1.667-3.005,1.667h-19.953c-1.655,0-3.008-.751-3.008-1.667s1.352-1.667,3.008-1.667h19.953C1507.014,670.723,1508.366,671.472,1508.366,672.39Z"
        transform="translate(-1382.615 -656.69)"
        fill="#474645"
      />
      <path
        id="Path_71"
        data-name="Path 71"
        d="M1502.834,673.477a3.365,3.365,0,1,1-3.365-3.365A3.366,3.366,0,0,1,1502.834,673.477Z"
        transform="translate(-1362.453 -657.536)"
        fill="url(#linear-gradient-6)"
      />
      <path
        id="Path_72"
        data-name="Path 72"
        d="M1500.891,672.763a2.136,2.136,0,1,1-2.137-2.135A2.136,2.136,0,0,1,1500.891,672.763Z"
        transform="translate(-1361.754 -656.821)"
        fill="#1a1311"
      />
      <path
        id="Path_73"
        data-name="Path 73"
        d="M1449.674,883.452V996.1c0,11.605,10,21.048,22.291,21.048h146c12.289,0,22.288-9.442,22.288-21.048V703.848Z"
        transform="translate(-1433.313 -610.812)"
        opacity="0.05"
        fill="url(#radial-gradient)"
      />
      <path
        id="Path_74"
        data-name="Path 74"
        d="M1499.3,672.182a1.276,1.276,0,1,1-1.276-1.278A1.276,1.276,0,0,1,1499.3,672.182Z"
        transform="translate(-1361.611 -656.439)"
        fill="#007cc9"
        style="mix-blend-mode: multiply; isolation: isolate"
      />
      <path
        id="Path_75"
        data-name="Path 75"
        d="M1498.462,671.383a.525.525,0,1,1-.525-.525A.523.523,0,0,1,1498.462,671.383Z"
        transform="translate(-1360.661 -656.503)"
        fill="#fff"
        opacity="0.56"
      />
      <path
        id="Path_76"
        data-name="Path 76"
        d="M1445.529,746.764h-.715c-1.1,0-2-.408-2-.9V718.377c0-.5.9-.9,2-.9h.715S1445.519,746.725,1445.529,746.764Z"
        transform="translate(-1442.814 -591.937)"
        fill="url(#linear-gradient-7)"
      />
    </g>
    <g
      id="Group_2662"
      data-name="Group 2662"
      transform="translate(-285.765 34.176)"
    >
      <rect
        id="Rectangle_2545"
        data-name="Rectangle 2545"
        width="5.136"
        height="14.11"
        transform="translate(371.796 189.611)"
        fill="#1a1818"
      />
      <path
        id="Path_238"
        data-name="Path 238"
        d="M416.373,160.706l.292,7.333a2.966,2.966,0,0,0,.259,1.227,10.537,10.537,0,0,0,1.282,1.522,1.321,1.321,0,0,1,.312.936q0,1.375-1.644,1.916a21.535,21.535,0,0,1-5.875.542,11.58,11.58,0,0,1-4.06-.5,1.752,1.752,0,0,1-1.23-1.687,1.192,1.192,0,0,1,.125-.615,11.144,11.144,0,0,1,.833-.968q.834-.873.834-5.831a10.231,10.231,0,0,0-.666-4.249,2.426,2.426,0,0,0-3.917-.209,7.088,7.088,0,0,0-.519,3.25V167.5a6.137,6.137,0,0,0,.154,1.687,10.255,10.255,0,0,0,1.033,1.769,1.622,1.622,0,0,1,.227.856q0,1.228-1.706,1.811a17.834,17.834,0,0,1-5.332.584q-5.791,0-5.79-2.395a1.35,1.35,0,0,1,.177-.761,3.8,3.8,0,0,1,.843-.739,1.733,1.733,0,0,0,.689-.907,16.18,16.18,0,0,0,.269-2.51q.145-2.373.148-4.978a5.155,5.155,0,0,0-.219-1.884,3.43,3.43,0,0,0-1.261-1.093,1.566,1.566,0,0,1-.167-2.739,13.722,13.722,0,0,1,3-1.344q2-.707,3.854-1.2a12.141,12.141,0,0,1,2.541-.489,1.383,1.383,0,0,1,1.052.459,1.541,1.541,0,0,1,.427,1.083c0,.042-.008.215-.023.521a2.3,2.3,0,0,0-.02.292q0,1,.833,1c.334,0,.686-.263,1.063-.79a5.939,5.939,0,0,1,5.206-2.582,7.4,7.4,0,0,1,4.5,1.374,5.284,5.284,0,0,1,1.832,2.3A12.8,12.8,0,0,1,416.373,160.706Z"
        transform="translate(-11.908 -1.652)"
        fill="#343b3c"
      />
      <path
        id="Path_239"
        data-name="Path 239"
        d="M466.66,157.453v7.038a3.247,3.247,0,0,0,.614,2.043,1.908,1.908,0,0,0,1.573.791,4.74,4.74,0,0,0,2.145-.751,1.213,1.213,0,0,1,.625-.187,1.15,1.15,0,0,1,.916.6,2.268,2.268,0,0,1,.416,1.312,3.312,3.312,0,0,1-1.417,2.48,10.677,10.677,0,0,1-7.163,2.52,7.526,7.526,0,0,1-5.354-1.886,6.477,6.477,0,0,1-2.02-4.967v-9.623a.968.968,0,0,0-.113-.591.956.956,0,0,0-.594-.115h-2.146c-.4,0-.66-.067-.77-.2A1.635,1.635,0,0,1,453.2,155v-.916a1.054,1.054,0,0,1,.479-.917l9.538-6a1.872,1.872,0,0,1,.876-.187h1.687a.83.83,0,0,1,.7.239,1.746,1.746,0,0,1,.177.948v2.812a1.164,1.164,0,0,0,.2.811,1.3,1.3,0,0,0,.843.187h3.855a1.29,1.29,0,0,1,1,.282,2.089,2.089,0,0,1,.229,1.2v1.229a2.394,2.394,0,0,1-.292,1.385,1.252,1.252,0,0,1-1.061.384h-3.77a.813.813,0,0,0-1,1Z"
        transform="translate(-22.289 -0.627)"
        fill="#343b3c"
      />
      <path
        id="Path_240"
        data-name="Path 240"
        d="M349.875,149.745a11.732,11.732,0,0,0-6.208-5.457,21.218,21.218,0,0,0-7.538-1.085c-.793,0-1.451.014-1.98.043q-3.746.186-6.727.187-.791,0-2.54-.063c-.529-.014-.986-.02-1.375-.02a5.539,5.539,0,0,0-2.759.572,1.767,1.767,0,0,0-.991,1.593,1.907,1.907,0,0,0,1.438,1.854,2.055,2.055,0,0,1,1.27.937,6.478,6.478,0,0,1,.272,2.333q0,4.456-.135,8.977t-.3,6.08a5.089,5.089,0,0,1-.459,1.824,1.755,1.755,0,0,1-1.125.676,2.762,2.762,0,0,0-1.26.656,1.575,1.575,0,0,0-.384,1.135,2,2,0,0,0,.874,1.687,3.724,3.724,0,0,0,2.27.646q.4,0,1.417-.084,1.623-.124,2.937-.125h1.5q1.917,0,4.436.084l1.864.054V162.3a3.947,3.947,0,1,1,2.061,0v10.022l.76.025a16.749,16.749,0,0,0,5.332-.833,12.738,12.738,0,0,0,6.664-4.915,14.413,14.413,0,0,0,1.854-4.092,16.781,16.781,0,0,0,.689-4.718A16.451,16.451,0,0,0,349.875,149.745Z"
        fill="#e45620"
      />
      <path
        id="Path_241"
        data-name="Path 241"
        d="M383.26,158.636a10.628,10.628,0,0,0-3.314-3.687,11.5,11.5,0,0,0-6.684-1.979,13.8,13.8,0,0,0-9.311,3.291,10.083,10.083,0,0,0-3.665,8.041,9.266,9.266,0,0,0,3,7.163,11.436,11.436,0,0,0,8.061,2.834l.041,0v-6.433a3.947,3.947,0,1,1,2.061,0v6.29a14.359,14.359,0,0,0,6.957-2.876,9.4,9.4,0,0,0,4.144-7.685A10.263,10.263,0,0,0,383.26,158.636Z"
        transform="translate(-6.849 -1.623)"
        fill="#343b3c"
      />
      <path
        id="Path_242"
        data-name="Path 242"
        d="M447.618,168.613a2.9,2.9,0,0,1-.874-.229,1.441,1.441,0,0,1-.439-.584,11.89,11.89,0,0,1-.457-4.187l.04-3.1a9.159,9.159,0,0,0-.529-3.468,5.412,5.412,0,0,0-1.865-2.239,10.161,10.161,0,0,0-6.143-1.834,16.6,16.6,0,0,0-7.874,2,7.065,7.065,0,0,0-2.176,1.749,3.188,3.188,0,0,0-.8,2,2.066,2.066,0,0,0,.644,1.584,2.343,2.343,0,0,0,1.667.6q2.02,0,3.874-2.645a6.965,6.965,0,0,1,1.218-1.374,1.783,1.783,0,0,1,1.115-.374,1.551,1.551,0,0,1,1.5.988,8.135,8.135,0,0,1,.459,3.2c0,.584-.105.956-.314,1.115a2.961,2.961,0,0,1-1.519.24q-5.438.021-8.164,2.644a5.154,5.154,0,0,0-1.751,3.832,5.7,5.7,0,0,0,2.4,4.6,7.077,7.077,0,0,0,3.5,1.116V171.88a3.929,3.929,0,1,1,2.052,0v2.336a7.722,7.722,0,0,0,3.591-1.438,2.067,2.067,0,0,1,1.1-.519,1.159,1.159,0,0,1,.811.542,4.3,4.3,0,0,0,3.458,1.5,6.357,6.357,0,0,0,3.269-.918,6.655,6.655,0,0,0,2.073-1.811,3.534,3.534,0,0,0,.8-2.084A.761.761,0,0,0,447.618,168.613Z"
        transform="translate(-17.64 -1.623)"
        fill="#343b3c"
      />
      <g
        id="Group_2660"
        data-name="Group 2660"
        transform="translate(388.831 175.572)"
      >
        <path
          id="Path_243"
          data-name="Path 243"
          d="M411.732,194.3h-5.912a4.038,4.038,0,0,0,.415,1.815,2.779,2.779,0,0,0,2.552,1.582,3.686,3.686,0,0,0,.991-.126,3.245,3.245,0,0,0,.869-.4,6.539,6.539,0,0,0,.775-.581c.236-.207.543-.486.919-.841a.99.99,0,0,1,.665-.2,1.093,1.093,0,0,1,.753.255.909.909,0,0,1,.288.719,1.97,1.97,0,0,1-.32.958,3.823,3.823,0,0,1-.97,1.051,5.508,5.508,0,0,1-1.628.836,7.013,7.013,0,0,1-2.252.332,6.025,6.025,0,0,1-4.527-1.66,6.184,6.184,0,0,1-1.617-4.507,7.546,7.546,0,0,1,.4-2.484,5.5,5.5,0,0,1,1.162-1.966,5.057,5.057,0,0,1,1.883-1.256,6.746,6.746,0,0,1,2.48-.437,5.882,5.882,0,0,1,3.038.747,4.917,4.917,0,0,1,1.9,1.931,5.076,5.076,0,0,1,.63,2.413q0,1.142-.652,1.478A4.086,4.086,0,0,1,411.732,194.3Zm-5.912-1.717h5.48a3.679,3.679,0,0,0-.835-2.32,2.505,2.505,0,0,0-1.91-.769,2.423,2.423,0,0,0-1.854.78A3.9,3.9,0,0,0,405.821,192.578Z"
          transform="translate(-402.733 -182.914)"
          fill="#1a1818"
        />
        <path
          id="Path_244"
          data-name="Path 244"
          d="M432.04,193.657a4.776,4.776,0,0,1-.759,2.646,5.039,5.039,0,0,1-2.22,1.838,8.317,8.317,0,0,1-3.465.665,7.792,7.792,0,0,1-3.963-.908,5.276,5.276,0,0,1-1.8-1.744,3.953,3.953,0,0,1-.693-2.12,1.438,1.438,0,0,1,1.473-1.45,1.256,1.256,0,0,1,.881.331,2.623,2.623,0,0,1,.615.986,6.561,6.561,0,0,0,.67,1.295,2.705,2.705,0,0,0,1.011.857,3.735,3.735,0,0,0,1.717.339,3.87,3.87,0,0,0,2.374-.681,2.047,2.047,0,0,0,.913-1.7,1.8,1.8,0,0,0-.492-1.312,3.176,3.176,0,0,0-1.273-.769,21.308,21.308,0,0,0-2.087-.564,15.341,15.341,0,0,1-2.928-.959,4.715,4.715,0,0,1-1.87-1.494,3.873,3.873,0,0,1-.692-2.352,4.048,4.048,0,0,1,.73-2.381,4.613,4.613,0,0,1,2.115-1.6,8.655,8.655,0,0,1,3.254-.56,8.072,8.072,0,0,1,2.586.37,5.482,5.482,0,0,1,1.809.986A4.128,4.128,0,0,1,431,184.67a2.983,2.983,0,0,1,.331,1.317,1.556,1.556,0,0,1-.415,1.056,1.318,1.318,0,0,1-1.035.47,1.191,1.191,0,0,1-.857-.282,3.8,3.8,0,0,1-.637-.925,4.168,4.168,0,0,0-1.063-1.433,3.1,3.1,0,0,0-1.992-.514,3.464,3.464,0,0,0-2.054.558,1.625,1.625,0,0,0-.78,1.345,1.365,1.365,0,0,0,.264.842,2.268,2.268,0,0,0,.731.609,4.878,4.878,0,0,0,.941.4q.475.144,1.571.421a24.588,24.588,0,0,1,2.486.707,7.615,7.615,0,0,1,1.894.942,3.87,3.87,0,0,1,1.217,1.4A4.481,4.481,0,0,1,432.04,193.657Z"
          transform="translate(-405.46 -182.023)"
          fill="#1a1818"
        />
        <path
          id="Path_245"
          data-name="Path 245"
          d="M440.479,189.107v.365a5.969,5.969,0,0,1,1.772-1.544,4.33,4.33,0,0,1,2.08-.494,4.82,4.82,0,0,1,2.568.72,4.96,4.96,0,0,1,1.855,2.1,7.351,7.351,0,0,1,.681,3.276,8,8,0,0,1-.394,2.564,5.771,5.771,0,0,1-1.079,1.96,4.712,4.712,0,0,1-1.622,1.217,4.8,4.8,0,0,1-2.01.426,4.2,4.2,0,0,1-2.174-.521,6.226,6.226,0,0,1-1.678-1.527v4.539q0,1.994-1.449,1.993a1.164,1.164,0,0,1-1.13-.514,3.248,3.248,0,0,1-.277-1.5v-13.04a1.908,1.908,0,0,1,.377-1.29,1.3,1.3,0,0,1,1.03-.426,1.366,1.366,0,0,1,1.046.437A1.793,1.793,0,0,1,440.479,189.107ZM446.4,193.5a5.249,5.249,0,0,0-.364-2.054,3.019,3.019,0,0,0-1.013-1.317,2.427,2.427,0,0,0-1.434-.459,2.692,2.692,0,0,0-2.109.986,4.305,4.305,0,0,0-.858,2.9,4.191,4.191,0,0,0,.853,2.8,2.661,2.661,0,0,0,2.114,1,2.432,2.432,0,0,0,1.4-.437,3.006,3.006,0,0,0,1.03-1.312A5.21,5.21,0,0,0,446.4,193.5Z"
          transform="translate(-408.531 -182.919)"
          fill="#1a1818"
        />
        <path
          id="Path_246"
          data-name="Path 246"
          d="M465.867,193.557a7.166,7.166,0,0,1-.421,2.49,5.512,5.512,0,0,1-1.217,1.96,5.377,5.377,0,0,1-1.9,1.257,6.711,6.711,0,0,1-2.491.438,6.567,6.567,0,0,1-2.47-.443,5.463,5.463,0,0,1-3.116-3.216,7.706,7.706,0,0,1,.006-5,5.48,5.48,0,0,1,1.207-1.949,5.363,5.363,0,0,1,1.9-1.245,7.213,7.213,0,0,1,4.96.006,5.359,5.359,0,0,1,3.128,3.211A7.16,7.16,0,0,1,465.867,193.557Zm-3.034,0a4.543,4.543,0,0,0-.813-2.879,2.646,2.646,0,0,0-2.186-1.029,2.714,2.714,0,0,0-1.561.459,2.911,2.911,0,0,0-1.04,1.357,6.157,6.157,0,0,0-.008,4.162,2.979,2.979,0,0,0,1.03,1.357,2.686,2.686,0,0,0,1.578.47,2.64,2.64,0,0,0,2.186-1.035A4.523,4.523,0,0,0,462.833,193.557Z"
          transform="translate(-411.224 -182.919)"
          fill="#1a1818"
        />
        <path
          id="Path_247"
          data-name="Path 247"
          d="M474.114,195.34v2.522a1.951,1.951,0,0,1-.432,1.379,1.444,1.444,0,0,1-1.1.459,1.386,1.386,0,0,1-1.073-.464,1.988,1.988,0,0,1-.421-1.374V189.45q0-2.037,1.473-2.037a1.233,1.233,0,0,1,1.086.475,2.641,2.641,0,0,1,.365,1.407,5.306,5.306,0,0,1,1.113-1.407,2.3,2.3,0,0,1,1.522-.475,3.908,3.908,0,0,1,1.848.475,1.425,1.425,0,0,1,.9,1.263,1.2,1.2,0,0,1-.381.913,1.184,1.184,0,0,1-.825.359,3.744,3.744,0,0,1-.8-.2,3.758,3.758,0,0,0-1.125-.2,1.638,1.638,0,0,0-1.084.348,2.23,2.23,0,0,0-.654,1.036,7.239,7.239,0,0,0-.321,1.633Q474.113,193.977,474.114,195.34Z"
          transform="translate(-414.092 -182.919)"
          fill="#1a1818"
        />
        <path
          id="Path_248"
          data-name="Path 248"
          d="M482.947,186.861h.332v-1.815c0-.488.013-.869.038-1.147a1.679,1.679,0,0,1,.217-.714,1.365,1.365,0,0,1,.509-.5,1.447,1.447,0,0,1,.741-.193,1.489,1.489,0,0,1,1.041.43,1.281,1.281,0,0,1,.394.7,6.425,6.425,0,0,1,.083,1.179v2.06h1.107a1.415,1.415,0,0,1,.979.3,1,1,0,0,1,.339.78.876.876,0,0,1-.482.853,3.132,3.132,0,0,1-1.378.244H486.3V194.6a8.592,8.592,0,0,0,.051,1.09,1.106,1.106,0,0,0,.264.621.913.913,0,0,0,.7.237,3.852,3.852,0,0,0,.72-.093,3.755,3.755,0,0,1,.709-.094.91.91,0,0,1,.658.294.984.984,0,0,1,.294.725,1.213,1.213,0,0,1-.8,1.117,5.386,5.386,0,0,1-2.292.388,3.955,3.955,0,0,1-2.146-.476,2.217,2.217,0,0,1-.959-1.317,8.961,8.961,0,0,1-.226-2.247v-5.8h-.4a1.444,1.444,0,0,1-1-.31,1.017,1.017,0,0,1-.343-.787.978.978,0,0,1,.36-.78A1.581,1.581,0,0,1,482.947,186.861Z"
          transform="translate(-415.828 -182.1)"
          fill="#1a1818"
        />
        <path
          id="Path_249"
          data-name="Path 249"
          d="M502.257,195.749a3.5,3.5,0,0,1-2.407,3.493,8.074,8.074,0,0,1-2.9.459,7.06,7.06,0,0,1-2.788-.5,4.068,4.068,0,0,1-1.716-1.246,2.508,2.508,0,0,1-.554-1.5,1.164,1.164,0,0,1,.355-.853,1.214,1.214,0,0,1,.9-.354,1.049,1.049,0,0,1,.731.232,2.57,2.57,0,0,1,.486.654,3.314,3.314,0,0,0,1.112,1.207,3.352,3.352,0,0,0,1.766.4,2.54,2.54,0,0,0,1.488-.4,1.132,1.132,0,0,0,.581-.924,1.258,1.258,0,0,0-.6-1.162,7.437,7.437,0,0,0-1.986-.7,16.438,16.438,0,0,1-2.541-.813,4.182,4.182,0,0,1-1.568-1.124,2.579,2.579,0,0,1-.586-1.715,3.025,3.025,0,0,1,.543-1.717,3.712,3.712,0,0,1,1.6-1.29,6.122,6.122,0,0,1,2.552-.481,8.331,8.331,0,0,1,2.108.244,5.237,5.237,0,0,1,1.56.652,3.206,3.206,0,0,1,.953.908,1.769,1.769,0,0,1,.326.975,1.127,1.127,0,0,1-.348.852,1.386,1.386,0,0,1-.991.332,1.213,1.213,0,0,1-.791-.266,5.216,5.216,0,0,1-.747-.8,2.712,2.712,0,0,0-.809-.709,2.546,2.546,0,0,0-1.261-.266,2.47,2.47,0,0,0-1.362.349,1.017,1.017,0,0,0-.543.869.94.94,0,0,0,.4.781,3.3,3.3,0,0,0,1.074.5q.674.2,1.859.486a9.918,9.918,0,0,1,2.3.82,3.807,3.807,0,0,1,1.35,1.122A2.5,2.5,0,0,1,502.257,195.749Z"
          transform="translate(-417.549 -182.919)"
          fill="#1a1818"
        />
      </g>
      <circle
        id="Ellipse_39"
        data-name="Ellipse 39"
        cx="9.834"
        cy="9.834"
        r="9.834"
        transform="translate(364.65 177.401)"
        fill="#e45620"
      />
      <g
        id="Group_2661"
        data-name="Group 2661"
        transform="translate(368.953 181.704)"
      >
        <path
          id="Path_250"
          data-name="Path 250"
          d="M385.4,200.439a.626.626,0,0,1-.625-.625V190a.625.625,0,0,1,1.251,0v9.811A.626.626,0,0,1,385.4,200.439Z"
          transform="translate(-379.872 -189.377)"
          fill="#fff"
        />
        <path
          id="Path_251"
          data-name="Path 251"
          d="M389.33,196.511h-9.811a.625.625,0,0,1,0-1.251h9.811a.625.625,0,0,1,0,1.251Z"
          transform="translate(-378.894 -190.355)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_252"
        data-name="Path 252"
        d="M498.453,157.912a8.992,8.992,0,0,0-2.25-2.667A10.327,10.327,0,0,0,489.477,153a11.717,11.717,0,0,0-4.6.9,11.173,11.173,0,0,0-4.665,3.6,10.126,10.126,0,0,0-2.124,6.29,10.562,10.562,0,0,0,1.228,4.957,10.422,10.422,0,0,0,2.375,3.019,10.848,10.848,0,0,0,7.33,2.542,12.414,12.414,0,0,0,7.645-2.48,3.788,3.788,0,0,0,1.874-2.894,2.213,2.213,0,0,0-.519-1.375,1.417,1.417,0,0,0-1.062-.666,2.216,2.216,0,0,0-1.125.5,7.278,7.278,0,0,1-2.009.948,7.4,7.4,0,0,1-2.218.384,4.9,4.9,0,0,1-3.156-1.2,3.346,3.346,0,0,1-1.469-2.551c0-.374.215-.564.645-.564h1.307v-2.045a3.929,3.929,0,1,1,2.052,0v2.045h6.431a1.684,1.684,0,0,0,1.458-.863,4,4,0,0,0,.561-2.218A6.971,6.971,0,0,0,498.453,157.912Z"
        transform="translate(-26.423 -1.627)"
        fill="#343b3c"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "phone",
};
</script>