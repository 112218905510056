import Vue from 'vue'
import VueCookie from 'vue-cookie'
import BootstrapVue from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import { BootstrapVueIcons } from 'bootstrap-vue'
import VueSocialSharing from 'vue-social-sharing'
import VueAppleLogin from 'vue-apple-login'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import globalComponents from '@/plugins/globalComponents'
import { titlePage } from '@/mixins'
import MixinMethods from '@/mixins/methods.mixin.js'
import i18n from '@/utils/i18n'
import Ads from 'vue-google-adsense'
import './registerServiceWorker'

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.mixin(MixinMethods)
Vue.mixin(titlePage)
Vue.use(VueCookie)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(globalComponents)
Vue.use(VueSocialSharing)
Vue.use(VueMeta)

Vue.use(VueAppleLogin, {
  clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
  scope: 'name email',
  redirectURI: `${process.env.VUE_APP_FRONT_END}/redirect`,
  state: new Date().toString(),
  usePopup: true,
});

Vue.config.productionTip = false

// Get language from cookie
if (
  Vue.cookie.get('lang') === null ||
  Vue.cookie.get('lang') === undefined ||
  String(Vue.cookie.get('lang')).trim() == ''
) {
  Vue.cookie.set('lang', 'en', Infinity);
}

i18n.locale = Vue.cookie.get('lang');

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
