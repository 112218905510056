<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114.333"
    height="44.793"
    viewBox="0 0 114.333 44.793"
  >
    <g
      id="Group_2747"
      data-name="Group 2747"
      transform="translate(10.872 -21.745)"
    >
      <g
        id="Component_213_2"
        data-name="Component 213 – 2"
        transform="translate(-10.872 21.745)"
      >
        <rect
          id="Rectangle_2431"
          data-name="Rectangle 2431"
          width="3.801"
          height="10.444"
          transform="translate(39.025 34.349)"
          fill="#231f20"
        />
        <path
          id="Path_120"
          data-name="Path 120"
          d="M409.711,158.742l.216,5.427a2.2,2.2,0,0,0,.192.909,7.8,7.8,0,0,0,.949,1.126.977.977,0,0,1,.231.692q0,1.018-1.217,1.418a15.939,15.939,0,0,1-4.349.4,8.57,8.57,0,0,1-3.005-.37,1.3,1.3,0,0,1-.91-1.248.882.882,0,0,1,.093-.455,8.253,8.253,0,0,1,.617-.717q.617-.646.617-4.316a7.571,7.571,0,0,0-.493-3.145,1.8,1.8,0,0,0-2.9-.155,5.248,5.248,0,0,0-.384,2.406v3.053a4.538,4.538,0,0,0,.114,1.249,7.592,7.592,0,0,0,.765,1.31,1.2,1.2,0,0,1,.168.633q0,.909-1.263,1.341a13.2,13.2,0,0,1-3.947.432q-4.286,0-4.286-1.773a1,1,0,0,1,.131-.564,2.811,2.811,0,0,1,.624-.547,1.284,1.284,0,0,0,.51-.671,12,12,0,0,0,.2-1.858q.107-1.756.109-3.685a3.815,3.815,0,0,0-.162-1.394,2.538,2.538,0,0,0-.933-.809,1.159,1.159,0,0,1-.123-2.027,10.156,10.156,0,0,1,2.22-.995q1.479-.523,2.853-.887a8.985,8.985,0,0,1,1.88-.362,1.024,1.024,0,0,1,.779.339,1.141,1.141,0,0,1,.316.8c0,.031-.006.159-.017.386a1.707,1.707,0,0,0-.015.216q0,.74.617.739c.248,0,.508-.195.787-.585a4.4,4.4,0,0,1,3.854-1.911,5.48,5.48,0,0,1,3.33,1.017,3.913,3.913,0,0,1,1.356,1.7A9.474,9.474,0,0,1,409.711,158.742Z"
          transform="translate(-346.506 -147.01)"
          fill="#444c4c"
        />
        <path
          id="Path_121"
          data-name="Path 121"
          d="M463.164,154.731v5.21a2.4,2.4,0,0,0,.454,1.512,1.412,1.412,0,0,0,1.164.586,3.509,3.509,0,0,0,1.588-.556.9.9,0,0,1,.463-.138.851.851,0,0,1,.678.447,1.679,1.679,0,0,1,.308.971,2.452,2.452,0,0,1-1.049,1.835,7.9,7.9,0,0,1-5.3,1.865,5.57,5.57,0,0,1-3.963-1.4,4.794,4.794,0,0,1-1.5-3.676v-7.123a.717.717,0,0,0-.083-.438.707.707,0,0,0-.439-.085H453.9c-.3,0-.488-.049-.57-.148a1.21,1.21,0,0,1-.124-.685v-.678a.781.781,0,0,1,.354-.679l7.06-4.44a1.386,1.386,0,0,1,.648-.138h1.249a.614.614,0,0,1,.517.177,1.292,1.292,0,0,1,.131.7v2.081a.861.861,0,0,0,.146.6.963.963,0,0,0,.624.138h2.853a.955.955,0,0,1,.739.209,1.547,1.547,0,0,1,.17.887v.91a1.772,1.772,0,0,1-.216,1.025.927.927,0,0,1-.785.285H463.9a.6.6,0,0,0-.741.741Z"
          transform="translate(-370.421 -144.648)"
          fill="#444c4c"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M341.872,148.046a8.684,8.684,0,0,0-4.595-4.039,15.706,15.706,0,0,0-5.579-.8c-.587,0-1.074.01-1.466.031q-2.773.138-4.979.138-.585,0-1.88-.046c-.392-.011-.73-.015-1.018-.015a4.1,4.1,0,0,0-2.042.423,1.308,1.308,0,0,0-.733,1.179,1.412,1.412,0,0,0,1.064,1.373,1.521,1.521,0,0,1,.94.694,4.793,4.793,0,0,1,.2,1.727q0,3.3-.1,6.645t-.223,4.5a3.767,3.767,0,0,1-.34,1.35,1.3,1.3,0,0,1-.833.5,2.044,2.044,0,0,0-.933.486,1.166,1.166,0,0,0-.284.84,1.483,1.483,0,0,0,.647,1.249,2.757,2.757,0,0,0,1.68.478q.293,0,1.049-.062,1.2-.092,2.174-.093h1.11q1.419,0,3.283.062l1.38.04v-7.369a2.921,2.921,0,1,1,1.526,0v7.418l.562.019a12.4,12.4,0,0,0,3.947-.617,9.428,9.428,0,0,0,4.932-3.638,10.666,10.666,0,0,0,1.372-3.029,12.419,12.419,0,0,0,.51-3.492A12.176,12.176,0,0,0,341.872,148.046Z"
          transform="translate(-319.072 -143.204)"
          fill="#ea6e30"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M377.291,157.164a7.868,7.868,0,0,0-2.453-2.729,8.515,8.515,0,0,0-4.947-1.465A10.215,10.215,0,0,0,363,155.406a7.464,7.464,0,0,0-2.713,5.951,6.859,6.859,0,0,0,2.22,5.3,8.465,8.465,0,0,0,5.966,2.1h.03v-4.762a2.922,2.922,0,1,1,1.526,0v4.655a10.628,10.628,0,0,0,5.15-2.129,6.96,6.96,0,0,0,3.067-5.688A7.6,7.6,0,0,0,377.291,157.164Z"
          transform="translate(-334.85 -146.943)"
          fill="#444c4c"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M441.8,164.549a2.143,2.143,0,0,1-.647-.17,1.067,1.067,0,0,1-.325-.432,8.8,8.8,0,0,1-.338-3.1l.03-2.3a6.78,6.78,0,0,0-.392-2.567,4.006,4.006,0,0,0-1.381-1.657,7.521,7.521,0,0,0-4.547-1.357,12.283,12.283,0,0,0-5.828,1.481,5.228,5.228,0,0,0-1.611,1.294,2.36,2.36,0,0,0-.593,1.479,1.53,1.53,0,0,0,.476,1.173,1.735,1.735,0,0,0,1.234.447q1.5,0,2.867-1.958a5.149,5.149,0,0,1,.9-1.017,1.319,1.319,0,0,1,.825-.277,1.148,1.148,0,0,1,1.11.731,6.022,6.022,0,0,1,.34,2.366c0,.432-.078.708-.233.825a2.192,2.192,0,0,1-1.125.178q-4.025.016-6.043,1.957a3.815,3.815,0,0,0-1.3,2.837A4.22,4.22,0,0,0,427,167.893a5.238,5.238,0,0,0,2.591.826v-1.753a2.909,2.909,0,1,1,1.519,0V168.7a5.717,5.717,0,0,0,2.658-1.064,1.529,1.529,0,0,1,.818-.385.858.858,0,0,1,.6.4,3.183,3.183,0,0,0,2.56,1.11,4.706,4.706,0,0,0,2.419-.68,4.925,4.925,0,0,0,1.534-1.341,2.615,2.615,0,0,0,.593-1.542A.563.563,0,0,0,441.8,164.549Z"
          transform="translate(-359.711 -146.943)"
          fill="#444c4c"
        />
        <g
          id="Group_2605"
          data-name="Group 2605"
          transform="translate(51.634 23.958)"
        >
          <path
            id="Path_125"
            data-name="Path 125"
            d="M409.394,192.5h-4.376a2.992,2.992,0,0,0,.307,1.344,2.057,2.057,0,0,0,1.889,1.171,2.729,2.729,0,0,0,.734-.093,2.4,2.4,0,0,0,.643-.3,4.834,4.834,0,0,0,.573-.43c.175-.153.4-.36.68-.623a.733.733,0,0,1,.492-.147.808.808,0,0,1,.557.189.672.672,0,0,1,.213.532,1.457,1.457,0,0,1-.237.709,2.829,2.829,0,0,1-.718.778,4.077,4.077,0,0,1-1.2.619,5.191,5.191,0,0,1-1.667.246,4.459,4.459,0,0,1-3.351-1.229,4.577,4.577,0,0,1-1.2-3.336,5.586,5.586,0,0,1,.294-1.839,4.07,4.07,0,0,1,.86-1.455,3.743,3.743,0,0,1,1.394-.929,4.992,4.992,0,0,1,1.835-.323,4.353,4.353,0,0,1,2.249.553,3.639,3.639,0,0,1,1.405,1.429,3.757,3.757,0,0,1,.467,1.786q0,.845-.483,1.094A3.024,3.024,0,0,1,409.394,192.5Zm-4.376-1.271h4.056a2.723,2.723,0,0,0-.618-1.717,1.854,1.854,0,0,0-1.414-.569,1.793,1.793,0,0,0-1.372.577A2.884,2.884,0,0,0,405.018,191.23Z"
            transform="translate(-402.733 -184.076)"
            fill="#231f20"
          />
          <path
            id="Path_126"
            data-name="Path 126"
            d="M428.689,190.635a3.536,3.536,0,0,1-.562,1.958,3.731,3.731,0,0,1-1.643,1.36,6.158,6.158,0,0,1-2.564.492,5.767,5.767,0,0,1-2.933-.672,3.9,3.9,0,0,1-1.331-1.29,2.927,2.927,0,0,1-.513-1.569,1.065,1.065,0,0,1,1.09-1.073.93.93,0,0,1,.652.245,1.94,1.94,0,0,1,.455.73,4.855,4.855,0,0,0,.5.958,2,2,0,0,0,.749.635,2.765,2.765,0,0,0,1.271.251,2.865,2.865,0,0,0,1.757-.5,1.515,1.515,0,0,0,.676-1.257,1.333,1.333,0,0,0-.364-.971,2.35,2.35,0,0,0-.943-.569,15.764,15.764,0,0,0-1.545-.418,11.354,11.354,0,0,1-2.168-.71,3.491,3.491,0,0,1-1.384-1.106,2.867,2.867,0,0,1-.512-1.741,3,3,0,0,1,.541-1.762,3.413,3.413,0,0,1,1.565-1.183,6.406,6.406,0,0,1,2.409-.414,5.976,5.976,0,0,1,1.914.274,4.059,4.059,0,0,1,1.339.729,3.055,3.055,0,0,1,.779.955,2.206,2.206,0,0,1,.245.975,1.152,1.152,0,0,1-.307.782.976.976,0,0,1-.766.348.881.881,0,0,1-.634-.209,2.813,2.813,0,0,1-.472-.684,3.084,3.084,0,0,0-.787-1.061,2.3,2.3,0,0,0-1.475-.381,2.564,2.564,0,0,0-1.52.413,1.2,1.2,0,0,0-.577,1,1.01,1.01,0,0,0,.2.623,1.678,1.678,0,0,0,.541.451,3.608,3.608,0,0,0,.7.3q.352.106,1.163.312a18.215,18.215,0,0,1,1.84.523,5.638,5.638,0,0,1,1.4.7,2.864,2.864,0,0,1,.9,1.036A3.316,3.316,0,0,1,428.689,190.635Z"
            transform="translate(-409.015 -182.023)"
            fill="#231f20"
          />
          <path
            id="Path_127"
            data-name="Path 127"
            d="M439.737,188.667v.27a4.42,4.42,0,0,1,1.311-1.143,3.205,3.205,0,0,1,1.54-.365,3.567,3.567,0,0,1,1.9.533,3.671,3.671,0,0,1,1.373,1.557,5.44,5.44,0,0,1,.5,2.425,5.923,5.923,0,0,1-.291,1.9,4.274,4.274,0,0,1-.8,1.45,3.488,3.488,0,0,1-1.2.9,3.554,3.554,0,0,1-1.488.315,3.113,3.113,0,0,1-1.609-.386,4.61,4.61,0,0,1-1.242-1.13v3.359q0,1.476-1.073,1.475a.862.862,0,0,1-.836-.381,2.4,2.4,0,0,1-.2-1.111v-9.652a1.412,1.412,0,0,1,.279-.955.966.966,0,0,1,.762-.315,1.011,1.011,0,0,1,.775.323A1.326,1.326,0,0,1,439.737,188.667Zm4.384,3.253a3.883,3.883,0,0,0-.27-1.52,2.235,2.235,0,0,0-.75-.975,1.8,1.8,0,0,0-1.062-.34,1.993,1.993,0,0,0-1.561.729,3.187,3.187,0,0,0-.635,2.147,3.1,3.1,0,0,0,.631,2.076,1.969,1.969,0,0,0,1.564.742,1.8,1.8,0,0,0,1.033-.323,2.226,2.226,0,0,0,.762-.971A3.858,3.858,0,0,0,444.121,191.92Z"
            transform="translate(-416.09 -184.086)"
            fill="#231f20"
          />
          <path
            id="Path_128"
            data-name="Path 128"
            d="M462.74,191.961a5.3,5.3,0,0,1-.312,1.843,4.08,4.08,0,0,1-.9,1.45,3.981,3.981,0,0,1-1.41.93,4.967,4.967,0,0,1-1.844.324,4.861,4.861,0,0,1-1.828-.328,4.044,4.044,0,0,1-2.306-2.38,5.7,5.7,0,0,1,0-3.7,4.057,4.057,0,0,1,.894-1.443,3.97,3.97,0,0,1,1.408-.921,5.339,5.339,0,0,1,3.672,0,3.967,3.967,0,0,1,2.315,2.377A5.3,5.3,0,0,1,462.74,191.961Zm-2.246,0a3.362,3.362,0,0,0-.6-2.131,1.959,1.959,0,0,0-1.618-.762,2.008,2.008,0,0,0-1.155.339,2.156,2.156,0,0,0-.77,1,4.557,4.557,0,0,0-.005,3.08,2.2,2.2,0,0,0,.762,1,1.989,1.989,0,0,0,1.168.348,1.954,1.954,0,0,0,1.618-.766A3.347,3.347,0,0,0,460.494,191.961Z"
            transform="translate(-422.295 -184.086)"
            fill="#231f20"
          />
          <path
            id="Path_129"
            data-name="Path 129"
            d="M473.329,193.281v1.867a1.445,1.445,0,0,1-.32,1.021,1.069,1.069,0,0,1-.812.34,1.026,1.026,0,0,1-.794-.344,1.471,1.471,0,0,1-.312-1.017v-6.227q0-1.508,1.09-1.508a.913.913,0,0,1,.8.352,1.954,1.954,0,0,1,.27,1.041,3.925,3.925,0,0,1,.824-1.041,1.7,1.7,0,0,1,1.126-.352,2.892,2.892,0,0,1,1.368.352,1.054,1.054,0,0,1,.664.935.89.89,0,0,1-.282.676.877.877,0,0,1-.61.266,2.77,2.77,0,0,1-.594-.151,2.781,2.781,0,0,0-.832-.151,1.212,1.212,0,0,0-.8.257,1.649,1.649,0,0,0-.484.767,5.358,5.358,0,0,0-.238,1.208Q473.328,192.271,473.329,193.281Z"
            transform="translate(-428.902 -184.086)"
            fill="#231f20"
          />
          <path
            id="Path_130"
            data-name="Path 130"
            d="M482.581,185.725h.245v-1.344c0-.361.009-.643.029-.849a1.242,1.242,0,0,1,.16-.528,1.012,1.012,0,0,1,.376-.373,1.072,1.072,0,0,1,.549-.143,1.1,1.1,0,0,1,.77.318.948.948,0,0,1,.291.52,4.759,4.759,0,0,1,.061.873v1.524h.82a1.047,1.047,0,0,1,.725.225.743.743,0,0,1,.251.577.648.648,0,0,1-.357.631,2.318,2.318,0,0,1-1.02.181h-.418v4.113a6.351,6.351,0,0,0,.038.807.819.819,0,0,0,.2.46.676.676,0,0,0,.52.175,2.855,2.855,0,0,0,.533-.069,2.777,2.777,0,0,1,.525-.07.674.674,0,0,1,.487.217.728.728,0,0,1,.217.537.9.9,0,0,1-.59.827,3.986,3.986,0,0,1-1.7.287,2.927,2.927,0,0,1-1.589-.352,1.641,1.641,0,0,1-.71-.975,6.63,6.63,0,0,1-.167-1.663v-4.293h-.3a1.068,1.068,0,0,1-.737-.23.753.753,0,0,1-.254-.583.724.724,0,0,1,.267-.577A1.171,1.171,0,0,1,482.581,185.725Z"
            transform="translate(-432.902 -182.201)"
            fill="#231f20"
          />
          <path
            id="Path_131"
            data-name="Path 131"
            d="M499.565,193.583a2.594,2.594,0,0,1-1.782,2.585,5.977,5.977,0,0,1-2.143.34,5.225,5.225,0,0,1-2.064-.368,3.011,3.011,0,0,1-1.27-.922,1.857,1.857,0,0,1-.41-1.11.862.862,0,0,1,.263-.631.9.9,0,0,1,.663-.262.776.776,0,0,1,.541.172,1.9,1.9,0,0,1,.36.484,2.454,2.454,0,0,0,.823.893,2.481,2.481,0,0,0,1.307.3,1.88,1.88,0,0,0,1.1-.3.838.838,0,0,0,.43-.684.931.931,0,0,0-.446-.86,5.507,5.507,0,0,0-1.47-.517,12.169,12.169,0,0,1-1.881-.6,3.1,3.1,0,0,1-1.16-.832,1.908,1.908,0,0,1-.434-1.27,2.238,2.238,0,0,1,.4-1.271,2.748,2.748,0,0,1,1.184-.955,4.531,4.531,0,0,1,1.889-.356,6.164,6.164,0,0,1,1.56.181,3.877,3.877,0,0,1,1.155.483,2.374,2.374,0,0,1,.705.672,1.309,1.309,0,0,1,.241.721.834.834,0,0,1-.257.631,1.025,1.025,0,0,1-.734.246.9.9,0,0,1-.586-.2,3.857,3.857,0,0,1-.553-.589,2.008,2.008,0,0,0-.6-.525,1.885,1.885,0,0,0-.933-.2,1.828,1.828,0,0,0-1.008.258.753.753,0,0,0-.4.643.7.7,0,0,0,.3.578,2.439,2.439,0,0,0,.795.373q.5.147,1.376.36a7.338,7.338,0,0,1,1.7.607,2.818,2.818,0,0,1,1,.831A1.847,1.847,0,0,1,499.565,193.583Z"
            transform="translate(-436.866 -184.086)"
            fill="#231f20"
          />
        </g>
        <ellipse
          id="Ellipse_19"
          data-name="Ellipse 19"
          cx="7.279"
          cy="7.279"
          rx="7.279"
          ry="7.279"
          transform="translate(33.736 25.311)"
          fill="#ea6e30"
        />
        <g
          id="Group_2606"
          data-name="Group 2606"
          transform="translate(36.921 28.497)"
        >
          <path
            id="Path_132"
            data-name="Path 132"
            d="M385.24,197.565a.463.463,0,0,1-.463-.463V189.84a.463.463,0,0,1,.926,0V197.1A.463.463,0,0,1,385.24,197.565Z"
            transform="translate(-381.146 -189.377)"
            fill="#fff"
          />
          <path
            id="Path_133"
            data-name="Path 133"
            d="M386.619,196.186h-7.262a.463.463,0,0,1,0-.926h7.262a.463.463,0,0,1,0,.926Z"
            transform="translate(-378.894 -191.629)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_134"
          data-name="Path 134"
          d="M493.161,156.635a6.657,6.657,0,0,0-1.665-1.974A7.645,7.645,0,0,0,486.517,153a8.673,8.673,0,0,0-3.407.663,8.27,8.27,0,0,0-3.453,2.667,7.5,7.5,0,0,0-1.572,4.655,7.818,7.818,0,0,0,.909,3.669,7.715,7.715,0,0,0,1.758,2.235,8.03,8.03,0,0,0,5.426,1.882,9.189,9.189,0,0,0,5.658-1.835,2.8,2.8,0,0,0,1.387-2.142,1.638,1.638,0,0,0-.384-1.018,1.049,1.049,0,0,0-.786-.493,1.641,1.641,0,0,0-.833.37,5.385,5.385,0,0,1-1.487.7,5.477,5.477,0,0,1-1.642.285,3.625,3.625,0,0,1-2.336-.886,2.477,2.477,0,0,1-1.087-1.888c0-.277.159-.417.478-.417h.967V159.93a2.908,2.908,0,1,1,1.519,0v1.513h4.76a1.247,1.247,0,0,0,1.079-.639,2.962,2.962,0,0,0,.415-1.642A5.16,5.16,0,0,0,493.161,156.635Z"
          transform="translate(-379.945 -146.953)"
          fill="#444c4c"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "LogoHeaderAuthen"
};
</script>