import { adminPlayerService } from '@/services'

const state = {}
const getters = {}
const mutations = {}

const actions = {
    async upSertPlayer({ commit }, payload) {
        try {
            const result = await adminPlayerService.upSertPlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getPlayersAll({ commit }, payload) {
        try {
            const result = await adminPlayerService.getPlayersAll(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getDetailPlayer({ commit }, payload) {
        
        try {
            const result = await adminPlayerService.getDetailPlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async deletePlayer({ commit }, payload) {
        try {
            const result = await adminPlayerService.deletePlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async changeStatusPlayer({ commit }, payload) {
        try {
            const result = await adminPlayerService.changeStatusPlayer(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
    async getPlayerDetailByAdmin({ commit }, payload) {
        try {
            const result = await adminPlayerService.getPlayerDetailByAdmin(payload);
            return Promise.resolve(result.data);
        } catch (error) {
            if (error.data) return Promise.reject(error.data);
            return Promise.reject(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}