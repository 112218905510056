import api from './api'

const version = ''
const namespace = '/vote'
const resource = `${namespace}`

export default {
    getRanksEvent(params) {
        return api().get(`${resource}/number-rank`, { params })
    },
    getHistoryVote() {
        return api().get(`${resource}/history`)
    },
    getHistoryTicket(params) {
        return api().get(`/ticket/history` , { params })
    },
    getHistoryPoint() {
        return api().get(`/event/winner-history`)
    },
    getAllHistoryPoint() {
        return api().get(`/point-exchange/user/history/all`)
    },
    getAllHistoryVote() {
        return api().get(`${resource}/history/all`)
    },
    getAllHistoryTicket(params) {
        return api().get(`/ticket/history/all`, { params })
    },
    voteGamerEvent(params) {
        return api().post(`${resource}`, params)
    },
    voteHistoryEvent(eventId) {
        return api().get(`${resource}/event-vote-history/${eventId}`)
    }
}