<template>
  <div class="article-title">
    <h1>
      <span class="title">{{ title }}</span>
      <span class="sub-title">{{ sub_title }}</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "TitleArticle",
  props: {
    title: {
      type: String,
      default: "",
    },
    sub_title: {
      type: String,
      default: "",
    },
  },
};
</script>